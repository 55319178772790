import { MasterApi } from "apiCalls/masterDb";
import { useAppDispatch } from "hooks/reduxHooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SET_LOADER } from "redux/actions/common.actions";

export const MysqlCityLogs: React.FC<{}> = (props) => {
  const [cityLogs, setCityLogs] = useState<any[]>([]);
  const [currentPageNo, setPageNumber] = useState(1);
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCityLogs(currentPageNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNo]);

  const getCityLogs = async (pageNo: number = 1) => {
    dispatch(SET_LOADER(true));
    const response = await MasterApi.getCityLogs(pageNo);
    console.log(response);
    if (response.success && response.result) {
      setCityLogs(response.result);
    } else {
      toast.error("Error getting city change logs");
    }
    dispatch(SET_LOADER(false));
  };

  const handlePageChange = (props: { action: "next" | "prev" }) => {
    if (props.action === "next") {
      setPageNumber((old) => old + 1);
    } else if (props.action === "prev") {
      if (currentPageNo > 1) setPageNumber((old) => old - 1);
    } else {
      toast.error("Invalid page number");
    }
  };

  return (
    <>
      <button
        onClick={() => handlePageChange({ action: "prev" })}
        className="btn btn-primary  m-2 btn-sm"
      >
        Previous Page
      </button>
      <button
        onClick={() => handlePageChange({ action: "next" })}
        className="btn btn-primary m-2 btn-sm"
      >
        Next Page
      </button>
      <div>Page No. {currentPageNo}</div>
      {cityLogs?.map((log) => (
        <div key={log?.id} className="border my-2">
          User: {log?.user?.userName}
          <br />
          Remark: {log?.changeRemark}
          <br />
          Created At: {moment(log?.createdAt).format("llll")}
          <br />
          <pre>{JSON.stringify(log?.log, null, 2)}</pre>
        </div>
      ))}
    </>
  );
};
