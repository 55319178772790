import React, { useState, useEffect } from "react";
import {
  Tooltip,
  makeStyles,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { getRequest, postRequest } from "tools/ajax";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import LinkSippiCustDialog from "./LinkSippiCustDialog";
import Loading from "components/user/common/loading";
import { GET_SITE_DATA } from "redux/actions/siteSettings.actions";

import { toast } from "react-toastify";
import { AddSSL } from "./AddSSL";
import {
  Button,
  ButtonGroup,
  Collapse,
  Form,
  FormGroup,
  Modal,
} from "react-bootstrap";
import "assets/css/admin/whitelabel/viewWhitelabel.scss";
import { SET_LOADER } from "redux/actions/common.actions";
import { IWhitelabel } from "types/accounts.types";
import { IsippiCustomer } from "types/sippiCustomer.types";
import { useAppDispatch } from "hooks/reduxHooks";
import { sippiCustomerSlice } from "redux/slices/sippiCustomer.slice";
import { EditApiIntegrationPopup } from "./EditApiIntegrationPopup";
import _ from "lodash";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  indicator: {
    color: theme.palette.secondary.main,
  },
  tableColumnBorder: {
    borderRight: "1px solid #e0e0e0",
  },
}));

export const ListAllWhitelabels = () => {
  const [sortBy, setSortBy] = useState<{
    sortByField: "walletBalance" | "suspentionTime" | "";
    order: "asc" | "desc";
  }>({
    sortByField: "",
    order: "desc",
  });
  const [
    showSitesWithInsufficientBalance,
    setShowSitesWithInsufficientBalance,
  ] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [moreAnchor, setMoreAnchor] = useState(null);
  const [loading, setLoading] = useState(true);
  const moreOpen = Boolean(moreAnchor);
  const [whitelabels, setWhitelabels] = useState<IWhitelabel[]>([]);
  const [openSippiCustDialog, setOpenSippiCustDialog] = useState(false);
  const [selectedWl, setSelectedWl] = useState<IWhitelabel | undefined>(
    undefined
  );
  const [selectedWlModuleConfigurations, setSelectedWlModuleConfigurations] =
    useState<any[]>([]);
  const [allSiteModules, setAllSiteModules] = useState([]);
  const [isOpenSslDialog, setIsOpenSslDialog] = useState(false);
  const [filterState, setFilterState] = useState("all");
  const [wlsToShow, setWlsToShow] = useState<IWhitelabel[]>([]);
  const [open, setOpen] = useState<undefined | string>(undefined);
  const [billingDetails, setBillingDetails] = useState<
    | {
        dailyBillingAmount?: number | undefined;
        id?: number | undefined;
        internalRemark?: string | undefined;
        publicRemark?: string | undefined;
        selectedCurrency?: "INR" | "USD" | undefined;
        sippiCustomerId?: string;
        siteId?: string;
      }
    | undefined
  >({
    dailyBillingAmount: 0,
    id: undefined,
    internalRemark: "",
    publicRemark: "",
    selectedCurrency: "INR",
    sippiCustomerId: "",
    siteId: "",
  });
  /**
   *
   * {
   *    status: "",
   *    wl_id: "",
   * }
   *
   */
  const [selectedwlStatusState, setSelectedWlStatusState] = useState<
    | {
        wlName: string;
        status: string;
        wlId: string;
      }
    | undefined
  >();
  const [showModalState, setShowModalState] = useState(false);
  const [showModuleConfigModalState, setShowModuleConfigModalState] =
    useState(false);
  const dispatch = useAppDispatch();

  const getModuleConfiguration = async () => {
    if (!selectedWl) {
      return toast.error(
        "Whitelabel not selected... This is possibly a bug. Please contact dev team!"
      );
    }
    setLoading(true);
    axios({
      url: "/api/admin/whitelabel/wlModuleConfigurations",
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
      },
      method: "POST",
      data: {
        siteId: selectedWl._id,
      },
    }).then((r) => {
      setSelectedWlModuleConfigurations(r.data);
      setLoading(false);
    });

    moreClose();
    setShowModuleConfigModalState(true);
  };

  const getAllSiteModules = async () => {
    setLoading(true);
    axios({
      url: "/api/admin/whitelabel/getAllSiteModules",
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
      },
      method: "GET",
    }).then((r) => {
      setAllSiteModules(r.data);
      setLoading(false);
    });
  };

  const changeSiteModuleConfig = async (moduleId, isActive) => {
    if (!selectedWl) {
      return toast.error(
        "Whitelabel not selected... This is possibly a bug. Please contact dev team!"
      );
    }
    setLoading(true);
    axios({
      url: "/api/admin/whitelabel/changeSiteModuleConfig",
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
      },
      data: {
        siteId: selectedWl._id.toString(),
        moduleId,
        isActive,
      },
      method: "POST",
    })
      .then((r) => {
        getModuleConfiguration();
      })
      .catch((err) => {
        toast.error = err.message;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllWhitelabels = async () => {
    setWhitelabels([]);
    axios({
      url: "/api/admin/whitelabel/all",
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
      },
    }).then((r) => {
      // console.log(r.data);
      setWhitelabels(r.data.reverse());
    });
  };

  const searchByDomain = (e) => {
    setShowSitesWithInsufficientBalance(false);
    const domainName = e.target.value;

    setWlsToShow(whitelabels.filter((wl) => wl.domain.includes(domainName)));
  };

  const updateGSTNumber = (wlId) => {
    const elem: any = document.getElementsByName(`gst_${wlId}`)[0];
    const gstNumber = elem?.value;
    // toast.error(gstInput);
    axios({
      url: "/api/admin/whitelabel/updateGSTNumberToLE",
      data: {
        wlId,
        gstNumber,
      },
      method: "POST",
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
      },
    })
      .then((r) => {
        toast.success("GST Number Updated");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSuppliers = async () => {
    const res = await getRequest("admin/whitelabel/suppliers", {});
    // console.log("server response", res);
  };

  const moreClose = () => setMoreAnchor(null);

  const jumpLogin = async (whitelabel) => {
    setLoading(true);
    // console.log(whitelabel);
    const axiosRes = await axios({
      url: "/api/admin/whitelabel/getSippiCustomerById",
      data: { sippiCustomerId: whitelabel.sippiCustomerId },
      method: "POST",
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
      },
    });
    // console.log("\n\nCustomer\n\n", customer.data);
    const customer: IsippiCustomer = axiosRes.data.customer;
    if (!customer) {
      toast.error("This is not a Paid Account");
      setLoading(false);
      return;
    }

    dispatch(sippiCustomerSlice.actions.setSippiCustomer(customer));
    let loginResponse: any = await axios({
      url: "/api/user/googleLogin",
      method: "POST",
      data: { profileObj: customer.googlePersonObj },
    });
    loginResponse = loginResponse.data;
    // console.log("loginResponse\n\n", loginResponse);
    localStorage.setItem("customer-token", loginResponse?.token);
    /**
     * below dispatch will trigger saga
     * this saga will get site data and open the site owner's login in new tab
     **/
    dispatch(GET_SITE_DATA());
    localStorage.setItem(
      "googlePersonObj",
      JSON.stringify(loginResponse.customer.googlePersonObj)
    );
    setLoading(false);
  };

  const makeSupplier = async () => {
    const res = await postRequest("admin/whitelabel/makeSupplier", selectedWl);
    // console.log("server response", res);
  };

  const addSSL = () => {
    setIsOpenSslDialog(true);
    setMoreAnchor(null);
  };

  const submitStatusChange = () => {
    axios({
      url: "/api/admin/whitelabel/updateWlStatus",
      data: selectedwlStatusState,
      method: "POST",
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
      },
    })
      .then((r) => {
        toast.success("Whitelabel Status Updated");
        setShowModalState(false);
        setSelectedWlStatusState(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const suspendDefaulterSites = async () => {
    dispatch(SET_LOADER(true));
    const result = await getRequest(
      "admin/whitelabel/suspendPaymentDefaulters",
      {}
    );
    // console.log(result);
    dispatch(SET_LOADER(false));
  };

  // const getAllWhiteLabelApiIntegrations = async (siteId) => {
  //   console.log(siteId);

  //   const response = await postRequest(
  //     "/admin/nfs/getAllWhitelabelApiIntegration",
  //     siteId
  //   );
  //   if (response) {
  //     setAllWhiteLabelApiIntegrations(response);
  //   }
  // };
  const getSiteBillingDetails = async (siteId: string) => {
    setBillingDetails({
      dailyBillingAmount: undefined,
      id: undefined,
      internalRemark: "",
      publicRemark: "",
      selectedCurrency: "INR",
      sippiCustomerId: "",
      siteId: "",
    });
    const apiRes = await postRequest("admin/whitelabel/getSiteBillingDetails", {
      siteId,
    });
    setBillingDetails(apiRes.result);
  };
  const saveSiteBillingDetails = async (siteId: string) => {
    const apiRes = await postRequest(
      "admin/whitelabel/saveSiteBillingDetails",
      { ...billingDetails, siteId }
    );
    if (apiRes?.success) {
      toast.success("details saved");
    } else {
      toast.error("something went wrong");
    }
  };

  const sortWhitelabels = () => {
    if (!sortBy || !sortBy.sortByField) {
      return;
    }
    if (sortBy.sortByField !== undefined) {
      const WlArr = _.cloneDeep(wlsToShow);

      WlArr.sort((a, b) => {
        if (a[sortBy.sortByField] > b[sortBy.sortByField]) {
          return sortBy.order === "asc" ? 1 : -1;
        }
        if (a[sortBy.sortByField] < b[sortBy.sortByField]) {
          return sortBy.order === "asc" ? -1 : 1;
        }
        return 0;
      });

      setWlsToShow(
        WlArr.filter((wl) => {
          if (typeof wl.walletBalance === "number") {
            return true;
          }
        })
      );
    }
  };

  const provideGraceToSite = async (siteId, gracePeriod) => {
    const apiRes = await postRequest("admin/whitelabel/provideGraceToSite", {
      siteId,
      gracePeriod,
    });
    if (apiRes && apiRes.success) {
      toast.success("updated");
      getAllWhitelabels();
      setShowSitesWithInsufficientBalance(false);
    }
  };

  useEffect(() => {
    getAllWhitelabels();
    getSuppliers();
    getAllSiteModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log({ filterState });
    if (filterState === "all") {
      setWlsToShow(whitelabels);
    } else if (filterState === "suspended") {
      setWlsToShow(whitelabels.filter((wl) => wl?.isSuspended));
    } else if (filterState === "active") {
      setWlsToShow(whitelabels.filter((wl) => !Boolean(wl?.isSuspended)));
    }
  }, [filterState, whitelabels]);

  useEffect(() => {
    sortWhitelabels();
  }, [sortBy]);

  useEffect(() => {
    const WlArr = _.cloneDeep(wlsToShow);
    if (showSitesWithInsufficientBalance) {
      setWlsToShow(
        WlArr.filter((wl) => {
          if (wl.suspentionTime) {
            return true;
          }
        })
      );
    } else {
      setWlsToShow(whitelabels);
    }
  }, [showSitesWithInsufficientBalance]);

  return (
    <>
      {/* <EditApiIntegrationPopup
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
      {whitelabels.length ? (
        <div>
          {/* Chenge Status Modal */}
          <Modal
            show={showModalState}
            onHide={() => {
              setSelectedWlStatusState(undefined);
              setShowModalState(false);
              getAllWhitelabels();
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Are you sure you want to change the "
                {selectedwlStatusState?.wlName}" status to "
                {selectedwlStatusState?.status}" ?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <button className="btn btn-success" onClick={submitStatusChange}>
                Yes
              </button>
            </Modal.Body>
          </Modal>

          {/*  Modal */}
          <Modal
            show={showModuleConfigModalState}
            onHide={() => {
              setShowModuleConfigModalState(false);
            }}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Module Configurations
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {allSiteModules.map((siteModule: any, indx) => {
                const selectedModule = selectedWlModuleConfigurations.find(
                  (modConf) => modConf.siteModule.id === siteModule.id
                );

                return (
                  <FormGroup key={indx} className="my-3">
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          className="me-2"
                          checked={selectedModule?.is_active || false}
                          onChange={() => {
                            changeSiteModuleConfig(
                              siteModule.id,
                              !(selectedModule?.is_active || false)
                            );
                          }}
                        />
                      }
                      label={siteModule.module_name}
                    />
                  </FormGroup>
                );
              })}
            </Modal.Body>
          </Modal>

          {selectedWl && (
            <>
              <AddSSL
                isOpen={isOpenSslDialog}
                handleClose={() => setIsOpenSslDialog(false)}
                whitelabel={selectedWl}
              />

              <LinkSippiCustDialog
                selectedWl={selectedWl}
                isOpen={openSippiCustDialog}
                handleClose={() => setOpenSippiCustDialog(false)}
              />
            </>
          )}
          <Loading loading={loading} />
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <p className="h5">List of All Whitelabels</p>
              <>
                {`${whitelabels.length} Total Records `}
                <br />
                {`${wlsToShow.length} Filtered Records`}
                <br />
                <span
                  role="button"
                  className="btn btn-sm btn-outline-primary rounded m-2 small"
                  onClick={() => {
                    if (sortBy?.order === "desc") {
                      setSortBy({
                        sortByField: "walletBalance",
                        order: "asc",
                      });
                    } else {
                      setSortBy({
                        sortByField: "walletBalance",
                        order: "desc",
                      });
                    }
                  }}
                >
                  Sort by wallet Balance{" "}
                  {sortBy.sortByField === "walletBalance" &&
                    sortBy.sortByField && (
                      <i
                        className={`fas fa-caret-${
                          sortBy.order === "asc" ? "up" : "down"
                        }`}
                      ></i>
                    )}
                </span>
                <span
                  role="button"
                  className="btn btn-sm btn-outline-primary rounded m-2 small"
                  onClick={() => {
                    if (sortBy?.order === "desc") {
                      setSortBy({
                        sortByField: "suspentionTime",
                        order: "asc",
                      });
                    } else {
                      setSortBy({
                        sortByField: "suspentionTime",
                        order: "desc",
                      });
                    }
                  }}
                >
                  Sort by Suspention time{" "}
                  {sortBy.sortByField === "suspentionTime" &&
                    sortBy.sortByField && (
                      <i
                        className={`fas fa-caret-${
                          sortBy.order === "asc" ? "down" : "up"
                        }`}
                      ></i>
                    )}
                </span>
                <div className="form-check form-switch ms-3">
                  <input
                    onChange={(e) =>
                      setShowSitesWithInsufficientBalance(e.target.checked)
                    }
                    checked={showSitesWithInsufficientBalance}
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Show sites with insufficient balance
                  </label>
                </div>
              </>
            </div>
            <div className="col-md-8 col-sm-6 ">
              <div className="row">
                <div className="col-md-4 col-sm-6 ">
                  <Form.Select
                    onChange={(e) => {
                      setFilterState(e.target.value);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="suspended">Suspended only</option>
                    <option value="active">Active only</option>
                  </Form.Select>
                </div>
                <div className="col-md-4 col-sm-6 py-1">
                  <Form.Control
                    placeholder="Domain Search"
                    onChange={searchByDomain}
                  />
                </div>
                <div className="col-sm-4">
                  <button
                    className="btn btn-primary"
                    onClick={suspendDefaulterSites}
                  >
                    Suspend Payment Defaulters
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ overflow: "scroll", maxWidth: "100vw" }}
            className="container"
          >
            <table
              className={`table table-striped table-hover table-sm whitelabelTable border table-bordered text-center`}
            >
              <thead>
                <tr>
                  <td align="center" colSpan={5}>
                    List of Whitelabels
                  </td>
                </tr>
                <tr>
                  <td>Website Details</td>
                  <td>GST</td>
                  <td>Action</td>
                  <td>Contact</td>
                  <td>Indicators</td>
                </tr>
              </thead>
              <tbody>
                {wlsToShow?.map((w, i) => {
                  let siteSuspentionMessage: any;
                  if (w.suspentionTime) {
                    const days = Math.abs(
                      moment(w.suspentionTime).diff(moment(), "day")
                    );
                    let hours: number | undefined = undefined;
                    hours =
                      Math.abs(
                        moment(w.suspentionTime).diff(moment(), "hours")
                      ) % 24;
                    if (
                      w.isSuspended ||
                      moment().isAfter(moment(w.suspentionTime))
                    ) {
                      //site is suspended
                      siteSuspentionMessage = (
                        <span>
                          <b>
                            {" "}
                            {`Suspended ${
                              days + " days " + hours + " hours"
                            } ago`}{" "}
                          </b>
                        </span>
                      );
                    } else {
                      siteSuspentionMessage = `Will suspend in ${
                        days + " days " + hours + " hours"
                      }`;
                    }
                  }
                  {
                    w.suspentionTime && moment(w.suspentionTime).format("lll");
                  }
                  return (
                    <>
                      <tr key={i} className="text-center">
                        <td>
                          <div className=" row py-3 ">
                            <div className="col-4">
                              <a
                                href={`http://${w.domain}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  style={{ maxWidth: 100 }}
                                  src={w.logoUrl}
                                  alt={w.domain}
                                />
                              </a>{" "}
                              <br />
                              <span className="small pt-2">
                                Balance:
                                {typeof w.walletBalance === "number" ? (
                                  <b className="ms-2">₹{w.walletBalance}</b>
                                ) : (
                                  " Not found"
                                )}
                              </span>
                              <br />
                              {w?.suspentionTime && (
                                <select
                                  name=""
                                  id=""
                                  className="form-select small"
                                  onChange={(e) => {
                                    provideGraceToSite(
                                      w._id,
                                      parseInt(e.target.value.toString())
                                    );
                                  }}
                                >
                                  <option value="">Provide Grace Period</option>
                                  <option value={1}>1 Day</option>
                                  <option value={2}>2 Days</option>
                                  <option value={3}>3 Days</option>
                                  <option value={7}>1 week</option>
                                </select>
                              )}
                              <br />
                              <span className="text-danger small">
                                {w.suspentionTime && siteSuspentionMessage}
                              </span>
                            </div>
                            <div className="col-8">
                              <b>Domain: </b>
                              {w.domain}
                              <br />
                              <select
                                defaultValue={w?.status}
                                onChange={(e) => {
                                  setSelectedWlStatusState({
                                    status: e.target.value,
                                    wlId: w._id,
                                    wlName: w.companyName,
                                  });
                                  setShowModalState(true);
                                }}
                              >
                                <option value={undefined}>Select</option>
                                <option value="test">Testing</option>
                                <option value="maya">
                                  Sippi / Maya Website
                                </option>
                                <option value="free">Free Websites</option>
                                <option value="paid">Paid Websites</option>
                              </select>

                              <p>
                                <b>Company: </b>
                                {w.companyName}
                                <br />
                                <b>Site Name: </b>
                                {w.siteName}
                                <br />

                                <div className="mt-2 d-flex  align-items-end justify-content-center">
                                  <Button
                                    onClick={() => {
                                      setOpen(w._id);
                                      getSiteBillingDetails(w._id);
                                    }}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={open !== undefined}
                                  >
                                    Show daily billing details
                                  </Button>
                                </div>
                              </p>
                            </div>
                          </div>
                        </td>
                        <td
                          align="center"
                          style={{
                            textAlign: "center",
                            verticalAlign: "center",
                          }}
                        >
                          <input
                            name={`gst_${w._id}`}
                            className="form-control"
                            style={{
                              width: 100,
                            }}
                            type="text"
                            defaultValue={w.legalEntity?.gstNumber}
                          />

                          <button
                            onClick={() => {
                              updateGSTNumber(w._id);
                            }}
                            className="btn btn-sm btn-dark mt-2"
                          >
                            Update
                          </button>
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={() => {
                                history.push(
                                  `/admin/whitelabel/create/${w._id}`
                                );
                              }}
                            >
                              <EditIcon />
                              Edit
                            </button>

                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={() => {
                                jumpLogin(w);
                              }}
                            >
                              <TransferWithinAStationIcon />
                              <br />
                              Jump Login
                            </button>

                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={(e: any) => {
                                setMoreAnchor(e.currentTarget);
                                setSelectedWl(w);
                                // getAllWhiteLabelApiIntegrations(w._id);
                              }}
                            >
                              <MoreVertIcon /> More
                            </button>
                          </div>
                        </td>
                        <td>
                          <b>Admin Email: </b>
                          {w.adminEmail}
                          <br />
                          <b>Admin Phone: </b>
                          {w.adminContact}
                          <br />

                          {w?.sippiCustomer?.googlePersonObj?.email && (
                            <>
                              <b>Sippi Customer Email: </b>
                              {w?.sippiCustomer?.googlePersonObj?.email}
                            </>
                          )}
                        </td>
                        <td>
                          <div className="d-flex">
                            {!w.sippiCustomerId && (
                              <Tooltip title="Sippi Customer missing">
                                <PersonAddDisabledIcon
                                  className={classes.indicator}
                                  onClick={() => {
                                    setOpenSippiCustDialog(true);
                                    setSelectedWl(w);
                                  }}
                                />
                              </Tooltip>
                            )}
                            {w.isSuspended && (
                              <Tooltip title="Site Suspended">
                                <i className="fas fa-ban fs-5 text-danger"></i>
                              </Tooltip>
                            )}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={1000}>
                          <Collapse in={open === w._id}>
                            <div id="example-collapse-text ">
                              <div className="d-flex text-start align-items-end p-3">
                                <div>
                                  Daily Bill :{" "}
                                  <input
                                    name="internalRemark"
                                    onChange={(e) => {
                                      let { value } = e.target;
                                      setBillingDetails((old) => {
                                        return {
                                          ...old,
                                          dailyBillingAmount:
                                            parseFloat(value) || 0,
                                        };
                                      });
                                    }}
                                    className="form-control"
                                    type="number"
                                    value={billingDetails?.dailyBillingAmount}
                                  />
                                </div>
                                <div>
                                  Internal Remark :{" "}
                                  <input
                                    onChange={(e) => {
                                      let { value } = e.target;
                                      setBillingDetails((old) => {
                                        return {
                                          ...old,
                                          internalRemark: value,
                                        };
                                      });
                                    }}
                                    className="form-control"
                                    type="text"
                                    value={billingDetails?.internalRemark}
                                  />
                                </div>
                                <div>
                                  Public Remark :{" "}
                                  <input
                                    onChange={(e) => {
                                      let { value } = e.target;
                                      setBillingDetails((old) => {
                                        return {
                                          ...old,
                                          publicRemark: value,
                                        };
                                      });
                                    }}
                                    className="form-control"
                                    type="text"
                                    value={billingDetails?.publicRemark}
                                  />
                                </div>
                                <div>
                                  <button
                                    className="btn btn-success ms-1"
                                    onClick={() => {
                                      saveSiteBillingDetails(w._id);
                                    }}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-primary ms-1"
                                    onClick={() => {
                                      setOpen(undefined);
                                      setBillingDetails({
                                        dailyBillingAmount: undefined,
                                        id: undefined,
                                        internalRemark: "",
                                        publicRemark: "",
                                        selectedCurrency: "INR",
                                        sippiCustomerId: "",
                                        siteId: "",
                                      });
                                    }}
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Menu
            anchorEl={moreAnchor}
            keepMounted
            open={moreOpen}
            onClose={moreClose}
          >
            <MenuItem onClick={makeSupplier}>Make Supplier</MenuItem>
            <MenuItem onClick={addSSL}>Add SSL</MenuItem>
            <MenuItem onClick={getModuleConfiguration}>
              Module Configuration
            </MenuItem>
            <Link
              to={`/admin/whiteLabel/editwlapiintegrations?id=${selectedWl?._id}`}
            >
              <MenuItem>API Integrations</MenuItem>
            </Link>
            <Link to={`/admin/whiteLabel/appjsondata?id=${selectedWl?._id}`}>
              <MenuItem>App JSON Data</MenuItem>
            </Link>
          </Menu>
        </div>
      ) : (
        <div className="text-center fs-4 fw-bold my-4 py-4 text-primary">
          Loading whitelabels...
        </div>
      )}
    </>
  );
};
