import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import Header from "components/user/common/Header";
import Footer from "components/user/common/footer/Footer";
const LoggedInRoute: React.FC<{
  component: any;
  noMenu?: Boolean;
  path: any;
}> = ({ component: Component, noMenu = false, ...rest }) => {
  const location = useLocation();
  const isLoggedIn = () => {
    if (localStorage.getItem("customer-token")) return true;
    else return false;
  };
  return (
    <>
      <Header />
      <Route
        {...rest}
        render={(props) => {
          if (isLoggedIn()) {
            return <Component {...props} />;
          } else {
            return <Redirect to={`/login?next=${location.pathname}`} />;
          }
        }}
      />
      <Footer />
    </>
  );
};

export default LoggedInRoute;
