import React from 'react'
import Typography from 'commonUi/Typography'
import List from 'commonUi/list/List'
import ListItem from 'commonUi/list/ListItem'
import ListItemText from 'commonUi/list/listItemText'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const YoutubeVideo = () => {
    return(
        <iframe width="100%" title="smtp video link" height="auto" src="https://www.youtube.com/embed/D-NYmDWiFjU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    )
}

export default function Tips() {
    return (
        <div>
            <Typography variant="h6" >
                Tips for setting-up SMTP Account
            </Typography>
            
            <List>
                <ListItem icon={<ArrowDownwardIcon />}>
                    <ListItemText>
                    Link to a third party youtube video.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <YoutubeVideo />
                </ListItem>
                <ListItem>
                   <ListItemText>Above video shows how to enable smtp for gmail.</ListItemText> 
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <b>For Gmail SMTP config will be as following:</b>
                        <br />
                        Host = smtp.gmail.com
                        <br />
                        Port = 465
                        <br />
                        User = yourEmail@gmail.com
                        <br />
                        Pass = your-password
                        <br />
                        Secure = Yes
                    </ListItemText>
                </ListItem>
            </List>
         
        </div>
    )
}
