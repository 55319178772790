import React from "react";
import AdminRoutes from "components/routes/AdminRoutes";
import Dashboard from "components/admin/APIs/dashboard";
import S3 from "components/admin/APIs/AWS/S3/index";
import Tbo from "components/admin/APIs/tbo/totalSale/index";
import { ApiHeader } from "./apiHeader/index";
import { paths } from "./PathConstants";
import LiveSites from "./tbo/liveSites/index";
import { APIIntegrations } from "./APIIntegrations";

export default function API() {
  return (
    <React.Fragment>
      <ApiHeader />
      <AdminRoutes path={paths.s3} component={S3} noMenu={true} />
      <AdminRoutes
        path={paths.apiIntegrations}
        component={APIIntegrations}
        noMenu={true}
      />
      <AdminRoutes
        exact
        path={paths.apiDashboard}
        component={Dashboard}
        noMenu={true}
      />

      <AdminRoutes
        path={paths.tboLiveSites}
        component={LiveSites}
        noMenu={true}
      />
      <AdminRoutes path={paths.tboTotalSale} component={Tbo} noMenu={true} />
    </React.Fragment>
  );
}
