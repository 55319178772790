import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { useEffect, useState } from 'react'
import { FullScreenLoader } from '../flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader'
import { AdvanceSettings } from './AdvanceSettings'
import {  FormControl, Button, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { FixedDepartureService } from 'apiCalls/fixedDeparture.service'

import { toast } from 'react-toastify'
import Autocomplete from '@material-ui/lab/Autocomplete'

import FlightApi from 'apiCalls/FlightApi'
import TextField from '@material-ui/core/TextField';

const initialFormState = {
    mapping_key: "",
    origin: "",
    destination: "",
    is_return: false
}

export const FDKIngMapping = () => {

    const [loadingState, setLoadingState] = useState(false)

    const [fdKingMappingsState, setFdKingMappingsState] = useState<Array<any>>([])

    const [mappingFormState, setMappingFormState] = useState(initialFormState)

    const [originOptions, setOriginOptions] = useState(Array<any>([]))
    const [destinationOptions, setDestinationOptions] = useState(Array<any>([]))

    const [fdKingExceptionsOptions, setFdKingExceptionsOptions] = useState(Array<any>([]))

    const getFdKingMappings = async () => {
        setLoadingState(true);
        const fdKingMappings = await FixedDepartureService.getFDKingMappings();
        if (fdKingMappings.result && fdKingMappings.result.length) {
            setFdKingMappingsState(fdKingMappings.result)
        }
        setLoadingState(false)
    }

    const handleInputChange = (name, value) => {
        setMappingFormState({
            ...mappingFormState,
            [name]: value
        })
    }

    const saveFdKingMapping = async (e) => {
        e.preventDefault();

        setLoadingState(true);
        console.log(e);

        const fdKingMappingSave = await FixedDepartureService.addFDKingMappings(mappingFormState);
        try {
            if (fdKingMappingSave.success) {
                toast.success("Mapping Successfully saved");
                setMappingFormState(initialFormState)
                setFdKingExceptionsOptions(oldExcep => {
                    return oldExcep.filter(exc => mappingFormState.mapping_key !== exc.sector);
                })
                getFdKingMappings();
            } else {
                throw new Error();
            }
        } catch (Err) {
            toast.error("Some Error Occurrred");
        }

        setLoadingState(false)
    }

    useEffect(() => {
        getFdKingMappings()

    }, [])


    const handleDeleteMapping = async (mappingId) => {
        setLoadingState(true);
        const fdKingMappingDeleted = await FixedDepartureService.deleteFDKingMapping(mappingId);
        try {
            if (fdKingMappingDeleted.success) {
                toast.success("Mapping Successfully deleted");
                getFdKingMappings();
            } else {
                throw new Error();
            }
        } catch (Err) {
            toast.error("Some Error Occurrred");
        }

        setLoadingState(false)
    }


    const getAirports = async (airportCode) => {
        const airports = await FlightApi.searchAirports(airportCode)

        if (airports.response?.success && airports.response?.result) {
            return airports.response.result;
        }
        return [];
    }

    const getFDExceptions = async () => {

        const fdKingExceptions = await FixedDepartureService.getFDKingExceptions()

        if (fdKingExceptions.success) {
            setFdKingExceptionsOptions(fdKingExceptions.result);
        }
    }

    useEffect(() => {
        getFDExceptions();
    }, [])

    // useEffect(() => {
    //     getDestinationAirports(destination)
    // }, [destination])

    const getOriginAirports = async (airportCode) => {
        const airports = await getAirports(airportCode);
        setOriginOptions(airports);
    }

    const getDestinationAirports = async (airportCode) => {
        const airports = await getAirports(airportCode);
        setDestinationOptions(airports);
    }


    return (
        <div>
            <FullScreenLoader show={loadingState} />

            <AdvanceSettings />
            <Container>
                <Typography variant="h5">FD King Maps</Typography>


                <FormControl variant="filled" fullWidth className="my-1">
                    {/* <InputLabel >Mapping Label</InputLabel> */}
                    <Autocomplete
                        id="excepAutocomplete"
                        options={fdKingExceptionsOptions}
                        onChange={(e, value) => {
                            if (value) {
                                handleInputChange("mapping_key", value.sector)
                            }
                        }}
                        // onInputChange={(e, value) => {
                        //     // getOriginAirports(value)
                        // }}
                        getOptionLabel={(option) => `${option.sector}`}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} label="Mapping Label" variant="outlined" />}
                    />
                    {/* <Input onChange={(e) => handleInputChange("mapping_key", e.target.value)} fullWidth /> */}
                </FormControl>

                <FormControl variant="filled" fullWidth className="my-1">
                    <Autocomplete
                        id="originAutocomplete"
                        options={originOptions}
                        onChange={(e, value) => {
                            if (value) {
                                handleInputChange("origin", value.cityCode)
                            }
                        }}
                        onInputChange={(e, value) => {
                            getOriginAirports(value)
                        }}
                        getOptionLabel={(option) => `${option.cityName} (${option.cityCode})`}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} label="Origin" variant="outlined" />}
                    />
                    {/* <InputLabel >Origin</InputLabel>
                    <Input onChange={(e) => handleInputChange("origin", e.target.value)} fullWidth /> */}
                </FormControl>

                <FormControl variant="filled" fullWidth className="my-1">
                    <Autocomplete
                        id="originAutocomplete"
                        options={destinationOptions}
                        onChange={(e, value) => {
                            if (value) {
                                handleInputChange("destination", value.cityCode)
                            }
                        }}
                        onInputChange={(e, value) => {
                            getDestinationAirports(value)
                        }}
                        getOptionLabel={(option) => `${option.cityName} (${option.cityCode})`}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} label="Destination" variant="outlined" />}
                    />
                    {/* <InputLabel >Destination</InputLabel>
                    <Input onChange={(e) => handleInputChange("destination", e.target.value)} fullWidth /> */}
                </FormControl>

                <FormControl component="fieldset" fullWidth>
                    <RadioGroup aria-label="gender" name="gender1" value={mappingFormState.is_return}>
                        <FormControlLabel onClick={() => handleInputChange("is_return", true)} value={true} control={<Radio />} label="Returning" />
                        <FormControlLabel onClick={() => handleInputChange("is_return", false)} value={false} control={<Radio />} label="One Way" />
                    </RadioGroup>
                </FormControl>

                <Button type="button" onClick={saveFdKingMapping} color="primary" variant="contained" className="mt-3">Submit</Button>


                <table className="table table-responsive mt-5">
                    <thead>
                        <tr>
                            <th>
                                Mapping Label
                            </th>
                            <th>
                                Origin
                            </th>
                            <th>
                                Destination
                            </th>
                            <th>
                                Type
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fdKingMappingsState.map(mapping => (

                                <tr key={mapping.id}>
                                    <td>{mapping.mapping_key}</td>
                                    <td>{mapping.origin}</td>
                                    <td>{mapping.destination}</td>
                                    <td>{mapping.is_return ? "Returning" : "One Way"}</td>
                                    <td><Button color="primary" variant="contained" onClick={() => handleDeleteMapping(mapping.id)}>Delete</Button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </Container>

        </div >
    )
}
