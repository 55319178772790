import React from "react";
import logo from "assets/images/newHome/sippilogo.png";
import { Link } from "react-router-dom";
import moment from "moment";

export default function Footer() {
  return (
    <>
      <div className="pss-footer">
        {/* footer */}

        <footer id="main-footer" className="">
          <div className="container">
            <div className="row">
              <div className="col-md-5 pe-5">
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
                <div className="about-us">
                  <h4>About Us</h4>
                  We simplify the process of running a travel website by making
                  tools and softwares which help in automation and make things
                  simple.
                </div>
              </div>
              <div className="col-md-3">
                <div className="other-links">
                  <h4>Important Links</h4>
                  <div className="link">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </div>
                  <div className="link">
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </div>
                  <div className="link">
                    <Link to="/platform_and_usage_fee">
                      Platform & Usage Fee
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="other-links">
                  <h4>Contact Us</h4>
                  <div className="contact-us-point">
                    Support@SippiSoftware.com
                  </div>{" "}
                  <br />
                  <div className="contact-us-point">+91 7686963000 </div> <br />
                  <div className="contact-us-point">
                    #412, Golden Square, Zirakpur (140603), Punjab, India{" "}
                  </div>
                  <br />
                </div>
              </div>
            </div>

            <div className="copyright">
              Sippi Software Solutions Private Limited &copy; {moment().year()}
            </div>
          </div>
        </footer>
        {/* footer-end */}
      </div>
    </>
  );
}
