export const printAmount = (amount: any): Float32Array => {
  if (typeof amount === "string") amount = parseFloat(amount);
  return amount.toFixed(2);
};

export const generateRandomString = (len) => {
  let charSet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var randomString = "";
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
};

export const isset = (value: any) => {
  let hasValue = false;
  if (typeof value !== "undefined") {
    if (typeof value === "string") {
      if (!value.trim()?.length) {
        hasValue = false;
      } else {
        hasValue = true;
      }
    }
  }
  return hasValue;
};

// Helper
const _StringIsNumber = (value) => isNaN(Number(value)) === false;

// Turn enum into array
export function convertEnumToArray(enumme) {
  return Object.keys(enumme)
    .filter(_StringIsNumber)
    .map((key) => enumme[key]);
}
