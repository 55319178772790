import {
  addCashfreeDetailsApiCall,
  getCashfreeDetailsApiCall,
} from "apiCalls/siteSettingsDb";
// import { Button } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import TextInp from "commonUi/input/text/TextInp";
import { useEffect } from "react";
import { useForm, Resolver, Controller } from "react-hook-form";
import { toast } from "react-toastify";

interface IFormValues {
  appId: string;
  secretKey: string;
}

const resolver: Resolver<IFormValues> = async (values) => {
  return {
    values: values.secretKey ? values : {},
    errors: {},
  };
};

export const CashfreeDetails = () => {
  const { handleSubmit, control, getValues, setValue } = useForm<IFormValues>({
    resolver,
  });

  useEffect(() => {
    getCashfreeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCashfreeDetails = async () => {
    const cashfreeDetailsRes = await getCashfreeDetailsApiCall();
    const cashfreeDetails = cashfreeDetailsRes.result?.pgDetail;

    if (cashfreeDetails?.appId) {
      setValue("appId", cashfreeDetails["appId"]);
    }
    if (cashfreeDetails?.secretKey) {
      setValue("secretKey", cashfreeDetails["secretKey"]);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    // toast.error(data);
    const hdfcForm = getValues();
    const cashfreeUpdate = await addCashfreeDetailsApiCall(hdfcForm);
    if (cashfreeUpdate) {
      // setPgbtnDisabled(false);
      toast.success("Cashfree Details successfully saved.");
    }
  });

  return (
    <>
      <p>These are the Cashfree Details</p>
      <form onSubmit={onSubmit} className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 form-group mb-3">
          <Controller
            name="appId"
            control={control}
            required
            defaultValue=""
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"
                label="App ID"
              />
            )}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-12 form-group mb-3">
          <Controller
            name="secretKey"
            control={control}
            defaultValue=""
            required
            render={(field) => (
              <TextInp
                {...field}
                // variant="outlined"

                label="Secret Key"
              />
            )}
          />
        </div>
        <div className="text-center">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
        {/* <input type="submit" /> */}
      </form>
    </>
  );
};
