import React,{useEffect,useState} from 'react'
import axios from 'axios'
import Loading from 'components/admin/common/loading'
import {Button} from '@material-ui/core'
// will fix this page later
//right now mix arrrays method is not working properly
// what we need to do actually is that
//we get two arrays from axios
//1. comes from aws bucket
//2. comes from database
//in mix array messages we need to mix both arrays
//and get arrays of matched objects and non matched objects
// and implement delete match button for non matched objects
export default function S3(){
    const [objects,updateObjects] = useState([])
    const [dbObjects,updateDbObjects] = useState([])
    const [loading,updateLoading] = useState(true)
    const [show,setShow] = useState('apiResult')
    const [count, addCount] = useState(0)
    const getObectsFromS3 = () => {
        updateLoading(true)
        axios({
            url: '/api/admin/aws/getObjects',
            method: 'GET'
        }).then(r=>{
            console.log(r.data)
            updateObjects(r.data.Contents)
            updateLoading(false)
        })
    }

    const getObjectsFromDb = () => {
       updateLoading(true)
        axios({
            url: '/api/admin/aws/getObjectsFromDb',
            method: 'GET'
        }).then(r=>{
            updateLoading(false)
            console.log('DB',r.data)
            updateDbObjects(r.data)
            mixArrays()
        })
    }

    const mixArrays = () => {
        console.log('trigerred',objects.length,dbObjects.length)
        objects.forEach((obj,key)=>{
            console.log('forEach',key)
            dbObjects.filter((db,i) => {
                console.log('filter',i)
                if(db.key === obj.Key){
                    let newDbObjects = dbObjects
                    newDbObjects[i].matched = true
                    updateDbObjects(newDbObjects)
                    let newS3Objects = objects
                    newS3Objects[key].dbObj = db
                    updateObjects(newS3Objects)
                    return true
                }
                return true
            })
        })
    }

    useEffect(() => {
        getObectsFromS3()
        getObjectsFromDb()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const showDb = () => {
        setShow('dbResult')
        console.log(show)
    }

    const errorImage = (obj) => {
        addCount(count + 1)
        axios({
            url: '/api/admin/packages/s3Delete',
            method: 'POST',
            data: {key: obj.key,_id: obj._id}
        }).then(r=>{
            console.log(r.data)
        })

    }

   

    return (
        <>
       
            <Loading loading={loading} />
            <div className="p1">
                <h1>Objects stored in S3</h1>
                {objects.length > 0 && `${objects.length} Results found in bucket`}
                {dbObjects.length > 0 && ` & ${dbObjects.length} Results found in Database`}

            </div>            
            <Button variant="contained" color="primary" onClick={showDb}>
                Show DB Results
            </Button>
            <h1>
                {count}
            </h1>
             {show}
             {
               dbObjects.reverse().map((res,i)=>{
                 
                return(
                   <div key={i}>
                       <img src={res.location ? res.location : res.Location}
                       style={{width: '200px'}}
                       alt="aws" onError={()=>{errorImage(res)}} />
                    <pre>
                        {JSON.stringify(res,null,2)}
                    </pre>
                    </div>
                )
            })
            }
            {(show === 'apiResult') && 
            <div className="grid200">
            
            {
                objects && objects.map((obj,i) => (
                    <div key={i} className="card">
                        <img 
                        src={`https://whitelabel-sippi.s3.ap-south-1.amazonaws.com/${obj.Key}`} alt={obj.Key} />
                        <pre>
                            {JSON.stringify(obj,null,2)}
                        </pre>
                       
                    </div>
                ))
            }
           
           
            </div>
        }
        </>
    )
}