import React from 'react'
import {Form,Label,Button} from 'semantic-ui-react'
import * as yup from 'yup'
import {Formik, Field} from 'formik'
import axios from 'axios'
export default class NewCountryForm extends React.Component {


    constructor(props){
        super()
    }

    schema = yup.object({
        countryName: yup.string().label('Country Name').required()
    })
    printInpText = (name,label,errors,touched) => {
        
        return(
        <Form.Field>
            <Field
            name={name}
            placeholder={label}
            error={errors[name]}
            />
            {(errors[name] && touched[name]) && <Label basic color="red" pointing>{errors[name]}</Label>}
        </Form.Field>
        )
    }

    render =() => {
    return (
        <Formik
        validateOnChange={true}
        validationSchema={this.schema}
        initialValues={{
            countryName: ""
        }}
        onSubmit={(data,actions)=>{
   
            axios({
                url: '/api/admin/master/addCountry',
                method: 'POST',
                data: {
                    countryName: data.countryName
                },
                headers:{
                    'auth-token': localStorage.getItem('auth-token')
                }
            }).then(r=>{
                console.log(r.data)
                actions.setSubmitting(false)
                this.props.getCountries()
            })
        }}
        >
            {({errors,touched,isSubmitting,handleSubmit})=>(
                <Form onSubmit={handleSubmit} loading={isSubmitting}>
                    {this.printInpText('countryName','Country Name',errors,touched)}
                    <Button type='submit'>Submit</Button>
                
                </Form>
                
            )}
            
        </Formik>
    )
            }
}
