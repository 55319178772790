import { useEffect, useState } from "react";
import { Button, Card, Collapse, Modal } from "react-bootstrap";
import { postRequest } from "tools/ajax";
import apiSettingStyle from "./ApiSettings.module.scss";
import { toast } from "react-toastify";
import { SingleApiDetails } from "./SingleApiDetails";
import { EditApiSettingsPopup } from "./EditApiSettingsPopups";

export interface IUserApiIntegration {
  id: number;
  apiCredentials: any;
  apiName: string;
  updated_at: string;
  isActive: boolean;
  wlFlightApiCredentialName: string;
  isApiSaleAllowed: boolean;
  isB2bSaleAllowed: boolean;
  isB2cSaleAllowed: boolean;
  is_fixed_departure_api: boolean;
}

export const ApiSettings: React.FC<{}> = () => {
  const [selectedApi, setSelectedApi] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [showEditApiSettingsPopup, setShowEditApiSettingsPopup] = useState<
    undefined | number
  >(undefined);
  const [allWhiteLabelApiIntegrations, setAllWhiteLabelApiIntegrations] =
    useState<IUserApiIntegration[]>([]);

  const getApiIntegrations = async () => {
    setLoading(true);
    const apiRes = await postRequest("user/getApiIntegrationsForCustomer", {});
    if (apiRes?.success) {
      setAllWhiteLabelApiIntegrations(apiRes?.result);
    }
    setLoading(false);
  };

  const refreshSectors = async (credId: number) => {
    const apiRes = await postRequest("user/refreshApiSectors", {
      credId,
    });
    if (apiRes?.success) {
      toast.success("Sectors refreshed");
    } else {
      toast.error(apiRes?.errorMessage || "Something went wrong");
    }
  };

  useEffect(() => {
    getApiIntegrations();
  }, []);
  return (
    <div className={apiSettingStyle.apiSettingPage}>
      <div className="container">
        <p className="h3 mx-auto my-4 text-center">API Settings</p>
        <div className=" w-100">
          <div className=" row p-2  small d-flex align-items-center bg-secondary rounded font-weight-bold text-white mb-2">
            <div className=" col-4">API / Credential name</div>
            <div className=" col-8">
              <div className="row">
                <div className="col-2">Active</div>
                <div className="col-3">Api Sale Allowed</div>
                <div className="col-3">B2b Sale Allowed</div>
                <div className="col-3">B2c Sale Allowed</div>
                <div className="col-1">Edit</div>
              </div>
            </div>
          </div>
          {allWhiteLabelApiIntegrations?.length ? (
            allWhiteLabelApiIntegrations?.map((data, index) => {
              return (
                <div className=" row p-2  d-flex border font-weight-bold mb-3 shadow shadow-sm">
                  <div className=" col-4 text-uppercase">
                    {index + 1}. <b>{data.apiName}</b> /{" "}
                    <span className="small">
                      {" "}
                      {data.wlFlightApiCredentialName}{" "}
                      {data.is_fixed_departure_api && (
                        <button
                          className="btn btn-sm btn-primary ms-2 py-0"
                          onClick={() => refreshSectors(data.id)}
                        >
                          Refresh Sectors
                        </button>
                      )}
                    </span>
                  </div>
                  <div className="col-8">
                    <SingleApiDetails
                      editApiSettings={() =>
                        setShowEditApiSettingsPopup(data.id)
                      }
                      apiDetails={data}
                    />
                  </div>
                  <div className=" col-4 ">
                    <div className=" bg-white">
                      <Collapse in={selectedApi === data.id}>
                        <div id="example-collapse-text">
                          {Object.keys(data.apiCredentials).map(
                            (cred: string) => (
                              <div className="py-2 " key={cred}>
                                <b>{cred}:</b>
                                <p
                                  style={{
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {data.apiCredentials[cred]}
                                </p>
                              </div>
                            )
                          )}
                          {!Object.keys(data.apiCredentials).length && (
                            <span className="text-danger small">
                              No Credentials Found
                            </span>
                          )}
                        </div>
                      </Collapse>
                      <span
                        role="button"
                        className=" text-primary small "
                        onClick={() => {
                          if (selectedApi === data.id) {
                            setSelectedApi(undefined);
                          } else {
                            setSelectedApi(data.id);
                          }
                        }}
                      >
                        {data.id === selectedApi ? "Hide" : "Show Credentials"}
                        <i
                          className={`fas fa-chevron-${
                            data.id === selectedApi ? "up" : "down"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  {showEditApiSettingsPopup === data.id && (
                    <EditApiSettingsPopup
                      apiDetails={data}
                      getApiIntegrations={getApiIntegrations}
                      show={showEditApiSettingsPopup === data.id}
                      onHide={() => setShowEditApiSettingsPopup(undefined)}
                    />
                  )}
                </div>
              );
            })
          ) : loading ? (
            <div className="w-100 text-center"> Loading...</div>
          ) : (
            <div className="w-100 text-center text-danger"> Not Found</div>
          )}
        </div>
      </div>
    </div>
  );
};
