import React from "react";
import SupplierList from "./SupplierList";
import { PackageSupplierCategories } from "./PackageSupplierCategories";

export const SupplierManagement = () => {
  return (
    <div>
      <div className="container">
        <h1>
          <span className="badge bg-secondary">New Supplier Module</span>
        </h1>
        <div className="row">
          <div className="col-md-12 mb-4">
            <SupplierList />
          </div>
          <div className="col-md-6">
            <PackageSupplierCategories />
          </div>
        </div>
      </div>
    </div>
  );
};
