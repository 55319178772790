import paymentImage from "../../../assets/images/paymentScreenImages/payment-page.png";
export const PaymentScreenSkelton: React.FC<{}> = () => {
  return (
    <div className="pay-payment-details-container border shadow">
      <img className="img-fluid image" src={paymentImage} alt="img" />
      <div className="payment-details mt-2">
        <h5 className="ms-2">Proceed To Pay</h5>
        <div className="details container-fluid">
          <div className="d-flex justify-content-between my-2 row">
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
          </div>

          <div className="d-flex justify-content-between my-2 row">
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
          </div>
          <div className="d-flex justify-content-between my-2 row">
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
          </div>
          <div className="d-flex justify-content-between my-2 row">
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
          </div>

          <div className="d-flex justify-content-between my-2 row">
            <p className="placeholder-glow ">
              <span className="placeholder col-12 btn bg-success"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
