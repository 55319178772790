export enum paymentTypeEnum {
  TRAVEL_WEBSITE_PURCHASE = "Travel_Website_Purchase",
  WALLET_RECHARGE = "Wallet_Recharge",
  ADDON_PURCHASE = "Addon_Purchase",
}
// export enum productTypeEnum {
//   TRAVEL_WEBSITE_PURCHASE = "Travel_Website_Purchase",
//   WALLET_RECHARGE = "Wallet_Recharge",
//   WHATSAPP = "whatsApp",
//   TFNEXUX = "tfNexus",
//   CHEAP_FIX_DEPARTURE = "cheapFixDeparture",
//   GO_FLY_SMART = "goFlySmart",
//   CALLER_DESK = "callerDesk",
//   AIR_IQ = "airIQ",
//   ADDON = "addon",
//   TBO = "tbo",
//   TRIP_JACK = "tripJack",
//   CLEAR_TRIP = "clearTrip",
//   GALILEO = "galileo",
//   PAN_CARD_VERIFICATION = "panCardVerification",
//   COMBO_PLAN_1 = "comboPlan1",
//   COMBO_PLAN_2 = "comboPlan2",
//   COMBO_PLAN_3 = "comboPlan3",
//   COMBO_PLAN_4 = "comboPlan4",
// }

export enum currencySigns {
  INR = "₹",
  USD = "$",
}
export enum currencyType {
  INR = "INR",
  USD = "USD",
}
export interface ICurrencyWisePrices {
  id: number;
  Travel_Website_Purchase: number;
  airIQ: number;
  callerDesk: number;
  cheapFixDeparture: number;
  clearTrip: number;
  tfNexux: number;
  galileo: number;
  goFlySmart: number;
  panCardVerification: number;
  tbo: number;
  tripJack: number;
  whatsApp: number;
  currency: currencyType;
  comboPlan1: number;
  comboPlan2: number;
  comboPlan3: number;
  comboPlan4: number;
}

interface comboPlanDesc {
  heading: String;
  subHeading: String;
}
export interface IComboPlans {
  description: comboPlanDesc[];
  excludedItems: comboPlanDesc[];
  planEnum: "comboPlan1" | "comboPlan2" | "comboPlan3" | "comboPlan4";
  comboPlan4;

  // inrPrice: number;
  // monthlyMinimum_inr: number;
  // monthlyMinimum_usd: number;
  planName: String;
  // usdPrice: number;
  // walletBalance_inr: number;
  // walletBalance_usd: number;
}

export interface IServicesPrices {
  id: number;
  Travel_Website_Purchase: number;
  airIQ: number;
  callerDesk: number;
  cheapFixDeparture: number;
  clearTrip: number;
  fdKing: number;
  galileo: number;
  goFlySmart: number;
  panCardVerification: number;
  tbo: number;
  tripJack: number;
  whatsApp: number;
  currency: currencyType;
  comboPlan1: number;
  comboPlan2: number;
  comboPlan3: number;
  comboPlan4: number;
}

export enum serviceEnum {
  PAN_CARD_VERIFICATION = "panCardVerification",
  CALLER_DESK = "callerDesk",
  WHATSAPP = "whatsApp",
  FLIGHT_BOOKING = "flight_booking",
  FLIGHT_SEARCH = "flight_search",
}
