import React from "react";
// import TextField from "@material-ui/core/TextField";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
const TextInp: React.FC<{
  label: string;
  value: string;
  name: string;
  type?: string;
  onChange?: any;
  required?: boolean;
}> = ({ label, ...props }) => {
  return (
    <>
      {/* <TextField label={label} variant="outlined" {...props} /> */}

      <Form.Group>
        <FloatingLabel
          className="input-label"
          controlId="floatingInput"
          label={label}
        >
          {/* <Form.Label className="input-label">{label}</Form.Label> */}
          <Form.Control
            {...props}
            value={props.value}
            name={props.name}
            onChange={props.onChange ? props.onChange : () => {}}
          />
        </FloatingLabel>
      </Form.Group>
    </>
  );
};

export default TextInp;
