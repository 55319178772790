import {postRequest} from '../tools/ajax'

    export const getSelectedVisa = async (countryIdRedux) => {
        const r = await postRequest('admin/visa/getSelectedVisaCategory',{
        countryId:  countryIdRedux 
    })
        return r
    }


    export const submitNewVisaCategory = async (newVisaCategory, countryIdFromRedux,date) => {
    const r = await postRequest('admin/visa/createVisaCategory',{
        visaCategory: newVisaCategory,
        countryId: countryIdFromRedux,
        creationDate: date 
    })
        return r
    }

    export const isVisibleChangeHandler = async (_id,isVisible) => {
        let r = await postRequest('admin/visa/isVisibleChange',{
            _id,
            isVisible
        })
        return r
    }

    export const getExistingVisaChecklist = async (visaCategoryId) => {
        let r = await postRequest('admin/visa/getSelectedVisaChecklist',{
            visaCategoryId
        })
        return r
    }

    export const submitNewChecklist = async (
        visaCategoryId, 
        countryId,
        nationality_countryId,
        date,
        ckeditor
        ) => {
        const r = await postRequest('admin/visa/createVisaChecklist',{
            visaCategoryId: visaCategoryId,
            countryId: countryId,
            nationality_countryId: nationality_countryId,
            creationDate: date,
            ckeditor: ckeditor 
        })
            return r
        }

    export const updateChecklistLog = async (
        checklistId,
        updateDate,
        oldCkeditor,
        newCkeditor
        ) => {
        const r = await postRequest('admin/visa/updateVisaChecklistlog',{
            checklistId,
            updateDate,
            oldCkeditor,
            newCkeditor
        })
            return r
        }

  