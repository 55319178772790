import React from 'react'
import { Avatar, createStyles, makeStyles, Theme } from '@material-ui/core'
import { Flight as FlightIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
    green: {
      color: "#fff",
      backgroundColor: "grey",
    },
  })
);

export const AirlineLogo: React.FC<{
    logoUrl: string|undefined
}> = (props) => {

    const classes = useStyles();

    return (
        props.logoUrl ? (
            <Avatar
                style={{
                    width: 50,
                    height: 50,
                    margin: "0 auto",
                }}
                src={props.logoUrl}
            />
        ) : (
            <Avatar
                className={`${classes.green} mx-auto`}
                style={{
                    width: 50,
                    height: 50,
                    margin: "0 auto",
                }}
            >
                <FlightIcon />
            </Avatar>
        )
    )
}
