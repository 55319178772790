import React from "react";
import { Link } from "react-router-dom";

export default function WhitelabelMenu() {
  const printItem = (name, path) => {
    return (
      <Link to={path}>
        <button className="btn btn-primary m-1">{name}</button>
      </Link>
    );
  };
  return (
    <div className="whitelabelMenu text-center">
      <div className="btn-group ">
        {printItem("View WhiteLabel", "/admin/whiteLabel")}
        {printItem(
          "Payment Gateway List",
          "/admin/whitelabel/payment-gateway-list"
        )}
        {/* {printItem('Create','/admin/whiteLabel/create')} */}
      </div>
    </div>
  );
}
