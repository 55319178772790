import { getRequest, postRequest } from "tools/ajax";

export class FixedDepartureService {
  static getFDKingMappings = async () => {
    const fdKingMappings = await getRequest(
      "admin/fdKing/allFdKingMapping",
      {}
    );
    return fdKingMappings;
  };
  
  static addFDKingMappings = async (fdKingMap) => {
    const fdKingMappings = await postRequest(
      "admin/fdKing/saveFdKingMapping",
      fdKingMap
    );
    return fdKingMappings;
  };
 
  static getFDKingExceptions = async () => {
    const fdKingExceptions = await postRequest(
      "admin/fdKing/getFDKingExceptions",
      {}
    );
    return fdKingExceptions;
  };
  
  static deleteFDKingMapping = async (mappingId) => {
    const fdKingMappings = await postRequest(
      "admin/fdKing/deleteFdkingMapping",
      {mappingId}
    );
    return fdKingMappings;
  };
}
