import { AddonsData } from "components/user/Addons/AddonData";
import { IUserData } from "components/user/UserWallet/PaymentScreen";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "tools/ajax";
import {
  IComboPlans,
  IServicesPrices,
  currencySigns,
} from "types/wallet.types";
export const SelectAddonBeforePaymentScreen: React.FC<{}> = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<IUserData | undefined>(undefined);
  const [showdetails, setShowDetails] = useState(false);
  const [comboPlan, setComboPlan] = useState<IComboPlans | undefined>(
    undefined
  );
  const [additionalPurchases, setAdditionalPurchases] = useState<
    | {
        label: string;
        product: string;
        price: number;
      }[]
    | undefined
  >(undefined);
  const [alreadyAddedModules, setAlreadyAddedModules] = useState<any[]>();
  const [servicesPrices, setServicesPrices] = useState<
    IServicesPrices | undefined
  >(undefined);
  const params = new URLSearchParams(window.location.search);
  const orderId = params.get("orderId");
  useEffect(() => {
    getUserDataByOrderId();
    getAlreadyPurchasedAPIs();
  }, []);
  useEffect(() => {
    if (userData) {
      //   setTotalPayableAmount(parseFloat(userData.amount.toString()));
      getComboPlans();
      getPrices();
    }
    if (alreadyAddedModules?.length) {
      const modules: any[] = [];
      for (let module of alreadyAddedModules) {
        modules.push({
          product: module.service,
          price: parseFloat(module.price),
          label: module.service,
        });
      }
      setAdditionalPurchases(modules);
    }
    // getAlreadyPurchasedModues();
  }, [userData, alreadyAddedModules]);

  // const currencyWisePrices = useAppSelector(
  //   (state) => state?.currencyWisePrices?.currencyWisePrices
  // );

  const getUserDataByOrderId = async () => {
    setLoading(true);
    if (!orderId) {
      return;
    }
    const userDataRes = await getRequest("wallet/getUserDataByOrderId", {
      orderId: orderId,
    });
    if (userDataRes?.success) {
      setUserData(userDataRes.result);
    }
    setLoading(false);
  };

  const getPrices = async () => {
    setLoading(true);
    try {
      const res = await getRequest("wallet/getServicePrices", {
        currency: userData?.currency,
      });
      setServicesPrices(res.result);
      //   console.log(res);
    } catch (error: any) {
      console.log(error);
    }
    setLoading(false);
  };
  // console.log(additionalPurchases);

  const getTotal = () => {
    if (userData?.amount && servicesPrices) {
      // let total = parseFloat(userData?.amount.toString());
      let total = servicesPrices[userData.productType];
      if (additionalPurchases) {
        for (let serivice of additionalPurchases) {
          total += serivice.price;
        }
      }
      return total.toFixed(2);
    }
  };

  const proceedToPay = async () => {
    if (loading) return;
    setLoading(true);
    if (additionalPurchases?.length) {
      let additionalPurchasesObj: any[] = [];
      additionalPurchasesObj = additionalPurchases.filter((item) => {
        if (_.find(alreadyAddedModules, { service: item.product }))
          return false;
        else return true;
      });
      const response = await postRequest("wallet/addAdditionalPurchases", {
        additionalPurchases: additionalPurchasesObj,
        orderId,
      });
      if (response?.success) {
        history.push(
          `/user/payment_screen?orderId=${orderId}&redirectRoute=/user/dashboard`
        );
      } else {
        toast.error("something went wrong");
      }
    } else {
      history.push(
        `/user/payment_screen?orderId=${orderId}&redirectRoute=/user/dashboard`
      );
    }
    setLoading(false);
  };

  const getAlreadyPurchasedAPIs = async () => {
    try {
      const res = await getRequest("wallet/getAlreadyPurchasedAPIs", {
        orderId,
      });
      setAlreadyAddedModules(res.result);
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };
  const getComboPlans = async () => {
    try {
      const comboPlansRes = await getRequest("wallet/getComboPlans", {
        comboPlan: userData?.productType,
      });
      if (comboPlansRes?.success) {
        setComboPlan(comboPlansRes.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const planDetails = _.find(comboPlan, {
  //   planEnum: userData?.productType,
  // });

  return (
    <div className="container mt-4 " id="additionalPurchase">
      <div className="row">
        <div className="col-12 col-md-8 ">
          <h3 className="pb-2 mb-0 mt-2">
            Upgrade Your Website with Multiple APIs!
          </h3>
          <p className="small text-secondary ">
            Enhance your website's functionality and versatility all in one go!
            Explore our range of APIs and elevate your online presence
            effortlessly.
          </p>
          {AddonsData.filter((addon) => {
            if (_.find(alreadyAddedModules, { service: addon.addonEnum }))
              return false;
            else return true;
          }).map((addon, key) => {
            return (
              <div
                key={key}
                className="addon-item row border mb-2 rounded align-items-center py-2 px-md-0 px-2"
                onClick={() => {
                  let addonObj = {
                    label: addon.addonName,
                    product: addon.addonEnum,
                    price: servicesPrices && servicesPrices[addon.addonEnum],
                  };

                  let updateAdditionalPurchases: any[] = [];

                  if (additionalPurchases) {
                    updateAdditionalPurchases.push(...additionalPurchases);
                  }

                  const existingIndex = additionalPurchases?.findIndex(
                    (pur) => pur.product === addonObj.product
                  );

                  if (typeof existingIndex === "number" && existingIndex >= 0) {
                    updateAdditionalPurchases.splice(existingIndex, 1);
                  } else {
                    updateAdditionalPurchases.push(addonObj);
                  }

                  setAdditionalPurchases(updateAdditionalPurchases);

                  //   setAdditionalPurchases((old: any) => {
                  //     if (old) {
                  //       if (_.find(old, { product: addon.addonEnum })) {
                  //         _.remove(old, { product: addon.addonEnum });
                  //         setTotalPayableAmount(
                  //           (old) =>
                  //             old && parseFloat(old.toString()) - addonObj.price
                  //         );
                  //         return [...old];
                  //       } else {
                  //         setTotalPayableAmount(
                  //           (old) =>
                  //             old && parseFloat(old.toString()) + addonObj.price
                  //         );
                  //         return [...old, addonObj];
                  //       }
                  //     } else {
                  //       setTotalPayableAmount(
                  //         (old) =>
                  //           old && parseFloat(old.toString()) + addonObj.price
                  //       );
                  //       return [addonObj];
                  //     }
                  //   });
                }}
              >
                <div className="col-1 d-flex align-items-center justify-content-center">
                  <div className="form-check fs-4">
                    <input
                      checked={
                        _.find(additionalPurchases, {
                          product: addon.addonEnum,
                        })
                          ? true
                          : false
                      }
                      className="form-check-input"
                      type="checkbox"
                      value={addon.addonEnum}
                      id="flexCheckDefault"
                      onChange={() => {}}
                    />
                  </div>
                </div>
                <div className="addonImage col-3 p-0">
                  <img src={addon.data.logoImage} alt="" />
                </div>
                <div className="col-6  col-md-8">
                  <b>{addon.addonName}</b>
                  <br />
                  <span className="small text-muted">
                    {addon.data.shortDescription}
                  </span>
                </div>
                <div className="col-3 col-md-2 text-end">
                  {" "}
                  <b>
                    {userData?.currency && currencySigns[userData.currency]}
                    {servicesPrices &&
                      parseFloat(servicesPrices[addon.addonEnum]).toFixed(2)}
                  </b>{" "}
                </div>
              </div>
            );
          })}
        </div>
        <div className=" col-4 mt-5 purchase-summary">
          <div className="bg-light p-4 rounded border sticky-top  ">
            <div className="d-flex justify-content-between border-bottom pb-3 mb-4">
              <div className="text-capitalize">
                {comboPlan && comboPlan?.planName} Plan{" "}
              </div>
              <div>
                {userData?.currency && currencySigns[userData.currency]}
                {servicesPrices &&
                  userData &&
                  servicesPrices[userData.productType]}{" "}
              </div>
            </div>
            {additionalPurchases &&
              additionalPurchases.map((item, key) => {
                return (
                  <div
                    className="d-flex justify-content-between border-bottom pb-4 mb-4"
                    key={key}
                  >
                    <div className="text-capitalize">{item?.label} </div>{" "}
                    <div>
                      {" "}
                      {userData?.currency && currencySigns[userData.currency]}
                      {item.price.toFixed(2)}{" "}
                    </div>
                  </div>
                );
              })}
            <div className="d-flex justify-content-between  mb-3 py-3">
              <div>Total</div>
              <div>
                <b>
                  {" "}
                  {userData?.currency && currencySigns[userData.currency]}
                  {getTotal()}
                  {/* {totalPayableAmount?.toFixed(2)} */}
                </b>{" "}
              </div>
            </div>
            <div className="d-flex justify-content-between  ">
              <button className="btn btn-primary w-100" onClick={proceedToPay}>
                {loading ? "Loading..." : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* next button for mobile view */}
      <div className="purchase-summary-sm sticky-bottom shadow rounded p-2 border pt-4 fs-6">
        {showdetails ? (
          <>
            {" "}
            <button
              className="btn btn-outline-primary mb-3"
              onClick={() => {
                setShowDetails(false);
              }}
            >
              Hide Details
            </button>
            <div className="d-flex justify-content-between border-bottom pb-4 mb-4 w-100">
              <div>{userData && comboPlan?.planName} Plan</div>
              <div>
                {" "}
                {userData?.currency && currencySigns[userData.currency]}
                {servicesPrices &&
                  userData &&
                  servicesPrices[userData.productType]}
              </div>
            </div>
            {additionalPurchases &&
              additionalPurchases.map((item, key) => {
                return (
                  <div
                    className="d-flex justify-content-between border-bottom pb-4 mb-4 w-100"
                    key={key}
                  >
                    <div className="text-capitalize">{item?.label} </div>{" "}
                    <div>
                      {" "}
                      {userData?.currency && currencySigns[userData.currency]}
                      {item.price.toFixed(2)}{" "}
                    </div>
                  </div>
                );
              })}{" "}
          </>
        ) : (
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              setShowDetails(true);
            }}
          >
            Show Details
          </button>
        )}

        <div className="d-flex justify-content-center  mb-3 py-3 w-100">
          <div className="mx-2">Total: </div>
          <div className="mx-2">
            <b>
              {userData?.currency && currencySigns[userData.currency]}
              {getTotal()}
              {/* {totalPayableAmount?.toFixed(2)} */}
            </b>{" "}
          </div>
        </div>
        <button className="btn btn-primary w-75" onClick={proceedToPay}>
          {loading ? "Loading..." : "Next"}
        </button>
      </div>
    </div>
  );
};
