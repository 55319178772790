import React, { useState } from "react";
import {
  TextField,
  Button,
  makeStyles,
  Grid,
  Typography,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSelectedVisa, submitNewVisaCategory } from "apiCalls/visaDb";
import {
  SET_VISACATEGORIES,
  SET_COUNTRY,
  SET_SELECTED_VISACATEGORY,
  SET_NATIONALITY,
  SET_VISACHECKLISTS,
  GET_ALL_COUNTRIES,
  SET_SELECTED_VISA_CHECKLIST,
} from "redux/actions/visaActions";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "left",
    marginBottom: "10px",
    justifyContent: "center",
    alignSelf: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CreateVisaCategory() {
  const classes = useStyles();
  const country = useSelector(
    (state) => state.visaChecklist.selectedCountry.countryName
  );
  const countryIdFromRedux = useSelector(
    (state) => state.visaChecklist.selectedCountry._id
  );
  const [open, setOpen] = React.useState(false);
  const [newVisaCategory, setNewVisaCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    getSelectedVisa(countryIdFromRedux);
  }, [countryIdFromRedux]);

  //Component will unmount
  React.useEffect(() => {
    return () => {
      dispatch(GET_ALL_COUNTRIES([]));
      dispatch(SET_VISACATEGORIES([]));
      dispatch(SET_COUNTRY([]));
      dispatch(SET_NATIONALITY([]));
      dispatch(SET_SELECTED_VISACATEGORY([]));
      dispatch(SET_VISACHECKLISTS([]));
      dispatch(SET_SELECTED_VISA_CHECKLIST([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const newVisaCategoryHandleChange = (e) => {
    setNewVisaCategory(e.target.value);
  };

  const submit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const date = moment().format();
    const r = await submitNewVisaCategory(
      newVisaCategory,
      countryIdFromRedux,
      date
    );
    if (r.err) return toast.error(r.err);
    const categories = await getSelectedVisa(countryIdFromRedux);
    dispatch(SET_VISACATEGORIES(categories));
    setLoading(false);
    dispatch(SET_NATIONALITY([]));
    dispatch(SET_SELECTED_VISACATEGORY([]));
    dispatch(SET_VISACHECKLISTS([]));
    dispatch(SET_SELECTED_VISA_CHECKLIST([]));
  };

  return (
    <>
      {country ? (
        <Grid container={true} className={classes.root} direction="column">
          <Grid item>
            <Button color="primary" variant="contained" onClick={handleOpen}>
              + Add New Visa Category
            </Button>
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {loading === true ? <CircularProgress /> : ""}
            <form onSubmit={submit}>
              <Button
                vairant="outlined"
                color="secondary"
                style={{ float: "right" }}
                onClick={handleClose}
              >
                X
              </Button>
              <Typography variant="h4" noWrap={true}>
                Create Visa Category
              </Typography>
              <Typography>Selected Country: {country}</Typography>
              <TextField
                variant="outlined"
                label="Visa Category Name Here"
                style={{
                  marginBottom: "4px",
                  marginTop: "5px",
                  width: "300px",
                }}
                name="visaCategoryName"
                size="medium"
                onChange={(e) => {
                  newVisaCategoryHandleChange(e);
                }}
              />
              <br />
              <Button variant="contained" color="primary" type="submit">
                Create
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
