import React from "react";
import { Link } from "react-router-dom";

export const AdvanceSettings = () => {
  return (
    <div className="container">
      <div className="d-flex w-100 " style={{ justifyContent: "space-around" }}>
        <Link to="/admin/advance_settings/error_logs" title="Error Logs">
          Error Logs
        </Link>
        <Link to="/admin/advance_settings/fdking_mapping">FD King Mapping</Link>
        <Link to="/admin/advance_settings/tbo_logs">TBO Logs</Link>
        <Link to="/admin/advance_settings/duplicate_email_phone">
          Duplicate Emails/Phone
        </Link>
        <Link to="/admin/advance_settings/supplier_email_logs">
          Flight Supplier Email Logs
        </Link>
        {/* <Link to="/admin/advance_settings/fallback_DWP">Fallback DWP</Link> */}
      </div>
    </div>
  );
};
