import React, {useState,useEffect} from 'react'
import ListAdmins from './ListAdmins'
import NewUserForm from "./NewUserForm"

const axios = require('axios')
function AdminDashboard (){


    const [users,setUsers] = useState([])
    const [userListLoading, setUserListLoading] = useState(false)
    // const [userDeleteMessage,setUserDeleteMessage] = useState(false)
    const [selectedUser,setSelectedUser] = useState(null)

    useEffect(()=> {
        getUsers()
    },[])

    const getUsers = () => {
        setUserListLoading(true)
        axios({
            url: '/api/admin/user/',
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('auth-token')
            }
        }).then(r => {
            console.log('here',r.data)
            setUsers(r.data.users)
            setUserListLoading(false)
        })
    }

    const deleteUser = (_id) => {
        console.log('delete user with id ' + _id)
        axios({
            url: "/api/admin/user/deleteUser",
            data: {_id},
            method: "DELETE",
            headers: {
                'auth-token': localStorage.getItem('auth-token')
            }
        }).then(r=>{
            getUsers()
            // setUserDeleteMessage(true)
            // setTimeout(()=>{
            //     setUserDeleteMessage(false)
            // },3000)
        })
    }

    const selectUser = (data,i) => {
        setSelectedUser(data)
    }
  
    return(
        <>
            <div className="grid250">
            <ListAdmins 
            users={users}
            loading={userListLoading}
            deleteUser={deleteUser} 
            selectUser={selectUser}
            />
            <NewUserForm 
                selectedUser={selectedUser} 
                getUsers={getUsers} 
            />
            </div>
        </>
    )
    

}

export default AdminDashboard