import React, { Component } from "react";
import CountriesCard from "./CountriesCard";
import CitiesCard from "./CitiesCard";
import { AdminMasterMenu } from "../AdminMasterMenu";
export default class MongoCountryCityMaster extends Component {
  render() {
    return (
      <>
        <AdminMasterMenu />
        <div className="container">
          <h1 className="h4 mt-4">Mongo DB Country City Master</h1>
        </div>
        <div className="grid300">
          <CountriesCard />

          <CitiesCard />
        </div>
      </>
    );
  }
}
