import { SippiAccountsApi } from "apiCalls/SippiAccounts.api";
// import WhitelabelMenu from "components/admin/whiteLabel/whitelabelMenu";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { SET_LOADER } from "redux/actions/common.actions";
import { IInvoice } from "types/accounts.types";
import { AccountAddDiscountPopup } from "./AccountAddDiscountPopup";
import { AccountEmailChangePopup } from "./AccountEmailChangePopup";
import { SendEmailConfirmationDialog } from "./SendEmailConfirmationDialog";

const dateFormat = "MMMM yyyy";

export const MonthlyChargesReport = () => {
  const dispatch = useDispatch();
  const [selectedMonthYear, setSelectedMonthyYear] = useState(
    moment().format(dateFormat)
  );
  const [monthlyChargesData, setMonthlyChargesData] = useState<IInvoice[]>([]);
  const [accountDetailsState, setAccountDetailsState] = useState<
    | {
        id: number;
        discount: number;
        total: number;
        isPaid: boolean;
        adminRemark: string;
      }
    | undefined
  >(undefined);
  const [emailConfirmationState, setEmailConfirmationState] =
    useState<boolean>(false);

  const [emailChangePopup, setEmailChangePopup] = useState<
    | {
        email: string;
        mongoDb_siteId: string;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    getMonthlyCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonthYear]);

  const getMonthlyCharges = async () => {
    let month = moment(selectedMonthYear).format("MM");
    let year = moment(selectedMonthYear).format("YYYY");
    dispatch(SET_LOADER(true));
    const response = await SippiAccountsApi.getBillingDataForAllSitesByMonth(
      month,
      year
    );
    dispatch(SET_LOADER(false));
    if (!response?.success || !response?.result) {
      return toast.error(
        response?.errorMessage || "Error getting monthly charges"
      );
    }

    setMonthlyChargesData(response?.result);
  };

  const handleMonthYearChange = (event: any) => {
    setSelectedMonthyYear(event.target.value);
  };

  const sendInvoiceEmail = async (invoiceId) => {
    dispatch(SET_LOADER(true));
    try {
      const sendEmails = await SippiAccountsApi.sendEmailToSingleWhitelabel(
        invoiceId
      );
      if (sendEmails.result?.success) {
        toast.success("Email sent successfully");
      } else {
        throw new Error(
          sendEmails.result?.errorMessage ||
            "Emails could not be sent. Please try again later"
        );
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    }
    dispatch(SET_LOADER(false));
  };

  const printMonthYears = (noOfMonths) => {
    return [...Array(noOfMonths)].map((i, index) => (
      <option
        key={index}
        value={moment().subtract(index, "months").format(dateFormat)}
      >
        {moment().subtract(index, "months").format(dateFormat)}
      </option>
    ));
  };

  return (
    <div className="container">
      {emailChangePopup && (
        <AccountEmailChangePopup
          email={emailChangePopup?.email}
          mongoDb_siteId={emailChangePopup?.mongoDb_siteId}
          hidePopup={() => {
            getMonthlyCharges();
            setEmailChangePopup(undefined);
          }}
        />
      )}
      {accountDetailsState && (
        <AccountAddDiscountPopup
          adminRemark={accountDetailsState.adminRemark}
          discount={accountDetailsState.discount}
          total={accountDetailsState.total}
          isPaid={accountDetailsState.isPaid}
          id={accountDetailsState.id}
          hidePopup={() => {
            getMonthlyCharges();
            setAccountDetailsState(undefined);
          }}
        />
      )}
      {emailConfirmationState && (
        <SendEmailConfirmationDialog
          monthYear={moment(selectedMonthYear, dateFormat).format("yyyy-MM")}
          closeConfirmation={() => {
            setEmailConfirmationState(false);
          }}
        />
      )}

      <p className="h4">Monthly Charges Report</p>
      <div className="row">
        <Form.Group className="col-md-3" onChange={handleMonthYearChange}>
          <Form.Label className="mb-0 text-muted">
            Select Billing Period
          </Form.Label>
          <Form.Select>{printMonthYears(30)}</Form.Select>
          <button
            className="btn btn-primary mt-2"
            onClick={() => {
              setEmailConfirmationState(true);
            }}
          >
            Send Email
          </button>
        </Form.Group>
        {monthlyChargesData.length} Results <br />
        {monthlyChargesData.map((monthly, index) => (
          <div className="row shadow my-3 p-2">
            <div className="col-md-3">
              <b>{index + 1}. Website</b>: {monthly?.whitelabel?.domain} <br />(
              {monthly?.whitelabel?.domain})
              <br />
              <b>Accounts Email:</b> {monthly?.whitelabelMeta?.accountsEmail}{" "}
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setEmailChangePopup({
                    mongoDb_siteId: monthly?.whitelabel?.mongoDb_siteId,
                    email: monthly?.whitelabel?.accountsEmail,
                  });
                }}
              >
                Change
              </button>
              <br />
              <b>Admin Contact:</b> {monthly?.whitelabel?.adminContact}
              <br />
              {monthly?.isPaid ? (
                <span className="badge rounded-pill bg-success">Paid</span>
              ) : (
                <span className="badge rounded-pill bg-danger">Not Paid</span>
              )}
              {monthly?.isInvoiceSent && (
                <span className="badge rounded-pill bg-primary">Sent</span>
              )}
              <hr />
              <b>Total: </b>
              {monthly.subtotal}
              <br />
              <b>Tax: </b> {monthly.tax}
              <br />
              <b>Discount: </b>
              {monthly.discount}
              <br />
              {/* <b>Payment Status:</b> {monthly.isPaid ? "Paid" : "Not Paid"}
              <br /> */}
              <b>Min. Monthly Amount: </b>
              {monthly.currency}
              {monthly.minMonthlyAmount}
              <button
                onClick={() => {
                  setAccountDetailsState({
                    id: monthly.id,
                    discount: monthly.discount,
                    isPaid: monthly.isPaid,
                    total: monthly.subtotal,
                    adminRemark: monthly.adminRemark,
                  });
                }}
                className="btn btn-outline-danger m-1 btn-sm"
              >
                Change Invoice
              </button>
              {!monthly.isPaid && (
                <button
                  onClick={() => {
                    sendInvoiceEmail(monthly.id);
                  }}
                  className="btn btn-primary"
                >
                  Send Invoice Email
                </button>
              )}
            </div>
            <div className="col-md-4">
              <table className="table table-bordered border table-sm">
                <thead>
                  <tr>
                    <th colSpan={2} className="text-center table-dark">
                      Live Flight API
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {monthly.invoiceBreakups
                    .filter((br) => br.breakupGroup === "flight")
                    .map((breakup, index) => (
                      <tr>
                        <td>{breakup.label}</td>
                        <td>
                          {breakup.currency} {breakup.amountValue}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-5">
              <table className="table table-bordered table-sm border">
                <thead>
                  <tr>
                    <th colSpan={2} className="text-center table-dark">
                      Fix Departures
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {monthly.invoiceBreakups
                    .filter((br) => br.breakupGroup === "fixedDeparture")
                    .map((breakup, index) => (
                      <tr>
                        <td>{breakup.label}</td>
                        <td>
                          {breakup.currency} {breakup.amountValue}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
