import { Button, Modal } from "react-bootstrap";
import { currencyConfig } from "types/sippiCurrencyTypes";
import { currencySigns, currencyType } from "types/wallet.types";

export const PayOnlineModal: React.FC<{
  show: boolean;
  onHide: () => void;
  amount: number | undefined;
  totalAmount: number | undefined;
  tax: number | undefined;
  remark: string | undefined;
  orderId: string | null;
  onProceedPayment: () => void;
  currency: currencyType | undefined;
}> = (props) => {
  let ROE = currencyConfig.ROE;

  const currencySign = props.currency && currencySigns[props.currency];

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter "
          className="text-success"
        >
          Online Payment <i className="far fa-credit-card"></i>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex justify-content-between my-2 border-bottom pb-2">
            <span className="text-muted">Paying For : </span>
            <span className="text-end small w-75">{props.remark}</span>
          </div>
          <div className="d-flex justify-content-between my-2">
            <span className="text-muted">Order id : </span>
            <span>{props.orderId}</span>
          </div>
          <div className="d-flex justify-content-between my-2">
            <span className="text-muted">Price : </span>
            <span>
              {currencySign}
              {props.amount}
            </span>
          </div>
          <div className="d-flex justify-content-between my-2">
            <span className="text-muted">
              {currencyConfig.baseCurrencyTaxLabel} (
              {currencyConfig.baseCurrencyTaxPercentage}%) :{" "}
            </span>
            <span>
              {currencySign}
              {props.tax}
            </span>
          </div>{" "}
          <div className="d-flex justify-content-between my-2">
            <span className="text-muted">
              <b>Payable Amount in {props.currency} : </b>
            </span>
            <span>
              <b>
                {currencySign}
                {props.totalAmount}
              </b>
            </span>
          </div>
          {props.currency !== currencyConfig.baseCurrencyLabel && (
            <>
              <div className="d-flex justify-content-between my-2">
                <span className="text-muted">
                  ROE ({props.currency?.toString()} to{" "}
                  {currencyConfig.baseCurrencyLabel}) :{" "}
                </span>
                <span>$1 = ₹83.34</span>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="text-muted">Payable Amount in INR : </span>
                <span>
                  <b>
                    {currencyConfig.baseCurrencySymbol}{" "}
                    {props.totalAmount &&
                      (parseFloat(props.totalAmount?.toString()) * ROE).toFixed(
                        2
                      )}
                  </b>
                </span>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-secondary" onClick={props.onHide}>
          Close
        </button>
        <button className="btn btn-success" onClick={props.onProceedPayment}>
          Proceed To Payment
        </button>
      </Modal.Footer>
    </Modal>
  );
};
