import React, { useEffect, useState } from "react";

import { getAllLiveTboWhitelabels } from "apiCalls/whitelabelDb";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  main: {
    maxWidth: "700px",
    margin: "0 auto",
  },
});

export default function LiveSitesList() {
  const classes = useStyles();
  const [liveSites, setLiveSites] = useState([]);

  useEffect(() => {
    getLiveSites();
  }, []);

  const getLiveSites = async () => {
    setLiveSites(await getAllLiveTboWhitelabels());
  };
  const printCell = (val) => {
    return <TableCell>{val}</TableCell>;
  };
  return (
    <div>
      <TableContainer component={Paper} className={classes.main}>
        <Table>
          <TableHead>
            <TableRow>
              {printCell("Domain Name")}
              {printCell("Company Name")}
            </TableRow>
          </TableHead>
          <TableBody>
            {liveSites?.length &&
              liveSites.map((site, i) => (
                <TableRow key={i}>
                  {printCell(site.siteId?.domain)}
                  {printCell(site.siteId?.companyName)}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
