import { useEffect, useState } from "react";
import { IOption, SelectWrapper } from "../APIs/SelectWrapper";
import { postRequest } from "tools/ajax";
import _ from "lodash";
import { toast } from "react-toastify";
import { AddAirportModal } from "./AddAirportModal";

export interface IAirport {
  id?: number;
  airportName: string;
  airportCode: string;
  countryName: string;
  countryCode: string;
  cityName: string;
  cityCode: string;
  timezone: string;
  latitude: string;
  longitude: string;
}

export const AirportsSettings: React.FC<{}> = () => {
  const [airportDetails, setAirportDetails] = useState<IAirport>({
    airportName: "",
    airportCode: "",
    countryName: "",
    countryCode: "",
    cityName: "",
    cityCode: "",
    timezone: "",
    latitude: "",
    longitude: "",
  });
  const [airportResponses, setAirportResponses] = useState<
    IAirport[] | undefined
  >(undefined);
  const [selectWrapperValue, setSelectWrapperValue] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [showAirportmodal, setShowAirportModal] = useState(false);
  const [airportFilter, setAirportFilter] = useState<keyof IAirport | null>(
    null
  );
  const [airports, setAirports] = useState<IOption[]>([]);

  useEffect(() => {
    if (airportFilter) getAirports();
  }, [airportFilter]);

  useEffect(() => {
    if (airportResponses) {
      let airportDetail: any = _.find(airportResponses, {
        id: selectWrapperValue,
      });
      if (airportDetail) {
        setAirportDetails(airportDetail);
        setShowAirportModal(true);
      }
    }
  }, [selectWrapperValue]);

  const getAirports = async (keyWord: null | string = null) => {
    if (!keyWord && !airportFilter) return;
    setLoading(true);
    const response = await postRequest("admin/nfs/getAirportBySearch", {
      searchQuery: keyWord,
      filterBy: airportFilter,
    });
    if (response?.success && response?.result?.length > 0) {
      const resultArr = response?.result?.map(
        (airport: IAirport): IOption => ({
          label: `${airport.cityName} (${airport.cityCode})`,
          value: airport.airportCode,
        })
      );
      setAirportResponses(response.result);
      setAirports(resultArr);
    }
    setLoading(false);
  };
  console.log(airportResponses);

  const upsertAirport = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await postRequest("admin/nfs/upsertAirport", airportDetails);
      if (res?.success) {
        setAirportDetails({
          airportName: "",
          airportCode: "",
          countryName: "",
          countryCode: "",
          cityName: "",
          cityCode: "",
          timezone: "",
          latitude: "",
          longitude: "",
        });
        setShowAirportModal(false);
        toast.success("airport updated");
      } else {
        throw new Error("something went wrong");
      }

      // console.log(res);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleAirportDetails = (e) => {
    let { name, value } = e.target;
    if (value === null) value = undefined;
    setAirportDetails((old) => {
      return { ...old, [name]: value };
    });
    console.log(airportDetails);
  };

  return (
    <div className="container">
      <AddAirportModal
        loading={loading}
        upsertAirport={upsertAirport}
        handleAirportDetails={handleAirportDetails}
        airportDetails={airportDetails}
        show={showAirportmodal}
        onHide={() => {
          setShowAirportModal(false);
        }}
      />
      <h3 className="text-center py-4" id="airport-settings">
        Airports Settings
      </h3>
      <div className="row align-items-end">
        <div className="offset-2 col-4 pe-2">
          <SelectWrapper
            onChange={(option) => {
              // console.log(option);
              setSelectWrapperValue(option?.value);
            }}
            placeholder={selectWrapperValue || "Search Airport"}
            onInputChange={(val) => {
              getAirports(val);
            }}
            isLoading={loading}
            options={airports}
          />
        </div>
        <div className="col-2">
          <label htmlFor="'">Filter By</label>
          <select
            name=""
            id=""
            className="form-select"
            onChange={(e) => {
              let { value }: any = e.target;
              setAirportFilter(value);
            }}
          >
            <option value="">Select</option>
            <option value="airportName">Airport Name</option>
            <option value="airportCode">Airport Code</option>
            <option value="countryName">Country Name</option>
            <option value="countryCode">Country Code</option>
            <option value="cityName">City Name</option>
            <option value="cityCode">City Code</option>
            <option value="timezone">Timezone</option>
            <option value="latitude">Latitude</option>
            <option value="longitude">Longitude</option>
          </select>
        </div>
        <div className="col-2">
          <button
            className="btn btn-success"
            onClick={() => {
              setAirportDetails({
                airportName: "",
                airportCode: "",
                countryName: "",
                countryCode: "",
                cityName: "",
                cityCode: "",
                timezone: "",
                latitude: "",
                longitude: "",
              });
              setShowAirportModal(true);
            }}
          >
            +Add
          </button>
        </div>
      </div>

      {airports.length !== 0 && !loading && (
        <>
          <div>
            <h3 className="py-3 text-center">
              Airports Without {airportFilter?.toLocaleUpperCase()}
            </h3>
          </div>
          <div className="row py-4 bg-light border mt-3">
            <div className="col-1">
              <b>index</b>
            </div>
            <div className="col-1">
              <b>Airport Code/Name</b>
            </div>
            <div className="col-1">
              <b>Country Code/Name </b>
            </div>
            <div className="col-2">
              <b>City Code/Name</b>
            </div>
            <div className="col-2">
              <b>Time Zone</b>
            </div>
            <div className="col-2">
              <b>Latitude</b>
            </div>
            <div className="col-2">
              <b>Longitude</b>
            </div>
            <div className="col-1 text-center">
              <b>Update</b>
            </div>
          </div>
          {airportResponses &&
            airportResponses.map((airport, index) => {
              return (
                <div className="row py-4 bg-light border mt-3" key={index}>
                  <div className="col-1">{index + 1}</div>
                  <div className="col-1">
                    {airport.airportCode}/ <br />
                    <span className="small">
                      {airport.airportName || (
                        <span className="text-danger">not found</span>
                      )}
                    </span>
                  </div>
                  <div className="col-1">
                    {airport.countryCode}/ <br />
                    <span className="small">
                      {airport.countryName || (
                        <span className="text-danger">not found</span>
                      )}
                    </span>{" "}
                  </div>
                  <div className="col-2">
                    {airport.cityCode} /
                    <br />
                    <span className="small">
                      {airport.cityName || (
                        <span className="text-danger">not found</span>
                      )}
                    </span>
                  </div>
                  <div className="col-2">
                    {airport.timezone || (
                      <span className="text-danger">not found</span>
                    )}{" "}
                  </div>
                  <div className="col-2">
                    {airport.latitude || (
                      <span className="text-danger">not found</span>
                    )}{" "}
                  </div>
                  <div className="col-2">
                    {airport.longitude || (
                      <span className="text-danger">not found</span>
                    )}{" "}
                  </div>

                  <div className="col-1 text-center">
                    <a href="#airport-details">
                      {" "}
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          setAirportDetails(airport);
                          setShowAirportModal(true);
                        }}
                      >
                        Update
                      </button>
                    </a>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};
