import React from 'react'
import moment from 'moment'
import { TableBody, TableCell, TableContainer, TableRow, TableHead } from '@material-ui/core'
import {printAmount} from 'tools/commonFuncions'

export default function TransactionHistory(props) {
    const { transactionArray } = props
    
    return (
        <>
        <TableContainer>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Credit</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Remark</TableCell>
                </TableRow> 
            </TableHead>
            <TableBody>
            {props && props.transactionArray.length && transactionArray.reverse().map((data,i)=>{
                return(
                <TableRow key={i}>
                    <TableCell component='th'>
                         {moment(data.date).format("Do MMMM YYYY, h:mm a")}
                    </TableCell>
                    <TableCell> 
                        {
                            data.debit_credit === 'credit' &&
                            printAmount(data.amount)
                        }
                    </TableCell>
                    <TableCell>
                        {
                            data.debit_credit === 'debit' &&
                            printAmount(data.amount)
                        }
                    </TableCell>
                    <TableCell>
                        {printAmount(data.balance)}
                    </TableCell>
                    <TableCell>
                        {data.remark}
                    </TableCell>    
                </TableRow>
                )
            })}
            </TableBody>
        </TableContainer>
        </>
    )
}