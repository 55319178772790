import React, { Component } from 'react'
import {List} from 'semantic-ui-react'
import axios from 'axios'
import ListCity from './ListCity'
import NewCityForm from "./NewCityForm"
export default class CitiesCard extends Component {
    constructor(){
        super()
        this.state = {
            cities: []
        }
    }

    componentDidMount =() =>{
        this.getCities()
    }

    getCities = () => {
        axios({
            url: "/api/admin/master/getCities",
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('auth-token')
            }
        }).then(r=>{
            this.setState({cities: r.data})
        })
    }

    deleteCity = (_id) => {
        axios({
            url: '/api/admin/master/deleteCity',
            method: 'DELETE',
            headers: {
                'auth-token': localStorage.getItem('auth-token')
            },
            params:{
                cityId: _id
            }
        }).then(r=> {
            console.log(r.data,'deleted')
            this.getCities()
        })
    }

    editCityAndCountry = ({_id,newCityName,newCountry}) => {
        console.log("DATA FOR POST",_id,newCityName,newCountry)
        axios({
            url: '/api/admin/master/editCityAndCountry',
            method: 'POST',
            headers: {
                'auth-token': localStorage.getItem('auth-token')
            },
            data:{
                _id,
                newCountry,
                newCityName    
            }
        }).then(r=> {
            console.log(r.data,'Edited')
            this.getCities()
        })
    }

    render() {
        return (
            <React.Fragment>
            <div className="card">
                <h1>Cities</h1>
                <NewCityForm getCities={this.getCities} />   
                <List divided verticalAlign='middle'>
                    {this.state.cities.map((v,i)=>(
                        <ListCity city={v} key={i} 
                        getCities={this.getCities}
                        deleteCity={this.deleteCity}
                        editCityAndCountry={this.editCityAndCountry}/>
                    ))}
                </List>
            </div>
            </React.Fragment>
        
        )
    }
}
