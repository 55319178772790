import React from "react";
import { Route, Redirect } from "react-router-dom";
import TopMenu from "components/admin/common/header/topMenu";
import "semantic-ui-css/semantic.min.css";
import "assets/css/common/common.css";
import "assets/css/form.css";
function AdminRoute({ component: Component, noMenu = false, ...rest }) {
  const isLoggedIn = () => {
    if (localStorage.getItem("auth-token")) return true;
    else return false;
  };
  return (
    <React.Fragment>
      {!noMenu && <TopMenu {...rest} />}
      <Route
        {...rest}
        render={(props) => {
          if (isLoggedIn()) {
            return <Component {...props} />;
          } else {
            return <Redirect to="/admin" />;
          }
        }}
      />
      <button
        className="btn btn-sm btn-success"
        style={{ position: "fixed", bottom: 40, left: 40 }}
        onClick={() => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }}
      >
        <i className="fa fa-arrow-up" />
      </button>
    </React.Fragment>
  );
}

export default AdminRoute;
