import React from "react";
// import Button from "@material-ui/core/Button";

const Index: React.FC<{
  label: string;
  onClick?: (props: any) => void;
  type?: "submit" | "reset";
}> = ({ label, onClick, ...props }) => {
  return (
    <div>
      <button className="btn btn-primary" onClick={onClick} {...props}>
        {label}
      </button>
    </div>
  );
};

export default Index;
