import { getRequest, postRequest } from "tools/ajax";
import { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import queryString from "query-string";
import { verifyBillingPaymentApiCall } from "apiCalls/siteSettingsDb";
import { useHistory } from "react-router-dom";
import paymentImage from "../../../assets/images/paymentScreenImages/payment-page.png";
import { currencySigns, currencyType } from "types/wallet.types";
import { useDispatch } from "react-redux";
import { sippiCustomerSlice } from "redux/slices/sippiCustomer.slice";
import { PaymentScreenSkelton } from "./PaymentScreenSkelton";
import { PayOnlineModal } from "./PayOnlineModal";
import { useAppSelector } from "hooks/reduxHooks";
import { currencyConfig } from "types/sippiCurrencyTypes";
import { FullScreenLoader } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import { Accordion } from "react-bootstrap";
import { productTypeEnum } from "commonFnBcode/types/whitelabel.types";
import { EaseBuzzPaymentGateway } from "../plans/paymentGateways/EaseBuzz/EaseBuzzPaymentGateway";
import {
  IsippiCustomer,
  ISippiCustomerWithPurchase,
} from "types/sippiCustomer.types";
import { phoneNumberStringSchema } from "commonFnBcode/zodSchemas/sippiCustomerSchema";
import { SippiCustomerContactDetailsModal } from "components/admin/sippiCustomers/SippiCustomerContactDetailsModal";

export interface IUserData {
  id: number;
  sippiCustomerId: string;
  amount: number;
  tax: number;
  totalAmount: number;
  remark: string;
  createdAt: string;
  updatedAt: string;
  productType: productTypeEnum;
  currency: currencyType;
}

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const PaymentScreen: React.FC<{}> = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get("orderId");

  const [userData, setUserData] = useState<IUserData | undefined>(undefined);
  const [sippiUser, setSippiUser] = useState<
    IsippiCustomer | ISippiCustomerWithPurchase
  >();
  const [loading, setLoading] = useState(false);
  const [openEaseBuzz, setOpenEaseBuzz] = useState(false);
  const [proceedPaymentClicked, setProceedPaymentClicked] = useState(false);
  const [onlinePaymentModalShow, setOnlinePaymentModalShow] = useState(false);
  const [showEditDetailsModal, setShowEditDetailsModal] = useState(false);
  const parsedParams = queryString.parse(window.location.search);
  const params = new URLSearchParams(window.location.search);
  const order_id = params.get("?order_id");
  const redirectRoute = params.get("redirectRoute");
  const history = useHistory();
  const currencySign = userData ? currencySigns[userData?.currency] : "";

  const [showPayfromWalletButton, setShowPayfromWalletButton] = useState<
    boolean | undefined
  >(undefined);

  const dispatch = useDispatch();
  let ROE = currencyConfig.ROE;
  useEffect(() => {
    getUserDataByOrderId();
  }, []);

  useEffect(() => {
    if (userData) getSippiCustomerById();
    if (
      userData?.productType === productTypeEnum.COMBO_PLAN_1 ||
      userData?.productType === productTypeEnum.COMBO_PLAN_2 ||
      userData?.productType === productTypeEnum.COMBO_PLAN_3 ||
      userData?.productType === productTypeEnum.COMBO_PLAN_4 ||
      userData?.productType === productTypeEnum.TRAVEL_WEBSITE_PURCHASE ||
      userData?.productType === productTypeEnum.WALLET_RECHARGE
    ) {
      setShowPayfromWalletButton(false);
    } else {
      setShowPayfromWalletButton(true);
    }
  }, [userData]);

  // useEffect(() => {
  //   if (order_id) {
  // console.log(order_id);

  //   checkForCashfreePayment(order_id);
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [parsedParams.order_id]);

  // useEffect(() => {
  //   setLoading(true);
  //   if (order_id) {
  //     const paymentObj = {
  //       order_id,
  //     };

  //     axios({
  //       url: "/api/payment/verifyPGTransaction",
  //       method: "POST",
  //       data: paymentObj,
  //       headers: {
  //         "customer-token": localStorage.getItem("customer-token"),
  //       },
  //     })
  //       .then((r) => {
  //         // console.log(r);
  //         if (!r?.data?.success) {
  //           throw new Error("Something wemt wrong please contact support");
  //         }
  //         toast.success("Payment Successful");
  //         if (r?.data?.result?.updateCustomer)
  //           dispatch(
  //             sippiCustomerSlice.actions.setSippiCustomer(
  //               r?.data?.result?.updateCustomer
  //             )
  //           );

  //         if (redirectRoute) history.push(redirectRoute?.toString());
  //       })
  //       .catch((err) => {
  //         toast.error(
  //           err?.response?.data?.message || err || "something went wrong"
  //         );
  //         history.push("/");
  //       });
  //     // .finally(() => {
  //     //   setLoading(false);
  //     // });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [order_id, parsedParams.order_token]);

  const checkPhoneValidity = (next: () => void) => {
    const phoneNumber = sippiUser?.purchases?.form.phoneNumber;
    const countryCode = sippiUser?.purchases?.form.phoneCountryCode;
    let isPhoneValid = true;

    if (!countryCode || !phoneNumber) {
      isPhoneValid = false;
    }

    if (phoneNumber && countryCode === "91" && phoneNumber.length !== 10) {
      isPhoneValid = false;
    }
    const validPhoneNumber = phoneNumberStringSchema.safeParse(phoneNumber);
    if (!validPhoneNumber.success) {
      isPhoneValid = false;
    }
    if (!isPhoneValid) {
      toast.warning("Please provide a valid phone number.");
      setShowEditDetailsModal(true);
      setOnlinePaymentModalShow(false);
      return;
    }
    // if phone number is valid then proceed to payment gateway
    next();
  };

  //pay online
  const createPGOrder = async () => {
    if (loading) return;
    // if (proceedPaymentClicked) return;
    // setProceedPaymentClicked(true);
    if (!userData || !sippiUser) {
      return;
    }
    if (
      userData.productType === productTypeEnum.WALLET_RECHARGE ||
      userData.productType === productTypeEnum.COMBO_PLAN_1
    ) {
      setOnlinePaymentModalShow(false);
      setOpenEaseBuzz(true);
    } else {
      toast.error("Online payment not eligible");
    }

    // if (
    //   userData.productType === productTypeEnum.ADDON ||
    //   userData.productType === productTypeEnum.AIR_IQ ||
    //   userData.productType === productTypeEnum.CALLER_DESK ||
    //   userData.productType === productTypeEnum.CHEAP_FIX_DEPARTURE ||
    //   userData.productType === productTypeEnum.CLEAR_TRIP ||
    //   userData.productType === productTypeEnum.TFNEXUS ||
    //   userData.productType === productTypeEnum.GALILEO ||
    //   userData.productType === productTypeEnum.GO_FLY_SMART ||
    //   userData.productType === productTypeEnum.PAN_CARD_VERIFICATION ||
    //   userData.productType === productTypeEnum.TBO ||
    //   userData.productType === productTypeEnum.TRIP_JACK ||
    //   userData.productType === productTypeEnum.WHATSAPP
    // ) {
    //   toast.error("Addons can only be purchased from wallet");
    //   return;
    // }
    // const totalPayableAmount =
    //   userData.currency !== currencyConfig.baseCurrencyLabel
    //     ? userData.totalAmount * ROE
    //     : userData.totalAmount;
    // const pGOrderResponse = await postRequest("wallet/createPGOrder", {
    //   ...userData,
    //   totalAmount: totalPayableAmount,
    //   ...sippiUser,
    //   paymentType: userData?.productType,
    // });
    // if (pGOrderResponse?.success && pGOrderResponse?.result) {
    //   if (!pGOrderResponse.result.pgRedirectUrl) {
    //     return toast.error(
    //       "Error in creating payment gateway order... Failed to create payment session id!"
    //     );
    //   }

    //   await loadScript(pGOrderResponse.result.pgRedirectUrl);
    //   const windowRef: any = window;
    //   const cashfree = new windowRef.Cashfree(
    //     pGOrderResponse.result.payment_session_id
    //   );
    //   console.log(cashfree.redirect());
    // } else {
    //   toast.error("Error in creating payment gateway order...");
    // }
    // setProceedPaymentClicked(false);
  };

  const getUserDataByOrderId = async () => {
    setLoading(true);
    if (!orderId) {
      return;
    }
    const userDataRes = await getRequest("wallet/getUserDataByOrderId", {
      orderId: orderId,
    });
    if (userDataRes?.success) {
      setUserData(userDataRes.result);
    }
    setLoading(false);
  };
  const getSippiCustomerById = async () => {
    setLoading(true);
    const res = await getRequest("user/getSippiCustomerById", {
      sippiCustomerId: userData?.sippiCustomerId,
    });
    if (res.success) {
      setSippiUser(res.result);
    }
    setLoading(false);
  };

  //verify from invioce db
  // const checkForCashfreePayment = async (order_id) => {
  //   setLoading(true);
  //   // console.log(order_id);

  //   const paymentRes = await verifyBillingPaymentApiCall({
  //     orderId: order_id,
  //   });

  //   if (paymentRes.success) {
  //     // toast.success("Payment Verified Successfully");
  //   } else {
  //     toast.error("Could not verify payment... Please contact support!");
  //     history.push("/buyTravelEsLite");
  //   }
  //   setLoading(false);
  // };
  //pay from wallet
  const payFromWallet = async () => {
    // if (proceedPaymentClicked) return;
    // setProceedPaymentClicked(true);
    try {
      if (userData?.productType === productTypeEnum.WALLET_RECHARGE) {
        toast.error("Not Applicable For Wallet Recharge");
        return;
      }
      const order_id = params.get("orderId");
      const walletPaymentResponse = await postRequest("wallet/payFromWallet", {
        ...userData,
        order_id,
      });

      if (walletPaymentResponse?.success) {
        if (walletPaymentResponse.result?.updateCustomer)
          dispatch(
            sippiCustomerSlice.actions.setSippiCustomer(
              walletPaymentResponse.result.updateCustomer
            )
          );
        toast.success("Purchase successfull");
        history.push(walletPaymentResponse.result?.redirectURL);
      } else {
        history.push("/user/wallet_recharge");
      }
    } catch (error) {
      toast.error("something went wrong");
    }
    // setProceedPaymentClicked(false);
  };

  const handleSuccessPayment = (paymentResponse) => {
    if (paymentResponse?.result?.updateCustomer) {
      dispatch(
        sippiCustomerSlice.actions.setSippiCustomer(
          paymentResponse?.result?.updateCustomer
        )
      );
      history.push("/user/dashboard");
      return;
    }
    if (redirectRoute) history.push(redirectRoute?.toString());
  };

  return (
    <div id="sippi-payment-page">
      <FullScreenLoader show={loading} />

      <div className="container">
        <div className="d-flex justify-content-center">
          {loading ? (
            <PaymentScreenSkelton />
          ) : (
            <div className="pay-payment-details-container border shadow">
              <div className="image">
                <img className="img-fluid " src={paymentImage} alt="img" />
              </div>
              <div className="payment-details mt-2">
                <h5 className="ms-2">Proceed To Pay</h5>
                <div className="details container-fluid">
                  <div className="paying-for d-flex justify-content-between align-items-top my-2 border-bottom pb-2">
                    <div className="text-muted">Paying For : </div>
                    <div className="paying-for-value text-start  text-capitalize ">
                      {" "}
                      {userData?.remark.split(" + ").map((item, key) => {
                        return (
                          <div className="mb-2" key={key}>
                            <i className="fas fa-check-circle pe-2 text-success"></i>
                            <span className="text-end w-100 ">{item}</span>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-2 ">
                    <span className="text-muted">Name : </span>
                    <span>{sippiUser?.googlePersonObj?.name} </span>
                  </div>
                  <div className="d-flex justify-content-between my-2">
                    <span className="text-muted">Email : </span>
                    <span> {sippiUser?.purchases?.form?.email}</span>
                  </div>
                  <div className="d-flex justify-content-between my-2">
                    <span className="text-muted">order Id : </span>
                    <span> {orderId}</span>
                  </div>

                  <div className="d-flex justify-content-between my-2 ">
                    <span className="text-muted fs-6">Total Amount : </span>
                    <span className="fs-6 fw-bold">{`${currencySign}${userData?.amount}`}</span>
                  </div>
                  <div className="d-flex justify-content-between my-2">
                    {!showPayfromWalletButton && (
                      <button
                        className={`btn btn-success border btn-sm w-100 py-2 me-1 ${
                          proceedPaymentClicked && "disabled"
                        }`}
                        onClick={() => setOnlinePaymentModalShow(true)}
                      >
                        {proceedPaymentClicked ? "Loading..." : "Pay Online"}
                      </button>
                    )}

                    {showPayfromWalletButton && (
                      <button
                        className={`btn btn-success border btn-sm w-100 py-2 ms-1 ${
                          proceedPaymentClicked && "disabled"
                        }`}
                        onClick={payFromWallet}
                      >
                        <i className="fas fa-wallet"></i>{" "}
                        {proceedPaymentClicked
                          ? "Loading..."
                          : "Pay from Wallet"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <PayOnlineModal
        amount={userData?.amount}
        totalAmount={userData?.totalAmount}
        tax={userData?.tax}
        onProceedPayment={() => checkPhoneValidity(createPGOrder)}
        remark={userData?.remark}
        show={onlinePaymentModalShow}
        onHide={() => setOnlinePaymentModalShow(false)}
        orderId={orderId}
        currency={userData?.currency}
      />
      {openEaseBuzz && orderId ? (
        <EaseBuzzPaymentGateway
          handleFailedPayment={() => {
            if (redirectRoute) history.push(redirectRoute?.toString());
          }}
          orderId={orderId}
          setLoading={(val) => setLoading(val)}
          handleSuccessPayment={handleSuccessPayment}
          closeGateway={() => setOpenEaseBuzz(false)}
        />
      ) : null}
      {/* <EaseBuzzPaymentGateway
        show={openEaseBuzz}
        onHide={() => setOpenEaseBuzz(false)}
      /> */}
      {showEditDetailsModal && sippiUser && (
        <SippiCustomerContactDetailsModal
          userType="user"
          show={showEditDetailsModal}
          handleClose={() => {
            setShowEditDetailsModal(false);
            getUserDataByOrderId();
          }}
          customerDetails={sippiUser}
        />
      )}
    </div>
  );
};
