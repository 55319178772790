import FlightApi from "apiCalls/FlightApi"
import { call, put, takeLatest } from "redux-saga/effects"
import { SET_LOADER } from "redux/actions/common.actions"
import { setAllAirlines } from "redux/actions/flightApi.actions"
import { flightApiTypes } from "redux/actionTypes/flightApi.types"

const FL = new FlightApi()

function* getAllAirlines() {
    yield put(SET_LOADER(true))

    const res = yield call(FL.getAllAirlines)
    
    yield put(setAllAirlines(res.airlines))

    yield put(SET_LOADER(false))
}


export function* watchFlightActions() {
    yield takeLatest(flightApiTypes.GET_ALL_AIRLINES, getAllAirlines)
}