import {commonTypes} from 'redux/actionTypes/common.types'

export const SET_ALERT_MESSAGE = (message) => {
    return {
        type: commonTypes.SET_ALERT_MESSAGE,
        payload: message
    }
} 

export const HIDE_ALERT_MESSAGE = () => {
    return {
        type: commonTypes.HIDE_ALERT_MESSAGE
    }
}

export const SET_LOADER = (data) => {
    return {
        type: commonTypes.SET_LOADER,
        payload: data 
    }
}

