import React, { useEffect, useState } from 'react'
import { getCountries } from 'apiCalls/masterDb'
import { Autocomplete } from '@material-ui/lab'
import { TextField, CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { GET_ALL_COUNTRIES, SET_COUNTRY, SET_SELECTED_VISACATEGORY, SET_NATIONALITY, SET_VISACHECKLISTS, SET_SELECTED_VISA_CHECKLIST } from 'redux/actions/visaActions'

export default function CountriesList() {
    const [countries, setCountries] = useState([])
    const [dropdownSearchValue, setDropdownSearchValue] = useState('')
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        getCountry()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const dropdownSearch = ({target}) => {
        setDropdownSearchValue(target.value)
    }

    const getCountry = async () => {
        setLoading(true)
        const countriesArr = await getCountries()
        setCountries(countriesArr)
        dispatch(GET_ALL_COUNTRIES(countriesArr))
        setLoading(false)
     }

     const setCountry = (e,v) => {  
        if (v === null ) (
            dispatch(SET_COUNTRY([]))
        )
        else  (
        dispatch(SET_COUNTRY(v))
        )
        
        dispatch(SET_NATIONALITY([]))
        dispatch(SET_SELECTED_VISACATEGORY([]))
        dispatch(SET_SELECTED_VISA_CHECKLIST([]))
        dispatch(SET_VISACHECKLISTS([]))
       
     }

    return (
        <>
        {loading === true ? <CircularProgress/> : 
         <Autocomplete
            noOptionsText="No Country Found"
            options={countries}
            onChange={setCountry}
            getOptionLabel={option => option.countryName}
            style={{ width: 300, margin: '5px', alignItems: 'left', paddingLeft: '10px'}}
            renderInput={(params) => <TextField {...params} value={dropdownSearchValue} 
            onChange={dropdownSearch} label="Select Country" variant="outlined" />}
        />
        }
       
        </>
    )
}