// import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer } from "redux/reducers/index";
import createSagaMiddleware from "redux-saga";
import rootSaga from "redux/sagas/root.saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["siteSettings", "sippiCustomer", "currencyWisePrices"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = createStore(
//     persistedReducer,
//     compose(applyMiddleware(sagaMiddleware),
//     (window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development')
//     ? window.__REDUX_DEVTOOLS_EXTENSION__()
//     : f => f
//     )
// )

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, sagaMiddleware],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

//export store
