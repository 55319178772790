import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
  Paper,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import { date } from "tools/constants";
import { getAllPaymentEntries } from "apiCalls/whitelabelDb";
import Pagination from "@material-ui/lab/Pagination";
import { FullScreenLoader } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import { useHistory, useParams } from "react-router-dom";
// import { SearchCustomer } from '../SearchCustomer'
import { AdminRouteConstants } from "constants/routes";
import GridItem from "commonUi/grid/item";
import { IPaymentGatewayTransactionPagination } from "./types/PaymentGatewayTransaction.type";
import { SearchCustomer } from "../wallet/SearchCustomer";

const useStyles = makeStyles({
  gridContainer: {
    marginTop: "1rem",
  },
  searchInp: {
    margin: "10px auto",
    width: "100%",
  },
  imgHeight: {
    maxHeight: "100px",
    maxWidth: "100%",
    width: "auto",
    height: "auto",
    margin: "1px auto",
    objectFit: "cover",
  },
});

export const PaymentGatewayTransactions = (props) => {
  const classes = useStyles();

  const [paymentGatewayPaginationState, setPaymentGatewayPaginationState] =
    useState<IPaymentGatewayTransactionPagination>();
  // const [allEntries, setAllEntries] = useState([])
  const [pageState, setPageState] = React.useState(1);
  const [loadingState, setLoadingState] = React.useState(false);
  const [whitelabelState, setWhitelabelState] = React.useState<any>();

  let routeParams: any = useParams();
  let history: any = useHistory();

  useEffect(() => {
    if (paymentGatewayPaginationState) {
      setPageState(paymentGatewayPaginationState?.currentPage);
    }
  }, [paymentGatewayPaginationState]);

  const callGetAllPaymentEntries = useCallback(async () => {
    setLoadingState(true);

    const res = await getAllPaymentEntries({
      page: pageState,
      siteId: routeParams.siteId,
    });
    setLoadingState(false);

    // const allEntriess = res.reverse()
    // console.log(allEntriess)
    if (res.success) {
      // console.log(res.result);
      // toast.error(JSON.stringify(res.result))
      // setPaymentGatewayPaginationState({...res.result});
      setPaymentGatewayPaginationState(res.result);
    }
  }, [pageState, routeParams.siteId]);

  useEffect(() => {
    callGetAllPaymentEntries();
  }, [pageState, routeParams.siteId, callGetAllPaymentEntries]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (value !== pageState) {
      setPageState(value);
    }
  };

  const TablePagination = () => (
    <Pagination
      style={{
        float: "right",
        marginTop: 15,
      }}
      color="primary"
      count={paymentGatewayPaginationState?.totalPages || 0}
      page={pageState}
      onChange={handlePageChange}
    />
  );

  const setWl = (whitelabel) => {
    if (whitelabel) {
      setWhitelabelState(whitelabel);
      history.push(`${AdminRouteConstants.ADMIN_PG_TXN}/${whitelabel._id}`);
    } else {
      setWhitelabelState(undefined);
      history.push(`${AdminRouteConstants.ADMIN_PG_TXN}/`);
    }
  };

  return (
    <>
      <FullScreenLoader show={loadingState} />

      <Container>
        <SearchCustomer setWhitelabel={setWl} />

        <Typography variant="h5">
          All Whitelabel Payment Gateway Entries
        </Typography>

        {paymentGatewayPaginationState?.data.records.length && (
          <>{paymentGatewayPaginationState?.totalRecords} Records Found</>
        )}

        {whitelabelState &&
          Object.keys(whitelabelState).length !== 0 &&
          whitelabelState.constructor === Object && (
            <GridItem xs={12} sm={6}>
              <Grid container justify="center">
                <Grid item xs={12} md={6} className="text-center">
                  <img
                    src={whitelabelState.logoUrl}
                    alt={whitelabelState.companyName}
                    className={classes.imgHeight}
                  />
                </Grid>
                <Grid item xs={12} md={6} className="text-center">
                  <Typography variant="h6" color="initial">
                    {whitelabelState.companyName}
                  </Typography>
                  <Typography color="initial">
                    Domain: {whitelabelState.domain}
                  </Typography>
                  <Typography color="initial">
                    Site: {whitelabelState.siteName}
                  </Typography>
                </Grid>
              </Grid>
            </GridItem>
          )}

        <TablePagination />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Txn Id</TableCell>
                <TableCell>PG Name</TableCell>
                <TableCell>OrderId</TableCell>
                <TableCell>PaymentId</TableCell>
                <TableCell>PaymentDetail</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Site Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {JSON.stringify(paymentGatewayPaginationState)} */}
              {paymentGatewayPaginationState?.data.records.map(
                (entry, index) => (
                  <TableRow key={entry.id}>
                    <TableCell>{entry.id}</TableCell>
                    <TableCell>{entry.pgName}</TableCell>
                    <TableCell>{entry.orderId}</TableCell>
                    <TableCell>{entry.paymentId}</TableCell>
                    <TableCell>
                      <pre>
                        {entry.paymentObject &&
                          JSON.stringify(entry.paymentObject, null, 2)}
                      </pre>
                    </TableCell>
                    <TableCell>
                      {entry.currency} {entry.amount}
                    </TableCell>
                    <TableCell>{entry.siteName}</TableCell>
                    <TableCell>{entry.status}</TableCell>

                    <TableCell>{entry.pgName}</TableCell>
                    <TableCell>{entry.customerId}</TableCell>
                    <TableCell>
                      {moment(entry.createdAt).format(date.dateTimeFormat)}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination />
      </Container>
    </>
  );
};
