import React from "react";
import { AdminMasterMenu } from "./AdminMasterMenu";

export default class AdminMaster extends React.Component {
  render = () => {
    return (
      <React.Fragment>
        <AdminMasterMenu />
        {/* <CountryCityMaster /> */}
      </React.Fragment>
    );
  };
}
