import React from 'react'
import {Container,Grid,Typography
,Accordion,AccordionSummary,AccordionDetails} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import DbSync from './DbSync'

export default function Settings(){
    const accordion = (summary,component,expanded = false) => (
        <Accordion expanded={expanded}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            >
            <Typography>{summary}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{width: '100%'}}>
                {component}
                </div>
            </AccordionDetails>
        </Accordion>
    )
    return(
        <Container>
            <Grid container justify="center">
                <Grid item lg={12}>
                    <Typography variant="h5">
                        Settings
                    </Typography>
                </Grid>
                <Grid item lg={12}>
                    {accordion('Database Sync',<DbSync />,true)}
                </Grid>
            </Grid>
        </Container>
    )
}