export const ERROR_MESSAGES = {
    SERVER_TRY_LATER: "There is some issue with the server. Please contact support!"
}

export const isInvalidField = (fieldKey: any) => {

    if (fieldKey['validation'].includes("required") && (fieldKey.value === "" || fieldKey.value === null || fieldKey.value === undefined || typeof fieldKey.value === 'undefined')) {
        return true;
    }

    if (fieldKey['validation'].includes("percentage") && ((fieldKey.value < 0) || (fieldKey.value > 100))) {
        return true;
    }

    return false;

}


export const toggleValidation = (array: Array<string>, value: string) => {
    var index = array.indexOf(value);

    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }

    return array;
}