import React from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'


function index(props) {
    return (
        <>
            <ListItemText {...props}>
                {props.children}
            </ListItemText>
        </>
    )
}

index.propTypes = {
    children: PropTypes.node.isRequired
}

export default index
