import React from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types'

function AutoComplete(props) {
    const {options,getOptionLabel,label,onChange} = props
    return (
        <Autocomplete
            {...props}
            id="combo-box-demo"
            options={options}
            getOptionLabel={getOptionLabel}
            style={{ width: 300 }}
            onChange={onChange}
            onInputChange={props.onInputChange || null}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        />
    )
}

AutoComplete.propTypes = {
    options: PropTypes.array.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    onInputChange: PropTypes.func
}


export default AutoComplete
