import { useEffect, useState } from "react";
import { postRequest } from "tools/ajax";
import { currencySigns } from "types/wallet.types";
import { checkPurchasedAddon } from "../AddonHelper";
import { AddonsData } from "../AddonData";
import _ from "lodash";

import { useAppSelector } from "hooks/reduxHooks";
import { useHistory, useParams } from "react-router-dom";
export const AddonItem: React.FC<{}> = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params: any = useParams();
  console.log(params.addonEnum);

  const addonData = _.find(AddonsData, {
    addonEnum: params.addonEnum,
  });
  const history = useHistory();
  useEffect(() => {
    checkIfPurchased();
  }, []);

  const [isPurchased, setIsPurchased] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const addonPrice = useAppSelector(
    (state) => state.currencyWisePrices.currencyWisePrices?.[params.addonEnum]
  );
  const currency = useAppSelector(
    (state) => state.currencyWisePrices.currencyWisePrices?.currency
  );
  const checkIfPurchased = async () => {
    if (addonData?.addonEnum) {
      const res = await checkPurchasedAddon(addonData?.addonEnum);
      setIsPurchased(res);
    }
  };

  const createOrderForAddon = async () => {
    if (isPurchased || loading) return;
    setLoading(true);
    const createRechargeOrderRes = await postRequest(
      "wallet/createSippiOrder",
      {
        amount: addonPrice,
        remark: `${addonData?.addonName} Addon Purchase`,
        productType: addonData?.addonEnum,
        currency: currency,
      }
    );
    if (createRechargeOrderRes?.result) {
      history.push(
        `/user/payment_screen?orderId=${createRechargeOrderRes.result}&redirectRoute=/user/addons`
      );
    }
    setLoading(false);
  };
  return (
    <div>
      <div id="add-ons-review-details">
        <img
          className="img-fluid ad-on-cover-banner"
          src={addonData?.data.bgImage}
          alt=""
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <img src="" alt="" />

              <div className="add-on-item-content">
                <div className="icon-div">
                  <img
                    className="img-fluid icon"
                    src={addonData?.data.logoImage}
                    alt=""
                  />
                </div>

                <h3>{addonData?.addonName}</h3>
                <p className="small">
                  {addonData?.data.longDescription || " not found"}
                </p>
                <div className="features">
                  <h6>Features</h6>
                  <div className="includes-items">
                    {addonData?.data.features.map((feature, index) => {
                      return (
                        <div className="item " key={index}>
                          <i className="fas fa-check-square text-success me-2"></i>{" "}
                          <span className="small">{feature}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="purchase-amount-content">
                <div className="fs-6 fw-bold mb-2"> Purchase Details: </div>
                <div className="includes-items">
                  <div className="fs-5 mb-2">
                    <div className="small item bg-light p-3 mb-3 w-100">
                      {
                        <div className="small text-dark">
                          {" "}
                          <div className="fs-5 mb-2">
                            Price: {currency && currencySigns[currency]}
                            {addonPrice}/-
                          </div>
                          {addonData?.data.purchaseInfo && (
                            <>
                              {" "}
                              <div className="fw-bold mb-1">API Includes</div>
                              <span>{addonData?.data.purchaseInfo}</span>{" "}
                            </>
                          )}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="purchase-btn">
                  {!loading && (
                    <button
                      className={`btn btn-primary w-100 ${
                        isPurchased ? "disabled" : ""
                      }`}
                      onClick={createOrderForAddon}
                    >
                      {isPurchased ? "Already Purchased " : "Purchase Now"}
                      {isPurchased && <i className="fas fa-check-circle"></i>}
                    </button>
                  )}
                  {loading && (
                    <button className={`btn btn-primary w-100 disabled`}>
                      Loading...
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
