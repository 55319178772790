import React from 'react'
import List from '@material-ui/core/List'
import PropTypes from 'prop-types'

function index(props) {
    return (
        <>
        <List {...props} >
            {props.children}
        </List>
        </>
    )
}

index.propTypes = {
    children: PropTypes.node.isRequired
}

export default index