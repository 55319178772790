import React, { useState } from "react";
import Inp from "commonUi/input/text/index";
import { makeStyles } from "@material-ui/core/styles";
import Btn from "commonUi/button/btnPrimary/index";
import { useRegex } from "hooks/useRegex";
import { useDispatch } from "react-redux";
import { SEND_TEST_EMAIL } from "redux/actions/siteSettings.actions";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  inp: {
    width: "100%",
    margin: "8px auto",
  },
});

const initialFormState = {
  recipient: "",
  subject: "",
  body: "",
};

export default function TestMailForm({ smtp, setLoading }) {
  const regex = useRegex();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [form, updateForm] = useState(initialFormState);

  const printInp = (label, name, value, type = "text") => {
    return (
      <Inp
        label={label}
        name={name}
        value={value}
        className={classes.inp}
        onChange={handleChange}
        required
        type={type}
      />
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateForm((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const submit = (e) => {
    e.preventDefault();
    console.log("formSubmitted", form);
    if (!validateForm()) return toast.error("Invalid form input!");
    dispatch(
      SEND_TEST_EMAIL({
        form,
        smtp,
      })
    );
    setLoading(true);
  };

  const validateForm = () => {
    const emailValid = regex.emailRegex.test(form.recipient);
    if (!emailValid) {
      return false;
    }
    return true;
  };

  return (
    <>
      <form onSubmit={submit}>
        {printInp("Send To (Recipient)", "recipient", form.recipient, "email")}
        {printInp("Subject Line", "subject", form.subject)}
        {printInp("Mail Body", "body", form.body)}
        <Btn label="Send Mail" type="submit" />
      </form>
    </>
  );
}
