import { ChangeEvent, useState } from "react";
import { getRequest, postRequest } from "tools/ajax";
import { toast } from "react-toastify";
import { useEffect } from "react";
interface IAppJson {
  appJsonName: string;
  appJsonSlug: string;
  appVersion: string;
  appIconUrl: string;
  splashImageUrl: string;
  androidAdaptiveForegroundImage: string;
  androidPackageName: string;
  versionCode: number | null;
}

export const AppJSONData: React.FC<{}> = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const siteId = queryParameters.get("id");
  const [domain, setDomain] = useState<string>("");
  const [appJson, setAppJson] = useState<IAppJson>({
    appJsonName: "",
    appJsonSlug: "",
    appVersion: "",
    appIconUrl: "",
    splashImageUrl: "",
    androidAdaptiveForegroundImage: "",
    androidPackageName: "",
    versionCode: null,
  });
  useEffect(() => {
    getAppJsonData();
  }, []);
  const getAppJsonData = async () => {
    const wlData = await getRequest(
      "admin/whitelabel/checkWhitelabelForSippiAdmin",
      { siteId }
    );
    if (wlData?.result) {
      setAppJson(wlData.result[0].app);
      setDomain(wlData.result[0].domain);
    }
  };

  const uploadAppJsonData = async () => {
    if (
      !appJson?.appJsonName.length ||
      !appJson?.androidAdaptiveForegroundImage.length ||
      !appJson?.androidPackageName.length ||
      !appJson?.appIconUrl.length ||
      !appJson?.appJsonSlug.length ||
      !appJson?.splashImageUrl.length ||
      !appJson?.versionCode
    ) {
      toast.error("Incomplete fields");
      return;
    }
    const response: any = await postRequest(
      "admin/whitelabel/uploadAppJsonData",
      {
        siteId,
        app: appJson,
      }
    );
    if (response?.success) {
      toast.success("changes saved");
    }
  };

  const handleAppJsonChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value }: any = e.target;
    if (name === "versionCode") {
      value = parseInt(value);
    }
    setAppJson((old: IAppJson) => {
      return { ...old, [name]: value };
    });
  };

  return (
    <div className="container">
      <h3 className="my-4">App json ({domain})</h3>
      <div className="  d-flex justify-content-center">
        <div className=" border border-grey px-2 py-4 w-75 bg-light shadow">
          <label htmlFor="appJsonName" className="small">
            appJsonName
          </label>
          <input
            id="appJsonName"
            name="appJsonName"
            type="text"
            className="form-control mb-2"
            onChange={handleAppJsonChange}
            value={appJson?.appJsonName || ""}
          />

          <label htmlFor="appJsonSlug" className="small">
            appJsonSlug
          </label>
          <input
            id="appJsonSlug"
            type="text"
            name="appJsonSlug"
            className="form-control mb-2"
            onChange={handleAppJsonChange}
            value={appJson?.appJsonSlug || ""}
          />

          <label htmlFor="appVersion" className="small">
            appVersion
          </label>
          <input
            id="appVersion"
            type="text"
            name="appVersion"
            className="form-control mb-2"
            onChange={handleAppJsonChange}
            value={appJson?.appVersion || ""}
          />
          <label htmlFor="appIconUrl" className="small">
            appIconUrl
          </label>
          <input
            type="text"
            className="form-control mb-2"
            id="appIconUrl"
            name="appIconUrl"
            onChange={handleAppJsonChange}
            value={appJson?.appIconUrl || ""}
          />
          <label htmlFor="splashImageUrl" className="small">
            splashImageUrl
          </label>
          <input
            type="text"
            className="form-control mb-2"
            id="splashImageUrl"
            name="splashImageUrl"
            onChange={handleAppJsonChange}
            value={appJson?.splashImageUrl || ""}
          />
          <label htmlFor="androidAdaptiveForegroundImage" className="small">
            androidAdaptiveForegroundImage
          </label>
          <input
            type="text"
            className="form-control mb-2"
            id="androidAdaptiveForegroundImage"
            name="androidAdaptiveForegroundImage"
            onChange={handleAppJsonChange}
            value={appJson?.androidAdaptiveForegroundImage || ""}
          />

          <label htmlFor="androidPackageName" className="small">
            androidPackageName
          </label>
          <input
            type="text"
            className="form-control mb-2"
            id="androidPackageName"
            name="androidPackageName"
            onChange={handleAppJsonChange}
            value={appJson?.androidPackageName || ""}
          />

          <label htmlFor="versionCode" className="small">
            versionCode
          </label>
          <input
            id="versionCode"
            name="versionCode"
            type="number"
            className="form-control mb-2"
            onChange={handleAppJsonChange}
            value={appJson?.versionCode || ""}
          />
        </div>
      </div>
      <div className="text-center mt-2">
        <button className="btn btn-success" onClick={uploadAppJsonData}>
          Submit
        </button>
      </div>
    </div>
  );
};
