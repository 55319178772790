import {flightApiTypes} from 'redux/actionTypes/flightApi.types'

export const tboSetAllBookings = (payload) => {
    return {
        type: flightApiTypes.TBO_SET_ALL_BOOKINGS,
        payload
    }
}

export const getAllAirlines = (payload) => {
    return {
        type: flightApiTypes.GET_ALL_AIRLINES,
        payload
    }
}

export const setAllAirlines = (payload) => {
    return {
        type: flightApiTypes.SET_ALL_AIRLINES,
        payload
    }
}