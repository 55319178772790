import React, { useEffect } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { IAirline } from "redux/reducers/flightApi.reducer";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DiscountMarkupTextField } from "./components/DiscountMarkupTextField";
import { useState } from "react";
import { IAgentGroup, IAirlineMarkup, IMarkupObject } from "./types";
import { Autocomplete } from "@material-ui/lab";
import { Radio } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { flightAxios } from "./commonFlightAjax";
import { FullScreenLoader } from "./components/FullScreenLoader";
import { getWhitelabelGroups } from "apiCalls/whitelabelDb";
import { ERROR_MESSAGES, isInvalidField } from "./tools/utils";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { AirlineLogo } from "./components/AirlineLogo";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        // width: 200,
      },
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

interface IMarkupFormState {
  airlineId: string;
  markupValue: number;
  agentGroupId: string;
  userGroupId: string | null;
  isDefault: boolean;
  isInternational: boolean;
  isAbsoluteMarkup: boolean;
}

export const MarkupSettings: React.FC<{
  airlines: Array<IAirline>;
}> = (props) => {
  const classes = useStyles();

  const initialFormState: IMarkupObject = {
    airlineId: { value: "", error: [], validation: ["required"] },
    markupValue: {
      value: 0,
      error: [],
      validation: ["required"],
    },
    agentGroupId: { value: "", error: [], validation: ["required"] },
    userGroupId: { value: "", error: [], validation: [] },
    isDefault: { value: false, error: [], validation: ["required"] },
    isInternational: { value: false, error: [], validation: ["required"] },
    isAbsoluteMarkup: { value: false, error: [], validation: ["required"] },
  };

  const [formState, setFormState] = useState<IMarkupObject>(initialFormState);

  const [loadingState, setLoadingState] = useState(false);
  const [airlinesMarkupState, setAirlinesMarkupState] = useState<
    Array<IAirlineMarkup>
  >([]);
  const [selectedAirlineState, setSelectedAirlineState] = useState<
    | IAirline
    | {
        text: any;
      }
    | undefined
  >({ text: "" });
  const [agentGroupsState, setAgentGroupsState] = useState<Array<IAgentGroup>>(
    []
  );
  const [selectedAgentGroup, setSelectedAgentGroup] = useState<
    | IAgentGroup
    | {
        groupName: any;
      }
    | undefined
  >({ groupName: "" });

  useEffect(() => {
    getAllAirlineMarkups();
    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    if (agentGroupsState.length) {
      setSelectedAgentGroup(
        agentGroupsState.find(
          (agent) =>
            agent.id.toString() === formState.agentGroupId.value.toString()
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.agentGroupId]);

  useEffect(() => {
    setSelectedAirlineState(
      props.airlines.find((air) => air._id === formState.airlineId.value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.airlineId]);

  const handleAirlineChange = (e, value) => {
    if (value) {
      setFormState({
        ...formState,
        airlineId: {
          ...formState.airlineId,
          value: value._id,
          error: [],
        },
      });
    }
  };

  const handleAgentGroupChange = (e, value) => {
    if (value) {
      setFormState({
        ...formState,
        agentGroupId: {
          ...formState.agentGroupId,
          value: value.id,
          error: [],
        },
      });
    }
  };

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: {
        ...formState[e.target.name],
        value: e.target.value,
        error: [],
      },
    });
  };

  //   const handleDefaultChange = (
  //     e: React.ChangeEvent<HTMLInputElement>,
  //     checked: boolean
  //   ) => {
  //     setFormState({
  //       ...formState,
  //       isDefault: {
  //         ...formState.isDefault,
  //         value: checked,
  //         error: [],
  //       },
  //     });
  //   };

  const getAllAirlineMarkups = async () => {
    let markupResponse: AxiosResponse;
    let agentGroupsResponse;
    setLoadingState(true);

    try {
      markupResponse = await flightAxios({
        url: `airlineMarkup`,
        method: "GET",
      });
      agentGroupsResponse = await getWhitelabelGroups();
    } catch (error) {
      toast.error("Error connecting to server. ");
      return;
    }

    if (
      markupResponse.data &&
      agentGroupsResponse &&
      agentGroupsResponse.success
    ) {
      setAirlinesMarkupState(markupResponse.data.result);
      setAgentGroupsState(agentGroupsResponse.result);
    }
    setLoadingState(false);
  };

  const handleDomesticChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    let isInternational: boolean = value === "true" || value === "1";
    setFormState({
      ...formState,
      isInternational: {
        ...formState.isInternational,
        value: isInternational,
        error: [],
      },
    });
  };

  const handleAbsoluteMarkupChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    let isAbsoluteMarkup: boolean =
      event.target.value === "true" || event.target.value === "1";

    setFormState({
      ...formState,
      markupValue: {
        ...formState.markupValue,
        // validation: toggleValidation(
        //   formState.markupValue.validation,
        //   "percentage"
        // ),
      },
      isAbsoluteMarkup: {
        ...formState.isAbsoluteMarkup,
        value: isAbsoluteMarkup,
        error: [],
      },
    });
  };

  const handleMarkupDeleteBtn = async (markupId: number) => {
    setLoadingState(true);

    try {
      let deleteResponse = await flightAxios({
        method: "DELETE",
        url: "airlineMarkup",
        data: {
          markupId: markupId,
        },
      });

      if (deleteResponse.data.success) {
        toast.success("Markup deleted successfully");
      } else {
        throw new Error();
      }
    } catch (error: any) {
      toast.success(error?.message);
    }

    await getAllAirlineMarkups();
    setLoadingState(false);
  };

  const handleMarkupEditBtn = (discountRowIndx) => {
    let formData = { ...formState };

    Object.keys(formData).forEach((key) => {
      formData[key] = {
        ...formData[key],
        value: airlinesMarkupState[discountRowIndx][key],
      };
    });
    formData.agentGroupId.value =
      airlinesMarkupState[discountRowIndx].agentGroupId.id.toString();

    setFormState(formData);
  };

  const handleMarkupSave = async () => {
    setLoadingState(true);

    let formData: IMarkupFormState = {
      isAbsoluteMarkup: false,
      markupValue: 0,
      airlineId: "",
      isDefault: false,
      isInternational: false,
      agentGroupId: "",
      userGroupId: null,
    };

    let newFormDataState = { ...formState };

    let errorExists = false;

    Object.keys(newFormDataState).forEach((fieldKey) => {
      formData[fieldKey] = newFormDataState[fieldKey].value;

      if (newFormDataState[fieldKey].value !== "") {
        formData[fieldKey] = newFormDataState[fieldKey].value;
      } else {
        formData[fieldKey] = undefined;
      }
      if (isInvalidField(newFormDataState[fieldKey])) {
        newFormDataState[fieldKey].error = ["This Field is required"];
        errorExists = true;
      }
    });

    setFormState(newFormDataState);
    console.log("errorExists", errorExists);

    if (!errorExists) {
      try {
        let saveResponse = await flightAxios({
          url: "airlineMarkup",
          data: formData,
        });

        await getAllAirlineMarkups();

        let backendErrors = saveResponse.data.errors;
        if (backendErrors) {
          let newFormDataState = { ...formState };
          Object.keys(newFormDataState).forEach((fieldKey) => {
            if (backendErrors[fieldKey]) {
              newFormDataState[fieldKey].error = backendErrors[fieldKey];
            }
          });
          setFormState(newFormDataState);
        } else {
          setFormState(initialFormState);
        }
      } catch (error) {
        toast.error(ERROR_MESSAGES.SERVER_TRY_LATER);
      }
    }
    setLoadingState(false);
  };

  return (
    <>
      <FullScreenLoader show={loadingState} />
      <div>
        <form
          className={`${classes.root} text-center`}
          noValidate
          autoComplete="off"
        >
          <div>
            <div className="my-4 ">
              <Typography variant="h5">Airline Specific Markup</Typography>
            </div>

            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={formState.isDefault.value} onChange={handleDefaultChange} name="isDefault" />}
                                    label="Is Default ?"
                                />
                            </Grid> */}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="isInternational"
                    name="isInternational"
                    value={formState.isInternational.value}
                    onChange={handleDomesticChange}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Domestic"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="International"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={agentGroupsState}
                  getOptionLabel={(option) => {
                    if (option) {
                      return option.groupName;
                    }
                  }}
                  value={selectedAgentGroup}
                  onChange={handleAgentGroupChange}
                  className="w-100"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select Agent Group"
                      variant="outlined"
                      error={formState.agentGroupId.error.length > 0}
                    />
                  )}
                />
              </Grid>

              {!formState.isDefault.value && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={props.airlines}
                    getOptionLabel={(option) => option?.text}
                    value={selectedAirlineState}
                    onChange={handleAirlineChange}
                    className="w-100"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Select Airline"
                        variant="outlined"
                        error={formState.airlineId.error.length > 0}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} className="py-0">
                <Typography className="mt-3 text-left" variant="h6">
                  Pricing
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className="mt-2 w-100"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={formState.isAbsoluteMarkup.value}
                    onChange={handleAbsoluteMarkupChange}
                  >
                    <MenuItem value="true">Fixed</MenuItem>
                    <MenuItem value="false">Percentage</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <DiscountMarkupTextField
                  name="markupValue"
                  label="Markup Value"
                  value={formState.markupValue}
                  onChange={handleInputChange}
                  textFieldProps={{
                    type: "number",
                    className: "w-100",
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  className="mt-2 mb-5"
                  color="primary"
                  variant="contained"
                  onClick={handleMarkupSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>

            {airlinesMarkupState.map((air, index) => {
              // if (!air. || air.airline_joint.length !== 1) {
              //   return null;
              // }
              const airline = props.airlines.find(
                (airline) => airline._id === air.airlineId
              );

              if (!airline) return null;

              return (
                <>
                  <Grid
                    key={air.id}
                    justify="center"
                    alignItems="center"
                    container
                    spacing={2}
                  >
                    <Grid item xs={3} className="text-center">
                      <AirlineLogo logoUrl={airline.logo?.logoUrl} />
                    </Grid>
                    <Grid item xs={3} className="text-center">
                      <p className="mb-0">
                        <small>Markup</small>
                      </p>
                      <p>
                        {air.markupValue}
                        {!air.isAbsoluteMarkup && "%"}
                      </p>
                    </Grid>
                    <Grid item xs={3} className="text-center">
                      <p className="bg-dark text-white rounded">
                        {air.agentGroupId.groupName}
                      </p>
                    </Grid>
                    <Grid item xs={3} className="text-center">
                      <div>
                        {air.isInternational ? "International" : "Domestic"}
                      </div>

                      <Button
                        onClick={() => handleMarkupEditBtn(index)}
                        variant="contained"
                        color="primary"
                        size="small"
                        className="mt-2"
                      >
                        Edit
                      </Button>

                      <Button
                        onClick={() => handleMarkupDeleteBtn(air.id)}
                        variant="contained"
                        color="primary"
                        size="small"
                        className="mt-2 bg-danger"
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                  <hr />
                </>
              );
            })}
          </div>
        </form>
      </div>
    </>
  );
};
