import whatsAppLogo from "../../../assets/images/AddonsImages/whatsappLogo.png";
import whatsApp from "../../../assets/images/AddonsImages/whatsapp.png";
import tfNexuxLogo from "../../../assets/images/AddonsImages/tfNexuxLogo.png";
import tfNexuxBg from "../../../assets/images/AddonsImages/tfbg.png";
import AirIQ from "../../../assets/images/AddonsImages/AirIQ.png";
import AirIQLogo from "../../../assets/images/AddonsImages/airIqLogo.png";
import callerDesk from "../../../assets/images/AddonsImages/callerDesk.png";
import callerDeskLogo from "../../../assets/images/AddonsImages/callerDeskLogo.png";
import galileo from "../../../assets/images/AddonsImages/Galileo.png";
import galileoLogo from "../../../assets/images/AddonsImages/GalileoLogo.png";
import tbo from "../../../assets/images/AddonsImages/tbo.png";
import tboLogo from "../../../assets/images/AddonsImages/tbo_logo.png";
import tripjackLogo from "../../../assets/images/AddonsImages/tripjack.png";
import tripjackCover from "../../../assets/images/AddonsImages/tripjackCover.png";
import CheapFixDeparture from "../../../assets/images/AddonsImages/CheapFixDeparture.png";
import CheapFixDepartureBg from "../../../assets/images/AddonsImages/CheapFixDepartureBg.png";
import fareBotiqueLogo from "../../../assets/images/AddonsImages/fareBotique.png";
import fareBotiqueBg from "../../../assets/images/AddonsImages/fareBotiqueBg.png";
import clearTrip from "../../../assets/images/AddonsImages/clearTrip.png";
import clearTripBg from "../../../assets/images/AddonsImages/clearTripBG.png";
import amadeusBg from "../../../assets/images/AddonsImages/amadeus_bg.png";
import amadeusLogo from "../../../assets/images/AddonsImages/amadeus_logo.png";
import { productTypeEnum } from "commonFnBcode/types/whitelabel.types";

export const AddonsData = [
  {
    addonEnum: productTypeEnum.TRIP_JACK,
    addonName: "Tripjack API",
    data: {
      logoImage: tripjackLogo,
      bgImage: tripjackCover,
      shortDescription:
        "Tripjack provides a multiple options and deals for travelers",
      longDescription: `Tripjack provide white label solution to travel agents, tour operators and travel distributors with best user-friendly platform.`,
      features: [
        "Reliable Technology",
        "Fast Response",
        "Real-Time Booking",
        "Special Contracted Fares",
      ],

      purchaseInfo: "Tripjack API",
    },
  },
  {
    addonEnum: productTypeEnum.TBO,
    addonName: "TBO API",
    data: {
      logoImage: tboLogo,
      bgImage: tbo,
      shortDescription:
        "Travel Boutique Online (TBO) offers the White label Solutions and B2B travel agency.",
      longDescription: `TBO API integration allows travel agencies to automate multiple processes, which can result in time and effort savings. Agents can confirm bookings quickly without the need for manual verification thanks to the API's real-time availability checks.`,
      features: [
        "Flights from all major airlines",
        "Real-time availability checks for bookings",
        "Reliable Technology",
        "Instant confirmation",
        "Fast Response",
      ],

      purchaseInfo: "TBO API",
    },
  },

  {
    addonEnum: productTypeEnum.WHATSAPP,
    addonName: "WhatsApp API",
    data: {
      logoImage: whatsAppLogo,
      bgImage: whatsApp,
      shortDescription:
        "Seamless notification system for suppliers and users alike.",
      longDescription:
        "Receive instant alerts whenever a CRM enquiry is generated, ensuring you're always up-to-date and ready to act promptly. Whether you're a supplier eager to capitalize on new opportunities or a user seeking timely responses, our notification system ensures that important interactions are never missed.",
      features: [
        "Instant Alerts: Receive immediate notifications when a CRM enquiry is created.",
        "Real-Time Updates: Stay informed about new opportunities or inquiries as they happen.",
        "Customizable Preferences: Tailor your notification settings to receive alerts according to your preferences.",
        "Stay Ahead: Be proactive in seizing opportunities and providing timely responses to users.",
        "Easy Setup: Get started quickly and effortlessly with our intuitive notification setup process.",
      ],

      purchaseInfo: "WhatsApp API",
    },
  },
  {
    addonEnum: productTypeEnum.CALLER_DESK,
    addonName: "Caller Desk API",
    data: {
      logoImage: callerDeskLogo,
      bgImage: callerDesk,
      shortDescription:
        "Improve customer satisfaction and streamline call management with the CallerDesk API.",
      longDescription: `It is built especially for businesses to help them intelligently manage callers and for automating self-service options.`,
      features: [
        "Build a virtual contact center to enable a call support system for your customers.",
        "Track all your calls.",
        "Provides virtual numbers for easy management.",
        "The platform is relatively intuitive and easy to use.",
      ],

      purchaseInfo: "Caller Desk API",
    },
  },
  {
    addonEnum: productTypeEnum.AIR_IQ,
    addonName: "AIRIQ API",
    data: {
      logoImage: AirIQLogo,
      bgImage: AirIQ,
      shortDescription:
        "AIR iQ is user-friendly FIT air ticketing portal catering to the diverse needs of trade partners in the travel industry.",
      longDescription:
        "Air iQ is a fixed departure portal in India that offers fixed departures for multilple sectors.",
      features: [
        "Reliable Technology",
        "Fast Response",
        "Real-Time Booking",
        "Special Contracted Fares",
      ],

      purchaseInfo: "AIRIQ API",
    },
  },
  {
    addonEnum: productTypeEnum.FARE_BOUTIQUE,
    addonName: "Fare Boutique",
    data: {
      logoImage: fareBotiqueLogo,
      bgImage: fareBotiqueBg,
      shortDescription:
        "Fare Boutique Live Inventory Sharing System to access a network of Travel Itineraries across multiple destinations",
      longDescription: `This tool offers you access to a network of Travel Itineraries across multiple destinations.`,
      features: ["Easy booking", "Fast Response", "Discounted rates"],

      purchaseInfo: "Fare Boutique",
    },
  },

  // {
  //   addonEnum: productTypeEnum.CLEAR_TRIP,
  //   addonName: "Clear Trip API",

  //   data: {
  //     logoImage: clearTrip,
  //     bgImage: clearTripBg,
  //     shortDescription:
  //       "Cleartrip (previously known as Cleartrip Travel Services Private Limited) is a global online travel company.",
  //     longDescription:
  //       "Cleartrip is an online travel aggregator website for booking flights in multiple countries.",
  //     features: [
  //       "Reliable Technology",
  //       "Fast Response",
  //       "Real-Time Booking",
  //       "Special Contracted Fares",
  //     ],

  //     purchaseInfo: "Clear Trip API",
  //   },
  // },
  {
    addonEnum: productTypeEnum.TFNEXUS,
    addonName: "TF Nexus API",
    data: {
      logoImage: tfNexuxLogo,
      bgImage: tfNexuxBg,
      shortDescription: "TF Nexus is a travel and tourism company ",
      longDescription: `TF Nexus provides you access to a network of Travel Itineraries across multiple countries.`,
      features: [
        "Reliable Technology",
        "Fast Response",
        "Real-Time Booking",
        "Special Contracted Fares",
      ],

      purchaseInfo: "TF Nexus API",
    },
  },
  {
    addonEnum: productTypeEnum.AMADEUS,
    addonName: "Amadeus",
    data: {
      logoImage: amadeusLogo,
      bgImage: amadeusBg,
      shortDescription:
        "Amadeus Live Inventory Sharing System to access a network of Travel Itineraries across multiple destinations",
      longDescription: `This tool offers you access to a network of Travel Itineraries across multiple destinations.`,
      features: ["Easy booking", "Fast Response", "Discounted rates"],

      purchaseInfo: "Fare Boutique",
    },
  },
  // {
  //   addonEnum: productTypeEnum.GALILEO,
  //   addonName: "Galileo API",
  //   data: {
  //     logoImage: galileoLogo,
  //     bgImage: galileo,
  //     shortDescription:
  //       "The Galileo GDS API can be used for flight packages availability and booking.",
  //     longDescription:
  //       "Galileo API Integration provids interactive functionality and booking systems with B2C and B2B travel modules for travel agents.",
  //     features: [
  //       "Reliable Technology",
  //       "Fast Response",
  //       "Real-Time Booking",
  //       "Special Contracted Fares",
  //     ],

  //     purchaseInfo: "Galileo API",
  //   },
  // },
];
