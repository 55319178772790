import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  mainGrid: {
    padding: "1rem",
  },
});

// term and condition
export default function TermsAndConditions() {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2} className={classes.mainGrid} justify="center">
        <Grid item lg={12}>
          <br />
          <br />
          <p
            align="center"
            variant="h3"
            color="primary"
            display="block"
            componnet="h1"
          >
            Terms & Conditions
          </p>
        </Grid>
        <Grid item lg={10}>
          <br />
          <br />
          <p>
            This web site (the "Site") is published and maintained by Sippi
            Software Solutions Private Limited (" SippiSoftware.com"), a company
            incorporated and existing in accordance with the laws of the
            Republic of India. When you access, browse or use this Site you
            accept, without limitation or qualification, the terms and
            conditions set forth below. When you access any sub-site (whether
            belonging to an ‘associate’ of SippiSoftware.com) through this site,
            then such sub-site may have its own terms and conditions of use
            which is specific to such sub-site. Sub-sites may contain such
            additional terms and conditions of use as may be set out in such
            sub-site.
          </p>

          <br />
          <b>Site and Its Contents</b>

          <br />
          <br />
          <p>
            This Site is only for your personal use. You shall not distribute,
            exchange, modify, sell or transmit anything you copy from this Site,
            including but not limited to any text, images, audio and video, for
            any business, commercial or public purpose. As long as you comply
            with the terms of these Terms and Conditions of Use,
            SippiSoftware.com grants you a non-exclusive, non-transferable,
            limited right to enter, view and use this Site. You agree not to
            interrupt or attempt to interrupt the operation of this Site in any
            way. Access to certain areas of the Site may only be available to
            registered members. To become a registered member, you may be
            required to answer certain questions. Answers to such questions may
            be mandatory and/or optional. You represent and warrant that all
            information you supply to us, about yourself, and others, is true
            and accurate.
          </p>

          <br />
          <b>Ownership</b>
          <br />
          <br />
          <p>
            All materials on this Site, including but not limited to audio,
            images, software, text, icons and such like (the "Content"), are
            protected by copyright under international conventions and copyright
            laws. You cannot use the Content, except as specified herein. You
            agree to follow all instructions on this Site limiting the way you
            may use the Content. There are a number of proprietary logos,
            service marks and trademarks found on this Site whether owned/used
            by SippiSoftware.com or otherwise. By displaying them on this Site,
            SippiSoftware.com is not granting you any license to utilize those
            proprietary logos, service marks, or trademarks. Any unauthorized
            use of the Content may violate copyright laws, trademark laws, the
            laws of privacy and publicity, and civil and criminal statutes. You
            may download such copy/copies of the Content to be used only by you
            for your personal use at home unless the subsite you are accessing
            states that you may not. If you download any Content from this Site,
            you shall not remove any copyright or trademark notices or other
            notices that go with it.
          </p>

          <br />
          <b>Others Rights</b>
          <br />
          <br />
          <p>
            If this Site contains bulletin boards, chat rooms, access to mailing
            lists or other message or communication facilities, you agree to use
            the same only to send and receive messages and materials that are
            proper and related thereto. By way of example and not as a
            limitation, you agree that when using the Site or any facility
            available herefrom, you shall not do any of the following:
          </p>
          <ul>
            <li>
              Defame, abuse, harass, stalk, threaten or otherwise violate the
              legal rights (such as rights of privacy and publicity) of others.
            </li>
            <li>
              Publish, post, distribute or disseminate any defamatory,
              infringing, obscene, indecent or unlawful material or information.
            </li>
            <li>
              Upload or attach files that contain software or other material
              protected by intellectual property laws (or by rights of privacy
              and publicity) unless the User owns or controls the rights thereto
              or has received all consents therefor as may be required by law.
            </li>
            <li>
              Upload or attach files that contain viruses, corrupted files or
              any other similar software or programs that may damage the
              operation of another’s computer.
            </li>
            <li>
              Delete any author attributions, legal notices or proprietary
              designations or labels in any file that is uploaded.
            </li>
            <li>
              Falsify the origin or source of software or other material
              contained in a file that is uploaded.
            </li>
            <li>
              Advertise or offer to sell any goods or services, or conduct or
              forward surveys, contests or chain letters, or download any file
              posted by another user of a Forum that the User knows, or
              reasonably should know, cannot be legally distributed in such
              manner.
            </li>
          </ul>

          <br />
          <b>User's Material</b>
          <br />
          <br />
          <p>
            You are prohibited from posting or transmitting any defamatory,
            libelous, obscene, pornographic, profane, threatening or unlawful
            material or any material that could constitute or encourage conduct
            that would be considered a criminal offense or give rise to civil
            liability, or otherwise violate any law.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com assumes no liability or responsibility arising
            from the contents of any communications containing any defamatory,
            erroneous, inaccurate, libelous, obscene or profane material.
            SippiSoftware.com may change, edit, or remove any user material or
            conversations that are illegal, indecent, obscene or offensive, or
            that violates SippiSoftware.com 's policies in any way.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com will fully cooperate with any law enforcement
            authorities or court order requesting or directing SippiSoftware.com
            to disclose the identity of anyone posting such materials.
          </p>

          <br />
          <b>Important Terms and Conditions</b>
          <br />
          <br />
          <p>
            SippiSoftware.com (Sippi Software Solutions Private Limited) is not
            a tour operator / hotel chain / airlines company or entertainment
            company. Therefore, it is concluded that we ( SippiSoftware.com /
            Sippi Software Solutions Private Limited) are not the end service
            provider in any condition. If there is any kind of lack /
            inconsistency / inability / delay in providing any service booked
            through Sippi Software Solutions Private Limited / SippiSoftware.com
            then we are not responsible.
          </p>
          <br />
          <br />
          <p>
            Sippi Software Solutions Private Limited / SippiSoftware.com
            provides you a platform / technology / medium to book travel
            services online. Any kind of services booked from our website are
            booked at customer's / travel agent's own will and risk. We are pure
            agent / intermediary and we book on behalf of customer / user /
            travel agent. Funds for the bookings / services are transferred to
            supplier / hotel / travel company / airlines on behalf of customer /
            travel agent / corporate client / user and the remaining amount /
            money is the service charge for providing a technology / platform to
            book travel arrangements for you.
          </p>

          <br />
          <b>Content &amp; Copyright</b>
          <br />
          <br />
          <p>
            SippiSoftware.com is a product designed and owned by Sippi Software
            Solutions Private Limited. All the content on this website has been
            provided by suppliers / third parties at their own wish for their
            own benefit. If content / functionality / technology on this website
            voilates any copyright law / patent or it is intellectual property
            of any third party. Then the party must contact Sippi Software
            Solutions Private Limited on Support@SippiSoftware.com and +91
            7686963000 in order to request / notify us to modify and remove the
            disputed content if required. Minimum time of 15 days has to be
            alloted to change the content if required. Any legal action must
            only be considered only if, after notifing us on the given contact
            details and providing appropriate time to make the required changes
            Sippi Software Solutions Private Limited / SippiSoftware.com fails
            to modify / remove the disputed content.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com / Sippi Software Solutions Private Limited has no
            intention to harm / defame any person / company / organisation /
            institution / religion in any condition. The only motive of this
            website / product is to make the process of making travel
            arrangements simple, easy, effective and affordable. This product
            has been designed to increase efficiency and reduce human effort. If
            in case of any technical error or human error by the team of Sippi
            Software Solutions Private limited / SippiSoftware.com any kind of
            loss (financial / legal / time related) happens to any person /
            organisation / institution / community then it is a pure accident
            and not intentional at all. We are not responsible / liable for any
            kind of loss due to human or technical error.
          </p>
          <br />
          <b>Right to Change Service Fee</b>
          <br />
          <br />
          <p>
            SippiSoftware.com or Sippi Software Solutions Private Limited
            reserves the right to change its service fee in order to maintain
            financial sustainability. Since the development cost of the product
            is not being paid by the customers and only a fraction of the actual
            cost/expense is being charged, the service fee to be collected from
            a single customer might vary based on the total number of customers
            and the total expense of the company. In case, we (sippisoftware.com
            or Sippi Software Solutions Private Limited) changes the price/fee
            for the services/software then the customer can either accept the
            change in service fee or choose to terminate the service.
          </p>

          <br />
          <b>Right To Terminate Service</b>
          <br />
          <br />
          <p>
            Sippisoftware.com or Sippi Software Solutions Private Limited
            reservers the right to terminate the service at any time without
            providing any reason or clarification for the termination of the
            service. All sorts of service fee / usage fee / maintainance fee and
            other expenses are completely non-refundable. Only the setup fee of
            product is refundable if the service is terminated by Sippi Software
            Solutions Private Limited. If customer wish to terminate the service
            then no refund will be provided.
            <br /> Sippisoftware.com or Sippi Software Solutions Private Limited
            reserves the right to change the terms and conditions of the service
            at any time without any prior notice.
          </p>

          <br />
          <b>Limited Liability Policy</b>
          <br />
          <br />
          <p>
            The information, software, products, and services published on this
            website may include inaccuracies or errors, including pricing
            errors. In particular, the SippiSoftware.com companies and the
            SippiSoftware.com affiliates do not guarantee the accuracy of, and
            disclaim all liability for any errors or other inaccuracies relating
            to the information and description of travel products displayed on
            this website (including, without limitation, the pricing,
            photographs, list of hotel amenities, general product descriptions,
            etc.), much of which information is provided by the respective
            suppliers. In addition, SippiSoftware.com expressly reserves the
            right to correct any pricing errors on our website and/or on pending
            reservations made under an incorrect price. In such event, if
            available, we will offer you the opportunity to keep your pending
            reservation at the correct price or we will cancel your reservation
            without penalty.
          </p>

          <br />
          <b>SippiSoftware.com Rights</b>
          <br />
          <br />
          <p>
            If you send any communications or materials to the Site by
            electronic mail or otherwise, including any comments, data,
            questions, suggestions or the like, all such communications are, and
            will be treated by SippiSoftware.com, as non-confidential.
          </p>
          <br />
          <br />
          <p>
            You hereby give up any and all claim that any use of such material
            violates any of your rights including moral rights, privacy rights,
            proprietary or other property rights, publicity rights, rights to
            credit for material or ideas, or any other right, including the
            right to approve the way SippiSoftware.com uses such material.
          </p>
          <br />
          <br />
          <p>
            Any material submitted to this Site may be adapted, broadcast,
            changed, copied, disclosed, licensed, performed, posted, published,
            sold, transmitted or used by SippiSoftware.com anywhere in the
            world, in any medium, forever.
          </p>
          <br />
          <b>Transmitted Material</b>
          <br />
          <br />
          <p>
            Internet transmissions are never completely private or secure. You
            understand that any message or information you send to this Site may
            be read or intercepted by others unless there is a special notice
            that a particular message (for example, credit card information) is
            encrypted (send in code). Sending a message to SippiSoftware.com
            does not cause SippiSoftware.com to have any special responsibility
            to you.
          </p>
          <br />
          <br />
          <p>
            The copyright in the contents of this website belong to
            SippiSoftware.com. Accordingly PackgeKart.com reserves all rights.
            Copying of part or all the contents of this website without
            permission of SippiSoftware.com is prohibited except to the extent
            that such copying/printing is necessary for the purposes of availing
            of the paid services provided.
          </p>
          <br />
          <b>Disclaimer</b>
          <br />
          <br />
          <p>
            The material in this Site could include technical inaccuracies or
            typographical errors. SippiSoftware.com may make changes or
            improvements at any time.
          </p>
          <br />
          <br />
          <p>
            The materials on this site are provided on an “As Is” basis, without
            warranties of any kind either expressed or implied. Tot the fullest
            extent permissible pursuant to applicable law, SippiSoftware.com
            disclaims all warranties of merchantibility and fitness for a
            particular purpose.
          </p>
          <br />
          <br />
          <p>
            It is the responsibility of the hotel chain and/or the individual
            property to ensure the accuracy of the photos displayed. '
            SippiSoftware.com ' is not responsible for any inaccuracies in the
            photos.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com does not warrant that the functions contained in
            this site will be uninterrupted or error free, that defects will be
            corrected, or that this site or the servers that make it available
            are free of viruses or other harmful components, but shall endeavour
            to ensure your fullest satisfaction.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com does not warrant or make any representations
            regarding the use of or the result of the use of the material on the
            site in terms of their correctness, accuracy, reliability, or
            otherwise, insofar as such material is derived from other service
            providers such as airlines, hotel owners and tour operators.
          </p>
          <br />
          <br />
          <p>
            You acknowledge that this Website is provided only on the basis set
            out in these terms and conditions. Your uninterrupted access or use
            of this Website on this basis may be prevented by certain factors
            outside our reasonable control including, without limitation, the
            unavailability, inoperability or interruption of the Internet or
            other telecommunications services or as a result of any maintenance
            or other service work carried out on this Website. SippiSoftware.com
            does not accept any responsibility and will not be liable for any
            loss or damage whatsoever arising out of or in connection with any
            ability/inability to access or to use the Site.
          </p>
          <br />
          <br />
          <p>
            You also acknowledge that through this Site, SippiSoftware.com
            merely provides intermediary services in order to facilitate highest
            quality services to you SippiSoftware.com is not the last-mile
            service provider to you and therefore, SippiSoftware.com shall not
            be or deemed to be responsible for any lack or deficiency of
            services provided by any person (airline, travel/tour operator,
            hotel, facility or similar agency) you shall engage or hire or
            appoint pursuant to or resulting from, the material available in
            this Site.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com will not be liable to you or to any other person
            for any direct, indirect, incidental, punitive or consequential
            loss, damage, cost or expense of any kind whatsoever and howsoever
            caused from out of your usage of this Site.
          </p>
          <br />
          <br />
          <p>
            Notwithstanding anything else to the contrary contained elsewhere
            herein or otherwise at law, SippiSoftware.com’s liability (whether
            by way of indemnification to you or otherwise) shall be restricted
            to a maximum of INR 1000 (Indian Rupees One Thousand only).
          </p>

          <br />
          <b>Availability</b>
          <br />
          <br />
          <p>
            The products and services displayed on the Site may not be available
            for purchase in your particular country or locality. The reference
            to such products and services on the Site does not imply or warrant
            that these products or services will be available at any time in
            your particular geographical location. You should check with your
            local SippiSoftware.com authorized representative for the
            availability of specific products and services in your area.
          </p>

          <br />
          <b>Terms and Conditions of Use</b>
          <br />
          <br />
          <p>
            SippiSoftware.com may add to, change or remove any part of these
            Terms and Conditions of Use at any time, without notice. Any changes
            to these Terms and Conditions of Use or any terms posted on this
            Site apply as soon as they are posted. By continuing to use this
            Site after any changes are posted, you are indicating your
            acceptance of those changes.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com may add, change, discontinue, remove or suspend
            any other Content posted on this Site, including features and
            specifications of products described or depicted on the Site,
            temporarily or permanently, at any time, without notice and without
            liability.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com reserves the right to undertake all necessary
            steps to ensure that the security, safety and integrity of
            SippiSoftware.com 's systems as well as its clients’ interests are
            and remain, well-protected. Towards this end, SippiSoftware.com may
            take various steps to verify and confirm the authenticity,
            enforceability and validity of orders placed by you.
          </p>
          <br />
          <br />
          <p>
            If SippiSoftware.com, in its sole and exclusive discretion,
            concludes that the said orders are not or do not reasonably appear
            to be, authentic, enforceable or valid, then SippiSoftware.com may
            cancel the said orders at any time up to 4 hours before the
            scheduled time of departure of the relevant flight or 4 hours before
            the expected date of visit to any property booked through
            SippiSoftware.com.
          </p>

          <br />
          <b>General Provisions</b>
          <br />
          <br />
          <p>
            You may travel to certain destinations which involve greater risks
            than others, entirely at your risk as to cost and consequences.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com requests you to consult your local authorities and
            evaluate travel prohibitions, warning, announcements, and advisories
            issued by them before booking travel to certain international
            destinations.
          </p>
          <br />
          <br />
          <p>
            By offering for sale travel to particular international
            destinations, SippiSoftware.com does not represent or warrant that
            travel to such point is advisable or without risk. SippiSoftware.com
            does not accept liability for damages, losses, or delays that may
            result from improper documents for entry, exit, length of stay, or
            from travel to such destinations.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com reserves its exclusive right in its sole
            discretion to alter, limit or discontinue the Site or any material
            posted herein, in any respect. SippiSoftware.com shall have no
            obligation to take the needs of any User into consideration in
            connection therewith.
          </p>
          <br />
          <br />
          <p>
            SippiSoftware.com reserves its right to deny in its sole discretion
            any user access to this Site or any portion hereof without notice.
          </p>
          <br />
          <br />
          <p>
            No waiver by SippiSoftware.com of any provision of these Terms and
            Conditions shall be binding except as set forth in writing and
            signed by its duly authorized representative.
          </p>
          <br />
          <br />
          <p>
            If any dispute arises between you and SippiSoftware.com during your
            use of the Site or thereafter, in connection with and arising from
            your use or attempt to use this Site, the dispute shall be referred
            to arbitration. The place of arbitration shall be Kurukshetra. The
            arbitration proceedings shall be in the English language.
          </p>
          <br />
          <br />
          <p>
            The said arbitration proceedings shall be governed and construed in
            accordance with the Arbitration and Conciliation Act, 1996 and
            modifications thereof as in force at the relevant time.
          </p>
          <br />
          <br />
          <p>
            These terms and conditions are governed by and shall be construed in
            accordance with the laws of the Republic of India and any dispute
            shall exclusively be subject to the jurisdiction of the appropriate
            Courts situated at Mohali, Punjab.
          </p>
        </Grid>
      </Grid>
    </>
  );
}
