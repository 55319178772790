import { getRequest, postRequest } from "tools/ajax";

export class AdminApi {
  static getDuplicateEmails = async () => {
    const duplicateEmails = await getRequest(
      "admin/master/getDuplicateEmails",
      {}
    );
    return duplicateEmails;
  };
  static getDuplicatePhones = async () => {
    const duplicatePhones = await getRequest(
      "admin/master/getDuplicatePhones",
      {}
    );
    return duplicatePhones;
  };

  static getDuplicateUsersByPhoneEmail = async (searchString: string) => {
    const duplicateUsers = await getRequest("admin/master/getDuplicateUsers", {
      searchString,
    });
    return duplicateUsers;
  };

  static getFLightSupplierEmailLogs = async (
    page: number,
    pnr_not_found: boolean
  ) => {
    const duplicateUsers = await postRequest("admin/flightSupplierEmailLogs", {
      page: page,
      pnr_not_found,
    });
    return duplicateUsers;
  };
}
