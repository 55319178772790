import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { selectWlMeta } from "redux/slices/wlMeta.slice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { SET_LOADER } from "redux/actions/common.actions";
import { postRequest } from "tools/ajax";

export const FacebookAuthSetting = () => {
  const initialState = {
    facebookAppSecret: null,
    facebookAppId: null,
  };

  const [facebookCred, setfacebookCred] = useState<{
    facebookAppSecret: string | null;
    facebookAppId: string | null;
  }>(initialState);
  const wlMeta = useAppSelector(selectWlMeta);
  const dispatch = useAppDispatch();

  const saveFacebookClientId = async () => {
    dispatch(SET_LOADER(true));
    try {
      const response = await postRequest("userAdvance/saveWlMetaData", {
        wlMeta: {
          facebookAppSecret: facebookCred?.facebookAppSecret || "",
          facebookAppId: facebookCred?.facebookAppId || "",
        },
      });

      if (response.success) {
        toast.success("Facebook Client Id saved successfully");
      } else {
        toast.error(response.errorMessage);
      }
    } catch (error: any) {
      toast.error(error.message || "Could not save Facebook Client Id...");
      console.log({ saveFacebookClientIdError: error });
    }
    dispatch(SET_LOADER(false));
  };

  const handleInpChange = (e) => {
    let { name, value } = e.target;
    setfacebookCred((old) => {
      return { ...old, [name]: value };
    });
  };

  useEffect(() => {
    if (wlMeta) {
      setfacebookCred({
        facebookAppId: wlMeta.facebookAppId || "",
        facebookAppSecret: wlMeta.facebookAppSecret || "",
      });
    }
  }, [wlMeta?.facebookAppId, wlMeta?.facebookAppSecret]);

  return (
    <div className="row shadow p-2 mt-4">
      <div className="py-4 col-md-6 d-flex flex-column align-items-center justify-content-center text-start">
        <div className="mb-2">
          <label htmlFor="" className="text-start small">
            Facebook App Secret
          </label>
          <input
            value={facebookCred?.facebookAppSecret || ""}
            type="text"
            className="form-control"
            name="facebookAppSecret"
            onChange={handleInpChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-start small">
            Facebook App Id
          </label>
          <input
            value={facebookCred?.facebookAppId || ""}
            type="text"
            className="form-control"
            name="facebookAppId"
            onChange={handleInpChange}
          />
        </div>
        <button className="btn btn-primary mt-2" onClick={saveFacebookClientId}>
          Save
        </button>
      </div>
      <div className="col-md-6"></div>
    </div>
  );
};
