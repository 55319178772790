import React from "react";
import "./partnerSippiPage.scss";
// import Footer from "./components/Footer";
// import MainNavbar from "./components/MainNavbar";
import flightService from "../../../assets/images/newHome/partner-sippi/e-shop -service/1.png";
import fdService from "../../../assets/images/newHome/partner-sippi/e-shop -service/2.png";
import tourService from "../../../assets/images/newHome/partner-sippi/e-shop -service/3.png";
import whitelabelImg from "../../../assets/images/newHome/partner-sippi/whitelable-img.png";
import reactLogo from "../../../assets/images/newHome/partner-sippi/tech-logo/1.png";
import nodeLogo from "../../../assets/images/newHome/partner-sippi/tech-logo/2.png";
import mongo from "../../../assets/images/newHome/partner-sippi/tech-logo/3.png";
import mysql from "../../../assets/images/newHome/partner-sippi/tech-logo/4.png";
import typeScript from "../../../assets/images/newHome/partner-sippi/tech-logo/5.png";
import aws from "../../../assets/images/newHome/partner-sippi/tech-logo/6.png";
import dataProtection from "../../../assets/images/newHome/partner-sippi/technology-logo/1.png";
import customDesign from "../../../assets/images/newHome/partner-sippi/technology-logo/2.png";
import language from "../../../assets/images/newHome/partner-sippi/technology-logo/3.png";
import sslProtection from "../../../assets/images/newHome/partner-sippi/technology-logo/4.png";
import privateServer from "../../../assets/images/newHome/partner-sippi/technology-logo/5.png";
import fdMockup from "../../../assets/images/newHome/partner-sippi/fixed-departure-mockup.png";
import vocalForLocal from "../../../assets/images/newHome/partner-sippi/vocal-for-local.png";
import cashFree from "../../../assets/images/newHome/partner-sippi/payment-logo/1.png";
import ccAvenue from "../../../assets/images/newHome/partner-sippi/payment-logo/2.png";
import razorPay from "../../../assets/images/newHome/partner-sippi/payment-logo/3.png";
import icici from "../../../assets/images/newHome/partner-sippi/payment-logo/4.png";
import hdfc from "../../../assets/images/newHome/partner-sippi/payment-logo/8.png";
import paytm from "../../../assets/images/newHome/partner-sippi/payment-logo/6.png";
import sampleWork from "../../../assets/images/newHome/partner-sippi/sample work/sample-work.png";
import sampleApp from "../../../assets/images/newHome/partner-sippi/travel-app-mockup.png";
import androidButton from "../../../assets/images/newHome/partner-sippi/android-button.png";
import priceImg from "../../../assets/images/newHome/partner-sippi/price-img.png";
import easebuzz from "../../../assets/images/newHome/partner-sippi/payment-logo/7.png";
export const PartnerSippiPage = () => {
  return (
    <div>
      {/* <!-- navbar --> */}

      {/* <MainNavbar /> */}

      {/* <!-- navbar end --> */}
      <div className="partner-sippi-page-04">
        <section id="partner-sippi-front">
          <div className="container">
            <div className="row align-items-center">
              <div className="content py-5">
                <h1>
                  Get your own <br />{" "}
                  <span>
                    Travel Booking <br />
                  </span>
                  <span>Portal</span>{" "}
                </h1>
                <div className="button">
                  <a
                    href="/travelWebsitePrice"
                    className="btn btn-lg btn-outline-primary"
                    // target="_blank"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- travel e-shop lite --> */}
        <section className="section-padding" id="e-shop--lite--feature">
          <div className="container">
            <div className="row align-items-start">
              <div className="col-md-4 col-sm-4 col-12">
                <div className="row item">
                  <div className="col-md-3 col-sm-3 col-3 text-end">
                    <img className="img-fluid" src={flightService} alt="" />
                  </div>
                  <div className="col-md-9 col-sm-9 col-9">
                    <h4>Flights</h4>
                    <p>Pre-integrated APIs and option to add your own API. </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-12">
                <div className="row item">
                  <div className="col-md-3 col-sm-3 col-3 text-end">
                    <img className="img-fluid" src={fdService} alt="" />
                  </div>
                  <div className="col-md-9 col-sm-9 col-9">
                    <h4>Fixed Departure</h4>
                    <p>
                      Sell your own departures along with 3rd party API
                      inventory.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-12">
                <div className="row item">
                  <div className="col-md-3 col-sm-3 col-3 text-end">
                    <img className="img-fluid" src={tourService} alt="" />
                  </div>
                  <div className="col-md-9 col-sm-9 col-9">
                    <h4>Tour Packages</h4>
                    <p>
                      Design itineraries and sell them online along with the
                      third party inventory.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- travel e-shop lite end --> */}

        {/* <!-- whitelable start --> */}
        <section className="section-padding my-5" id="White-lable--features">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-6 col-12 pb-5 px-4 text-center ">
                <img className="img-fluid" src={whitelabelImg} alt="" />
              </div>
              <div className="col-md-5 col-sm-10 col-12 ">
                <h2 className="pb-3">
                  <span>Travel E-Shop</span> Lite
                </h2>
                <p>
                  Travel Portal solution built especially for ordinary travel
                  agents who want to serve their customers online.
                  <br />
                  Affordable pricing, can be managed by a single staff and is
                  easy to use.
                  <br />
                  Pre-integrated APIs of Travel Suppliers. And the option to
                  integrate your own APIs.
                  <br />
                  Complete control over inventory, pricing, markups etc...
                </p>
                {/* <!-- <button type="button" className="login-with-google-btn px-4 mt-3" >
              <img className="img-fluid" src="assets/images/newHome/partner-sippi/google-icon.png" alt=""> Sign in with Google
            </button> --> */}
              </div>
            </div>

            <div className="tech-image mt-5">
              <div className="container-fluid">
                <div className="heading text-center my-5">
                  <h2>
                    Latest <span>Technology</span>
                  </h2>
                  <p>List of Technology used in our software Infrastructure</p>
                </div>
                <div className="row tech-logo">
                  <div className="logo col-sm-2 col-md-2 col-6 text-center">
                    <img className="img-fluid" src={reactLogo} alt="" />
                  </div>
                  <div className="logo col-sm-2 col-md-2 col-6 text-center">
                    <img className="img-fluid" src={nodeLogo} alt="" />
                  </div>
                  <div className="logo col-sm-2 col-md-2 col-6 text-center">
                    <img className="img-fluid" src={mongo} alt="" />
                  </div>
                  <div className="logo col-sm-2 col-md-2 col-6 text-center">
                    <img className="img-fluid" src={mysql} alt="" />
                  </div>
                  <div className="logo col-sm-2 col-md-2 col-6 text-center">
                    <img className="img-fluid" src={typeScript} alt="" />
                  </div>
                  <div className="logo col-sm-2 col-md-2 col-6 text-center">
                    <img className="img-fluid" src={aws} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- whitelable end --> */}

        <section className="section-padding" id="features-points">
          <div className="container">
            <div className="heading text-center  my-3 mb-5">
              <h2>
                <span>Key</span> Features
              </h2>
              <p>
                Based on our experience of working in the travel technology
                industry for many years, we understand the needs of small to
                mid-size travel companies. This product is crafted especially
                for small to mid-size travel companies. Traditional travel
                portal solutions available in the market need multiple staff to
                manage the travel portal. Therefore we have designed a
                solution that can be managed by a single person without any
                technical skills.
              </p>
            </div>

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div className="inclusion-item left-arrow">
                    <div className="arrow-icon">
                      <i className="fas fa-paper-plane"></i>
                    </div>
                    <p>
                      Live Flight Booking and payment collection via payment
                      gateway.
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="inclusion-item right-arrow">
                    <div className="arrow-icon">
                      <i className="fas fa-paper-plane"></i>
                    </div>
                    <p>
                      Sell your own Fix Departure/Pre-purchased Inventory just
                      like live tickets.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="inclusion-item left-arrow">
                    <div className="arrow-icon">
                      <i className="fas fa-paper-plane"></i>
                    </div>
                    <p>
                      Collect online payments and receive money directly in your
                      bank account from pre-integrated payment gateways.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inclusion-item right-arrow">
                    <div className="arrow-icon">
                      <i className="fas fa-paper-plane"></i>
                    </div>
                    <p>
                      Choice of verified and trusted suppliers/DMCs for each
                      destination.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div className="inclusion-item left-arrow">
                    <div className="arrow-icon">
                      <i className="fas fa-paper-plane"></i>
                    </div>
                    <p>
                      Enquiry forms for various travel services like Railway,
                      Cruise, Insurance & Hotels etc...{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="inclusion-item right-arrow">
                    <div className="arrow-icon">
                      <i className="fas fa-paper-plane"></i>
                    </div>
                    <p>
                      Readymade tour packages with B2b and b2c pricing from
                      verified and trusted suppliers.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- tech in use section --> */}
        <section className="section-padding" id="tech-in-use">
          <div className="container my-3">
            <div className="row">
              <h2 className="text-center pb-5">
                <span>Fully Managed</span> Software Solution
              </h2>

              <div className="col-md-2 col-12">
                <div className="content text-center">
                  <div className="img pb-3">
                    <img className="img-fluid" src={dataProtection} alt="" />
                  </div>
                  <div className="points">
                    <h5>High-level Encryption for data protection</h5>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-12">
                <div className="content text-center">
                  <div className="img pb-3">
                    <img
                      className="img-fluid tech-image"
                      src={customDesign}
                      alt=""
                    />
                  </div>
                  <div className="points">
                    <h5>Fully Customisable Design to match your brand</h5>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-12">
                <div className="content text-center">
                  <div className="img pb-3">
                    <img className="img-fluid" src={language} alt="" />
                  </div>
                  <div className="points">
                    <h5>
                      Website Designed in latest and high performance - advanced
                      technology (ReactJs & NodeJs)
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-12">
                <div className="content text-center">
                  <div className="img pb-3">
                    <img className="img-fluid ssl" src={sslProtection} alt="" />
                  </div>
                  <div className="points">
                    <h5>
                      Includes SSL Certificate Protection (Important for website
                      security)
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-12">
                <div className="content text-center">
                  <div className="img pb-3">
                    <img className="img-fluid" src={privateServer} alt="" />
                  </div>
                  <div className="points">
                    <h5>Website hosted on a high-speed private servers</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- tech in use section end --> */}

        {/* <!-- Fixed Departure --> */}

        <section id="fixed-departured" className="my-5 py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h2>
                  Sell <span> Pre-Purchased </span> Series <br /> Fares Online
                </h2>
                <p className="fs-5 text-secondary">
                  Live API results from Top suppliers of India
                </p>
                <div className="button">
                  <a
                    href="https://landarrangement.com"
                    className="btn btn-lg btn-outline-primary"
                    target="_blank"
                  >
                    View Demo
                  </a>
                </div>
              </div>
              <div className="col-lg-6 text-center my-5 ">
                <img className="img-fluid" src={fdMockup} alt="" />
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Fixed Departured End -->/ */}

        {/* <!-- vocal for local --> */}
        <section id="vocal-for-local">
          <div className="container">
            <div className="content">
              <img className="img-fluid" src={vocalForLocal} alt="" />
              <h1>
                <span className="vocal">VOCAL</span>
                <span className="for">FOR</span>
                <span className="local">LOCAL</span>
              </h1>

              <p>
                Sell & Buy Products of local supplier registered on our network
                by simply adding the supplier to your own supplier's Lists.
              </p>
            </div>
          </div>
        </section>
        {/* <!-- vocal for local end --> */}

        {/* <!-- payment --> */}
        <section className="section-padding my-5" id="payment-integration">
          <div className="container">
            <h2 className="pb-5 text-center">
              Pre integrated <span>Payment gateway</span>
            </h2>
            <div className="row text-center ">
              <div className="col-lg-2 col-md-4 col-6">
                <img
                  className="img-fluid img img-responsive w-100"
                  src={easebuzz}
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 ">
                <img
                  className="img-fluid img img-responsive w-100"
                  src={cashFree}
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 ">
                <img
                  className="img-fluid img img-responsive w-100"
                  src={ccAvenue}
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6 ">
                <img
                  className="img-fluid img img-responsive w-100"
                  src={razorPay}
                  alt=""
                />
              </div>
              {/* <div className="col-lg-2 col-md-4 col-6 ">
                <img
                  className="img-fluid img img-responsive w-100"
                  src={icici}
                  alt=""
                />
              </div> */}
              <div className="col-lg-2 col-md-4 col-6 ">
                <img
                  className="img-fluid img img-responsive w-100"
                  src={hdfc}
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6  ">
                <img
                  className="img-fluid img img-responsive w-100"
                  src={paytm}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- payment --> */}

        {/* <!-- sample work --> */}
        <section id="sample-work" className="section-padding">
          <div className="container">
            <div className=" text-center heading mb-5">
              <h2>
                View Our <span> Demo Website</span>
              </h2>
              <div className="see-demo">
                <a
                  href="https://landarrangement.com/"
                  className="btn btn-lg btn-outline-primary mt-3"
                  target="_blank"
                >
                  Click to View
                </a>
              </div>
            </div>

            <div className="sample-image text-center">
              <img className="img-fluid py-5" src={sampleWork} alt="" />
            </div>
          </div>
        </section>
        {/* <!-- sample work end -->/ */}

        {/* <!-- <section id="home--testimonials" className="section-padding">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6 col-md-6 col-12">
        <div className="main-heading what-says-heading">
          <h2>
            What Our <span>Customers</span>
            Says ?
          </h2>
          <p>
            Beautiful website with your branding and details of your
            company.
          </p>
        </div>
      </div>

      <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-12">
        <div className="feed-slide">
          <div className="sliders">
            <div className="wrapper">
              <div className="outer">
                <div className="card" style="--delay: -1">
                  <div className="content">
                    <div className="img">
                      <img src="image/client-img.png" alt="" />
                    </div>
                    <div className="details">
                      <span className="name"
                        >Sumit Kapoor
                        <span style="color: #0e8fd1"
                          >&starf;&starf;&starf;&starf;</span
                        ><span style="color: #c6c6c6">&starf;</span>
                      </span>
                      <h6>Frontend Developer</h6>
                      <hr />
                      <div>
                        <p>
                          Choice of verified and trusted suppliers/DMCs for
                          each destination. You can buy from them directly
                          through the website or buy from any other supplier
                          offline
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card" style="--delay: 0">
                  <div className="content">
                    <div className="img">
                      <img src="image/client-2.png" alt="" />
                    </div>
                    <div className="details">
                      <span className="name"
                        >Andrew Neil
                        <span style="color: #0e8fd1"
                          >&starf;&starf;&starf;</span
                        ><span style="color: #c6c6c6"
                          >&starf;&starf;</span
                        ></span
                      >
                      <h6>YouTuber & Blogger</h6>
                      <hr />
                      <div>
                        <p>
                          Choice of verified and trusted suppliers/DMCs for
                          each destination. You can buy from them directly
                          through the website or buy from any other supplier
                          offline
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card" style="--delay: 1">
                  <div className="content">
                    <div className="img">
                      <img src="image/client-3.png" alt="" />
                    </div>
                    <div className="details">
                      <span className="name"
                        >Jasmine Carter
                        <span style="color: #0e8fd1"
                          >&starf;&starf;&starf;&starf;</span
                        ><span style="color: #c6c6c6">&starf;</span></span
                      >
                      <h6>Freelancer & Vlogger</h6>
                      <hr />
                      <div>
                        <p>
                          Choice of verified and trusted suppliers/DMCs for
                          each destination. You can buy from them directly
                          through the website or buy from any other supplier
                          offline
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card" style="--delay: 2">
                  <div className="content">
                    <div className="img">
                      <img src="image/client-4.png" alt="" />
                    </div>
                    <div className="details">
                      <span className="name"
                        >Justin Chung
                        <span style="color: #0e8fd1"
                          >&starf;&starf;&starf;&starf;&starf;</span
                        ><span style="color: #c6c6c6"></span>
                      </span>
                      <h6>Backend Developer</h6>
                      <hr />
                      <div>
                        <p>
                          Choice of verified and trusted suppliers/DMCs for
                          each destination. You can buy from them directly
                          through the website or buy from any other supplier
                          offline
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card" style="--delay: 2">
                  <div className="content">
                    <div className="img">
                      <img src="image/client-5.png" alt="" />
                    </div>
                    <div className="details">
                      <span className="name"
                        >Adrina Calvo
                        <span style="color: #0e8fd1"
                          >&starf;&starf;&starf;&starf;</span
                        ><span style="color: #c6c6c6">&starf;</span>
                      </span>
                      <h6>Teacher & Advertiser</h6>
                      <hr />
                      <div>
                        <p>
                          Choice of verified and trusted suppliers/DMCs for
                          each destination. You can buy from them directly
                          through the website or buy from any other supplier
                          offline
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> --> */}

        {/* <!-- demo app --> */}
        <section id="travel-app" className="my-5 py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 pt-5">
                <h2>
                  Now <span>Available In App</span>
                </h2>
                <p>
                  This is a demo app of our product Travel E-Shop lite. This is
                  travel booking app from where travel agents can sell
                  travel-related services online. Both customers and travel
                  agents can register on your website or app and make their
                  bookings online.{" "}
                </p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.landarrangement.app"
                  target="_blank"
                >
                  <img
                    className="mt-2 img-fluid button-android"
                    src={androidButton}
                    alt=""
                  />
                </a>
              </div>
              <div className="col-lg-6 text-center">
                <img className="img-fluid pt-5" src={sampleApp} alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- demo app end --> */}

        {/* pricing section */}
        <section className="pricing">
          <div className="container">
            <div className="priceheading">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-6">
                  <h1>
                    <span className="portal">Travel Portal </span> <br />
                    <span className="for-just"> For Just </span>
                    <span className="cutting-price">₹50,000/-</span>
                    <div className="price-div">
                      <span className="actual-price">₹ 20,000/-</span>
                    </div>
                  </h1>
                  <a
                    href="/buyTravelWebsite"
                    className="btn btn-lg btn-outline-primary mt-4 ms-4"
                    target="_blank"
                  >
                    Buy Now
                  </a>
                  <a
                    href="https://calendly.com/sippisoftware"
                    className="btn btn-lg btn-primary mt-4 ms-4"
                    target="_blank"
                  >
                    Get a Demo
                  </a>
                </div>
                <div className="col-md-5 text-start">
                  <img className="img-fluid price-img" src={priceImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* pricing section end */}

        {/* <!-- section footer top --> */}
        <section className="py-5" id="tag-line">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h1>
                  Empowering Travel <br /> Companies Worldwide
                </h1>
              </div>
              <div className="col-md-4">
                <a
                  href="https://calendly.com/sippisoftware"
                  className="btn btn-lg btn-primary mt-3 me-3"
                  target="_blank"
                >
                  Get a Demo
                </a>
                <a
                  href="https://calendly.com/sippisoftware"
                  className="btn btn-lg btn-outline-primary mt-3"
                  target="_blank"
                >
                  Fix an Appointment
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section end --> */}

        {/* <Footer /> */}
      </div>
    </div>
  );
};
