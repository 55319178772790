import { postRequest } from "tools/ajax";

export const getAllErrorLogs = async (data) => {
  const errorLogs = await postRequest("admin/errorLogs/all", data);
  return errorLogs;
};

export const getALlTBOLogs = async (data) => {
  const errorLogs = await postRequest("admin/tboLogs", data);
  return errorLogs;
};

export const getUniqueTBOTraceIds = async (data) => {
  const errorLogs = await postRequest("admin/tboLogs/uniqueTraceIds", data);
  return errorLogs;
};
