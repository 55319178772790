import { Button, Modal } from "react-bootstrap";
import { ApiSettings, IUserApiIntegration } from "./ApiSettings";
import { useEffect, useState } from "react";
import { postRequest } from "tools/ajax";
import { toast } from "react-toastify";

export const EditApiSettingsPopup: React.FC<{
  show: boolean;
  onHide: () => void;
  apiDetails: IUserApiIntegration;
  getApiIntegrations: () => void;
}> = (props) => {
  const [currentApiDetails, setApiCurrentDetails] =
    useState<IUserApiIntegration>(props.apiDetails || undefined);

  const [loading, setLoading] = useState(false);

  const updateApiSetting = async () => {
    if (loading) return;
    if (!props.apiDetails.id) {
      toast.error("Api id not found");
      return;
    }
    setLoading(true);
    const apiRes = await postRequest(
      "user/updateApiSetting",
      currentApiDetails
    );
    if (apiRes?.success) {
      toast.success("Settings updated");
      props.getApiIntegrations();
      props.onHide();
    }
    setLoading(false);
  };

  const handleApiInpChange = (name: string, value: boolean) => {
    setApiCurrentDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  useEffect(() => {
    setApiCurrentDetails(props.apiDetails);
  }, [props.apiDetails]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Change API Settings ({props.apiDetails.apiName})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="alert alert-warning">
          You will be able to update these settings only<b> once in an hour.</b>
        </p>
        <div className="">
          <div className="row small ">
            <div className="col-3 ">
              <div className="form-check form-switch d-flex align-items-center m-0">
                <span>
                  <input
                    onChange={(e) => {
                      handleApiInpChange("isActive", e.target.checked);
                    }}
                    role="button"
                    className="form-check-input"
                    type="checkbox"
                    checked={currentApiDetails?.isActive}
                    id="isActive"
                  />
                </span>
                <span>
                  <label className="form-check-label " htmlFor={`isActive`}>
                    Active
                  </label>
                </span>
              </div>
            </div>
            <div className="col-3">
              <div className="form-check form-switch d-flex align-items-center m-0 ">
                <span>
                  <input
                    onChange={(e) => {
                      handleApiInpChange("isApiSaleAllowed", e.target.checked);
                    }}
                    role="button"
                    className="form-check-input"
                    type="checkbox"
                    checked={currentApiDetails?.isApiSaleAllowed}
                    id="isApiSaleAllowed"
                  />
                </span>
                <span>
                  <label
                    className="form-check-label"
                    htmlFor={`isApiSaleAllowed`}
                  >
                    Api Sale Allowed
                  </label>
                </span>
              </div>
            </div>
            <div className="col-3">
              <div className="form-check form-switch d-flex align-items-center m-0 ">
                <span>
                  <input
                    onChange={(e) => {
                      handleApiInpChange("isB2bSaleAllowed", e.target.checked);
                    }}
                    role="button"
                    className="form-check-input"
                    type="checkbox"
                    checked={currentApiDetails?.isB2bSaleAllowed}
                    id="isB2bSaleAllowed"
                  />
                </span>
                <span>
                  <label
                    className="form-check-label"
                    htmlFor={`isB2bSaleAllowed`}
                  >
                    B2b Sale Allowed
                  </label>
                </span>
              </div>
            </div>
            <div className="col-3">
              <div className="form-check form-switch d-flex align-items-center m-0 ">
                <span>
                  <input
                    onChange={(e) => {
                      handleApiInpChange("isB2cSaleAllowed", e.target.checked);
                    }}
                    role="button"
                    className="form-check-input"
                    type="checkbox"
                    checked={currentApiDetails?.isB2cSaleAllowed}
                    id="isB2cSaleAllowed"
                  />
                </span>
                <span>
                  <label
                    className="form-check-label"
                    htmlFor={`isB2cSaleAllowed`}
                  >
                    B2c Sale Allowed
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <button className="btn btn-success" onClick={updateApiSetting}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};
