import React, { useState, useEffect } from "react";
import { Form, Button } from "semantic-ui-react";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

let schema = yup.object().shape({
  userName: yup.string().required().min(6),
  password: yup.string().required().min(6),
  confirmPassword: yup
    .string()
    .required("Confirm password is a required feild")
    .min(6),
});
export default function NewUserForm(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    userName: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (props.selectedUser) {
      setFormData({
        userName: props.selectedUser.userName,
        password: "",
        confirmPassword: "",
      });
    }
  }, [props.selectedUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    schema.isValid(formData).then((valid) => {
      if (!valid) {
        schema.validate(formData).catch((err) => {
          console.log(err);
          toast.error(err.errors[0]);
        });
      } else {
        const f = formData;
        if (f.password !== f.confirmPassword) {
          return toast.error("password should match confirm password");
        } else if (checkIfUserExists(f.userName)) {
          return toast.error(`user ${f.userName} already exists`);
        } else {
          addUser();
        }
      }
    });

    setIsSubmitting(false);
  };

  const checkIfUserExists = (userName) => {
    axios({
      url: "/api/admin/user/checkIfUserExists",
      method: "GET",
      params: {
        userName,
      },
    }).then((r) => {
      return r.data;
    });
  };
  const addUser = () => {
    try {
      const f = formData;
      axios({
        url: "/api/admin/user/register/",
        method: "POST",
        data: {
          userName: f.userName,
          password: f.password,
        },
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
        },
      })
        .then((r) => {
          if (r.data.err) return toast.error(r.data.err);
          if (props.getUsers) {
            props.getUsers();
            setFormData({
              userName: "",
              password: "",
              confirmPassword: "",
            });

            setIsSubmitting(false);
          }
          if (r.data.msg) toast.error(r.data.msg);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.err || "Could not perform action!");
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((old) => {
      let newObj = Object.assign({}, old);
      newObj[name] = value;
      return newObj;
    });
  };

  return (
    <div className="card">
      <Form onSubmit={handleSubmit} loading={isSubmitting}>
        <Form.Input
          label="Username"
          name="userName"
          value={formData.userName}
          onChange={handleChange}
        />
        <Form.Input
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
        />
        <Form.Input
          label="Confirm Password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          type="password"
        />
        <Button>Submit</Button>
      </Form>
    </div>
  );
}
