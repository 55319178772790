import { SippiAccountsApi } from "apiCalls/SippiAccounts.api";
import { FullScreenLoader } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export const SendEmailConfirmationDialog: React.FC<{
  monthYear: string;
  closeConfirmation: () => void;
}> = (props) => {
  const [loadingState, setLoadingState] = useState(false);

  const handleSendEmail = async (condition: "onlyUnpaid" | null = null) => {
    setLoadingState(true);
    const sendEmails = await SippiAccountsApi.sendEmailsToAllWhitelabels(
      condition,
      props.monthYear
    );
    if (sendEmails.result?.status === "pending") {
      toast.success("Email sent successfully");
    } else if (sendEmails.result?.status === "success") {
      toast.success("Previous Email batch sent successfully");
    } else {
      toast.success("Emails could not be sent. Please try again later");
    }
    props.closeConfirmation();
    setLoadingState(false);
  };

  return (
    <>
      {loadingState && <FullScreenLoader show />}
      <Modal
        show={true}
        onHide={() => {
          props.closeConfirmation();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to send email to all whitelabels ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            className="btn btn-danger"
            onClick={() => handleSendEmail("onlyUnpaid")}
          >
            Send Invoices to those who have not paid
          </Button>
          <Button
            className="btn btn-success"
            onClick={() => handleSendEmail(null)}
          >
            Send Invoices to those who have not received an email yet
          </Button>
          <Button
            className="btn btn-light ms-3"
            onClick={props.closeConfirmation}
          >
            Cancel{" "}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
