import { FullScreenLoader } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "tools/ajax";

interface IBillingSummary {
  id: number;
  siteId: string;
  amountDeducted: string;
  walletBalance: string;
  remark: string;
  sippiCustomerId: string;
  fromDate: string;
  toDate: string;
  createdAt: string;
  updatedAt: string;
  customerDomain: string;
}

export const BillingSummaries: React.FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [billingSummaries, setBillingSummaries] = useState<
    IBillingSummary[] | undefined
  >(undefined);
  useEffect(() => {
    getBillingSummaryByTimeSlot();
  }, []);

  const [pagination, setPagination] = useState<{
    perPage: number;
    recordsToSkip: number;
    page: number;
    totalRecords: number;
    totalPages: number;
  }>({
    perPage: 30,
    recordsToSkip: 0,
    page: 1,
    totalRecords: 0,
    totalPages: 1,
  });

  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);

  // Accessing individual parameters
  const fromDate = queryParams.get("fromDate");
  const toDate = queryParams.get("toDate");

  const getBillingSummaryByTimeSlot = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const billingSummariesRes = await getRequest(
        "billing/getBillingSummaryByTimeSlot",
        { fromDate, toDate, pageNumber }
      );
      if (billingSummariesRes?.result?.billingSummariesWithDomains?.length) {
        setBillingSummaries(
          billingSummariesRes.result?.billingSummariesWithDomains
        );
      }
      if (billingSummariesRes?.result?.pagination) {
        setPagination({
          ...billingSummariesRes.result.pagination,
          page: parseInt(billingSummariesRes.result.pagination.page),
        });
      }
      console.log(billingSummaries);
    } catch (error: any) {
      toast.error(error);
      console.log(error);
    }
    setLoading(false);
  };
  const createBillingSummaryWithDate = async () => {
    setLoading(true);
    try {
      const res = await postRequest("billing/createBillingSummaryWithDate", {
        fromDate,
        toDate,
      });
      if (res?.success) {
        getBillingSummaryByTimeSlot();
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className="container">
      {" "}
      <FullScreenLoader show={loading} />
      <h3>Billing (Per site)</h3>
      <span>
        <b>Date: </b> {moment(fromDate).format("DD MMM YYYY")} <br />
        <b>Time: </b>
        {moment(fromDate).format("hh:mm A")} ~{" "}
        {moment(toDate).format("hh:mm A")}
      </span>
      <div className="row my-2 bg-light p-2 ">
        <div className="col-1">
          <b>index</b>
        </div>
        <div className="col-3">
          <b>Domain</b>
        </div>
        <div className="col-2">
          <b>Amount Deducted</b>
        </div>
        <div className="col-3">
          <b>Last Balance</b>
        </div>
        <div className="col-3">
          <b>Remark</b>
        </div>
      </div>
      {billingSummaries?.length
        ? billingSummaries.map((item, key) => {
            return (
              <Link
                to={`/admin/accounts/billingUsage?siteId=${item?.siteId}&fromDate=${fromDate}&toDate=${toDate}`}
                key={key}
              >
                <div className="row border p-2 mb-2 ">
                  <div className="col-1">
                    {" "}
                    {key + 1 + (pagination.page - 1) * 30}.
                  </div>
                  <div className="col-3">{item?.customerDomain}</div>
                  <div className="col-2">
                    {" "}
                    <b className="text-danger">{item?.amountDeducted}</b>{" "}
                  </div>
                  <div className="col-3">
                    <b>{item?.walletBalance}</b>{" "}
                  </div>
                  <div className="col-3">{item?.remark}</div>
                </div>
              </Link>
            );
          })
        : !loading && (
            <p className="text-center mt-4">
              No Billing was created in this time slot
              <br />
              <button
                className="mt-4 btn btn-success btn-sm"
                onClick={createBillingSummaryWithDate}
              >
                Create Billing
              </button>
            </p>
          )}
      {loading && <p className="text-center">Loading...</p>}
      <div className="p-1 d-flex justify-content-center my-2 ">
        {pagination.page !== 1 && (
          <div
            className="btn btn-outline-dark"
            onClick={() => {
              if (pagination.page > 1) {
                getBillingSummaryByTimeSlot(pagination.page - 1);
              }
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </div>
        )}
        <div className="mx-4 py-2">{`${pagination.page} of ${pagination.totalPages} Pages - ${pagination.totalRecords} Records`}</div>
        {pagination.totalPages > 1 &&
          pagination.page !== pagination.totalPages && (
            <div
              className="btn btn-outline-dark"
              onClick={() => {
                if (pagination.page === pagination.totalPages) {
                  return;
                }
                getBillingSummaryByTimeSlot(pagination.page + 1);
              }}
            >
              <i className="fas fa-chevron-right"></i>
            </div>
          )}
      </div>
    </div>
  );
};
