export interface IpackageSupplierCategory {
  id?: number;
  categoryName: string;
  isVisible: boolean;
  createdBy_sippiStaffId?: string;
  created_at?: string;
  updated_at?: string;
}

export enum SupplierCategoryEnum {
  "FixedDeparture",
  "Flights",
  "TourPackages",
}

export interface Isupplier {
  id?: number;
  mongoDb_siteId: string;
  whitelabel?: any;
  category: SupplierCategoryEnum;
  isVisible: boolean;
  created_at?: Date;
  updated_at?: Date;
}
