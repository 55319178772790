import { postRequest } from "tools/ajax";
import { flightBaseUrl } from "tools/routeConstants";

class FlightApi {
  static tboGetAllBookings = async () => {
    return await postRequest(`${flightBaseUrl}/tboGetAllBookings`, {});
  };
  static tboGetPNRBookings = async (pnrArray) => {
    return await postRequest(`${flightBaseUrl}/tboGetPNRBookings`, {
      pnrArray,
    });
  };

  getAllAirlines = async () => {
    const postRequestResponse = await postRequest(
      `${flightBaseUrl}/getAllAirlines`,
      {}
    );
    return postRequestResponse;
  };

  static searchAirports = async (airportCode) => {
    const postRequestResponse = await postRequest(
      `${flightBaseUrl}/searchAirports`,
      { airportCode }
    );
    return postRequestResponse;
  };
}

export default FlightApi;
