import React from "react";
import { useSelector } from "react-redux";
import List from "./List";

export default function Index() {
  const smtpDetails = useSelector(
    (state: any) => state.siteSettings.smtpDetails
  );

  return (
    <>
      <p className="h4">SMTP List</p>
      {<List smtpDetails={smtpDetails} />}
    </>
  );
}
