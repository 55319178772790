import React from 'react'
import { Container, makeStyles, Grid, Paper } from '@material-ui/core'
import CountriesList from 'components/admin/visa/countriesList'
import CreateVisaCategory from 'components/admin/visa/createVisaCategory'
import VisaCategoryList from 'components/admin/visa/visaCategoryList'
import Checklist from 'components/admin/visa/checklist'

const useStyles = makeStyles(theme => {
    return(
        {
            pGrid: {
                padding: theme.spacing(2)
            }
        }
    )
        
    })

export default function Visa() {

    const classes = useStyles()

    return (
        <>
        <Container>
        <Grid container spacing={2}>
                    <Grid item md={4} align="center">
                        <Paper className={classes.pGrid}>
                            <CountriesList/>
            
                            <CreateVisaCategory/>
                        
                            <VisaCategoryList/>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper className={classes.pGrid}>
                            <Checklist/>
                        </Paper>
                    </Grid>
                </Grid>
        </Container>
        </>
    )
}