import React, { useState, useEffect } from "react";
import * as yup from "yup";
import axios from "axios";

import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import JsonView from "react-json-view";
import { editWhitelabelSchema } from "commonFnBcode/types/whitelabel.types";
import { postRequest } from "tools/ajax";
export default function EditWhiteLabel(props) {
  const defaultFormState = {
    _id: "",
    siteName: "",
    adminEmail: "",
    adminContactPhoneCode: "+91",
    adminContact: "",
    whatsAppNumber: "",
  };

  const [form, setForm] = useState<{
    _id?: string;
    siteName: string;
    adminEmail: string;
    adminContactPhoneCode: string;
    adminContact: string;
    whatsAppNumber: string;
  }>(defaultFormState);

  const [wlData, setWlData] = useState<any>({});

  useEffect(() => {
    if (props.match.params._id) {
      axios({
        url: "/api/admin/whitelabel/find_unsafe",
        method: "POST",
        data: { _id: props.match.params._id },
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
        },
      }).then((r) => {
        const originalWlData = r.data[0];
        setWlData(originalWlData);
        setForm((old) => ({ ...old, ...originalWlData }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = yup.object().shape({
    siteName: yup.string().required().label("Site Name"),
    adminEmail: yup.string().email().required().label("Admin Email"),
    adminContactPhoneCode: yup.string().required().label("Admin Phone Code"),
    adminContact: yup.string().required().label("Admin Contact Number"),
    whatsAppNumber: yup.string().required().label("Whats app Number"),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      let newform = { ...old };
      newform[name] = value;
      return newform;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid = await editWhitelabelSchema.safeParse(form);
    if (!valid.success) {
      const zodError = valid.error.format();
      for (let [key, value] of Object.entries(zodError)) {
        if (key === "_errors") continue;
        const val: any = value;
        if (val._errors.length) {
          toast.error(`${key} ${val._errors[0]}`);
        }
      }
      // schema
      //   .validate(form)
      //   .then((val) => {
      //     console.log(val);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     toast.error(err.errors[0]);
      //   });
    } else {
      if (form._id) editWhitelabel();
      else toast.error("Wl id not found... Contact Support!");
      // else saveWhitelabel();
    }
  };

  const printinput = (name, placeHolder, type = "text") => {
    return (
      <Form.Group>
        <b>{placeHolder}</b>
        <Form.Control
          className="my-2"
          name={name}
          onChange={handleChange}
          value={form[name]}
          type={type}
        />
      </Form.Group>
    );
  };

  // const saveWhitelabel = () => {
  //   let Form = form;
  //   delete Form._id;
  //   axios({
  //     url: "/api/admin/whitelabel/create",
  //     method: "POST",
  //     data: Form,
  //   }).then((r) => {
  //     if (r.data._id) {
  //       toast.error("success!");
  //       resetForm();
  //     } else {
  //       toast.error("error! could not save white label...");
  //       console.log(r.data);
  //     }
  //   });
  // };

  const editWhitelabel = async () => {
    const apiRes = await postRequest(
      "admin/whitelabel/updateWhiteLabelDetails",
      form
    );
    if (apiRes?.success) {
      toast.success("changes saved!");
    }
    // axios({
    //   url: "/api/admin/whitelabel/updateWhiteLabelDetails",
    //   method: "PATCH",
    //   data: form,
    // }).then((r) => {
    //   console.log(r.data);
    //   toast.success("changes saved!");
    // });
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6 card">
          <h2 className="h4">Edit White Label</h2>

          <form onSubmit={handleSubmit}>
            {printinput("siteName", "Site Name")}
            {printinput("adminEmail", "Admin Email")}
            {printinput(
              "adminContactPhoneCode",
              "Admin Contact Phone Code",
              "text"
            )}
            {printinput("adminContact", "Admin Contact")}
            {printinput("whatsAppNumber", "Whatsapp Number")}

            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </form>
        </div>
      </div>
      <div>
        <JsonView
          src={wlData}
          theme={"monokai"}
          collapseStringsAfterLength={40}
          collapsed={1}
        />
      </div>
    </div>
  );
}
