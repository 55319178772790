/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";

import Autocomplete from "commonUi/autoComplete/index";
import { saleColumns, col } from "./saleColumns";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import {
  getWhitelabelsWithSippiCustomerData,
  updateActualPaymentStatusForWhitelabel,
} from "redux/actions/accounts.actions";
import AmountSummary from "./AmountSummary";
import { toast } from "react-toastify";
import EditActualAmount from "./EditActualAmount";

export function SetupFeeReport() {
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);
  const [actualTotalAmount, setActualTotalAmount] = useState(0);

  useEffect(() => {
    dispatch(getWhitelabelsWithSippiCustomerData());
  }, []);

  const rows = useSelector(
    (state: any) => state.accounts.whitelabelsWithSippiCustomerData
  );

  useEffect(() => {
    let total = 0;
    let actualTotal = 0;
    for (let i = 0; i <= rows.length; i++) {
      const amount = parseFloat(
        rows[i]?.sippiCustomerId?.purchases?.form?.amountPayable
      );
      if (amount) total = total + amount;
      if (
        rows[i]?.accountsEntryId?.actualPaymentStatus ===
        actualPaymentStatusOptions[0]
      ) {
        const actualAmount = parseFloat(
          rows[i]?.accountsEntryId?.actualPaymentAmount
        );
        if (actualAmount) actualTotal = actualTotal + actualAmount;
        else if (amount) actualTotal = actualTotal + amount;
      }
    }
    console.log({ total, actualTotal });
    setTotalAmount(total);
    setActualTotalAmount(actualTotal);
  }, [rows]);

  const actualPaymentStatusOptions = [
    "Paid",
    "Demo/Dummy Website",
    "Free Website",
    "Purchased & Refunded",
  ];

  const printTableRow = (cells: any, rowIndex: any) => {
    if (saleColumns.length !== cells.length) {
      toast.error("table header and row length mismatch");
      console.log({
        header: saleColumns.length,
        rows: cells.length,
      });
    }
    return (
      <tr key={rowIndex}>
        {cells.map((cell, index) => (
          <td key={index} style={cell.style}>
            <>{cell.cellValue ? cell.cellValue : col(cell).cellValue}</>
          </td>
        ))}
      </tr>
    );
  };

  const printError = (msg) => (
    <span style={{ color: "red", fontWeight: "bolder" }}>{msg}</span>
  );

  const printPurchaseError = (row) => {
    const sippiCust = row.sippiCustomerId;
    if (!sippiCust) return printError("Sippi Customer Missing");
    if (!sippiCust.purchases)
      return printError("Sippi Customer Exist but Purchase Data Missing");
    if (!sippiCust.purchases.planName) return printError("Plan Name Missing");
  };

  const printPlanName = (row) => {
    const error = printPurchaseError(row);
    if (error) return error;
    return row.sippiCustomerId.purchases.planName;
  };

  const printDbPaymentStatus = (row) => {
    const error = printPurchaseError(row);
    if (error) return error;
    if (row.sippiCustomerId.purchases.isPaid) return "Paid in DB";
    else {
      return "Not Paid";
    }
  };

  const printFormError = (row) => {
    const purchaseError = printPurchaseError(row);
    if (purchaseError) return purchaseError;
    const purchases = row.sippiCustomerId.purchases;
    if (!purchases.form) return "Form Data is Missing";
  };

  const printAmount = (row) => {
    const error = printFormError(row);
    if (error) return printError(error);
    const purchases = row.sippiCustomerId.purchases;
    return purchases.form.amountPayable;
  };

  const printGstNumber = (row) => {
    const error = printFormError(row);
    if (error) return printError(error);
    const gst = row.sippiCustomerId.purchases.form.gst;
    if (!gst) return printError("GST Number Missing");
    return gst;
  };

  const printState = (row) => {
    const error = printFormError(row);
    if (error) return printError(error);
    const State = row.sippiCustomerId.purchases.form.state;
    if (!State) return printError("state Missing");
    return State;
  };

  const onChangeActualPaymentStatus = (val, row) => {
    dispatch(
      updateActualPaymentStatusForWhitelabel({
        paymentStatus: val,
        siteId: row._id,
      })
    );
  };

  const printActualPaymentStatus = (row) => {
    return (
      <>
        <Autocomplete
          // value={row?.accountsEntryId?.actualPaymentStatus}
          // inputValue={row?.actualPaymentStatus?.actualPaymentStatus}
          label="Actual Pament Status"
          options={actualPaymentStatusOptions}
          onChange={(e, val) => onChangeActualPaymentStatus(val, row)}
          getOptionLabel={(option) => option}
        />
      </>
    );
  };

  const printCreationDate = (row) => {
    if (!row.creationTime) return printError("Creation Time is missing!");
    const date = moment(row.creationTime).format("DD-MM-YYYY");
    return col(date, {
      whiteSpace: "nowrap",
    });
  };

  const printActualAmountPaid = (row) => {
    let amount;
    if (row?.accountsEntryId) {
      if (
        row.accountsEntryId?.actualPaymentStatus ===
        actualPaymentStatusOptions[0]
      ) {
        if (!row.accountsEntryId?.actualPaymentAmount) {
          amount = printAmount(row);
          //if amount is present then it is stored as string
          //so we convert the string to float
          // but if amount is missing then we get error of type object
          //then we make the amount 0
          if (typeof amount === "string") amount = parseFloat(amount);
          if (typeof amount === "object") amount = 0;
        } else {
          amount = row.accountsEntryId?.actualPaymentAmount;
        }

        return col(
          <EditActualAmount
            siteId={row._id}
            actualPaymentStatus={row?.accountsEntryId?.actualPaymentStatus}
            amount={amount}
          />,
          { minWidth: "200px" }
        );
      } else {
        amount = 0;
      }
    } else {
      amount = 0;
    }
    return "Payment Status is not 'Paid'";
  };

  return (
    <>
      <div className="p-4 m-4">
        <p className="h5">Sales Report</p>
        <p>{rows.length} Records</p>

        <AmountSummary
          totalAmount={totalAmount}
          actualTotalAmount={actualTotalAmount}
        />

        <div className="table-responsive shadow m-2">
          <table className="table table-striped">
            <thead>{printTableRow(saleColumns, "rowindex")}</thead>
            <tbody>
              {rows?.map((row, index) =>
                printTableRow(
                  [
                    printCreationDate(row),
                    { cellValue: row.domain, styles: saleColumns[0]?.style },
                    { cellValue: row.companyName },
                    printPlanName(row),
                    printDbPaymentStatus(row),
                    printAmount(row),
                    printGstNumber(row),
                    printState(row),
                    printActualPaymentStatus(row),
                    printActualAmountPaid(row),
                  ],
                  index
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
