// IMPORTANT COMMENTS DONOT DELETE BELOW COMMENTS
// fare.OtherCharges (TBO) = Excel(Sum("Service Charges", "Total GST"));
// invoice.InvoiceAmount (TBO) = Excel(Round("TicketSaleAmount"));

import React, { useEffect, useRef, useState } from "react";
import papaParse from "papaparse";
import { InputGroup, Spinner } from "react-bootstrap";
import _ from "lodash";
import FlightApi from "apiCalls/FlightApi";
import moment from "moment";

// const TBO_CSV_KEYS = [
//   "DATE",
//   "BOOKING MODE",
//   "FARE", // Fare.BaseFare
//   "TAX", // Fare.Tax
//   "Special Ser Chrgs", // Fare.TotalSpecialServiceCharges
//   "PAX NAME", // Passenger.Title + Passenger.FirstName + Passenger.LastName
//   "GROSS", // Fare.PublishedFare
//   "H.CHRG(commission  - Rev H.Charge)", // Passenger.CommissionEarned
//   "PLBAmount", //Fare.PLBEarned
//   "IncentiveAmount", //Fare.IncentiveEarned
//   "Service Charges", //Fare.ServiceFee
//   "TDS", //Fare.TdsOnCommission
//   "SGST Amt", // Import these fields to our db
//   "CGST Amt", // Import these fields to our db
//   "IGST Amt", // Import these fields to our db
//   "Total GST", // Compare this field  with (Fare.)
//   "NET", // sHOULD MATCH Offer pRICE
//   "TRA Fee",
//   "TicketSaleAmount", // Invoice Amount
//   "PNR",
//   "IsAmendment", // if true , then fields will not match
// ];

interface ITBOFare {
  currency: string;
  baseFare: number;
  tax: number;
  taxBreakup: any[];
  yqTax: number;
  additionalTxnFeeOfrd: number;
  additionalTxnFeePub: number;
  pgCharge: number;
  otherCharges: number;
  chargeBu: any[];
  discount: number;
  publishedFare: number;
  commissionEarned: number;
  plbEarned: number;
  incentiveEarned: number;
  offeredFare: number;
  tdsOnCommission: number;
  tdsOnPlb: number;
  tdsOnIncentive: number;
  serviceFee: number;
  totalBaggageCharges: number;
  totalMealCharges: number;
  totalSeatCharges: number;
  totalSpecialServiceCharges: number;
}

export const CompareTboExcel = () => {
  // const { tboState, setTboState } = useState<any>();

  const [tboCSVState, setTboCSVState] = useState<any>();

  const [paxBookings, setPaxBookings] = useState<
    Array<{
      documentDetails: null;
      paxId: number;
      title: string;
      firstName: string;
      lastName: string;
      paxType: number;
      dateOfBirth?: Date;
      gender: number;
      isPanRequired: boolean;
      isPassportRequired: boolean;
      pan: string;
      passportNo: string;
      addressLine1: string;
      addressLine2?: string;
      fare: ITBOFare;
      city: string;
      countryCode: string;
      countryName?: string;
      nationality: string;
      contactNo: string;
      email: string;
      isLeadPax: boolean;
      ffAirlineCode: null;
      ffNumber: null;
      pnr: string;
      passportExpiry?: Date;
      createdAt: Date;
      gstCompanyAddress?: string;
      gstCompanyContactNumber?: string;
      gstCompanyEmail?: string;
      gstCompanyName?: string;
      gstNumber?: string;
    }>
  >();
  // const [PNRBookings, setPNRBookings] = useState<Array<any>>();

  // const [filterState, setFilterState] = useState<{
  //   startDate: Date;
  //   endDate: Date;
  // }>({
  //   startDate: new Date(),
  //   endDate: new Date(),
  // });

  // const filterDate = {
  //   startDate: new Date(),
  //   endDate: new Date(),
  // };

  const [excelToJsonState, setExcelToJsonState] = useState<
    Array<{
      DATE: string;
      "BOOKING MODE": string;
      FARE: string;
      TAX: string;
      "Special Ser Chrgs": string;
      "PAX NAME": string;
      GROSS: string;
      "H.CHRG(commission  - Rev H.Charge)": string;
      PLBAmount: string;
      IncentiveAmount: string;
      "Service Charges": string;
      TDS: string;
      "SGST Amt": string;
      "CGST Amt": string;
      "IGST Amt": string;
      " Total GST": string;
      NET: string;
      "TRA Fee": string;
      TicketSaleAmount: string;
      PNR: string;
      IsAmendment: string;
    }>
  >();

  const getAllBookings = async (pnr) => {
    const bookings = await FlightApi.tboGetPNRBookings(pnr);
    const paxFares: Array<any> = [];
    // setPNRBookings(bookings);
    bookings.forEach((book) => {
      book.passenger.forEach((bookPax) => {
        paxFares.push({
          ...bookPax,
          pnr: book.pnr,
          createdAt: book.createdAt,
        });
      });
    });
    setPaxBookings(
      paxFares.map((pax) => ({
        ...pax,
        fare: {
          ...pax.fare,
          publishedFare: pax.fare.publishedFare - pax.fare.otherCharges,
        },
      }))
    );
  };

  useEffect(() => {
    const allPnrs = excelToJsonState?.map((item) => item.PNR);

    if (allPnrs && allPnrs.length) {
      const uniquePnrs = _.uniq(allPnrs);

      getAllBookings(uniquePnrs);
    }
  }, [excelToJsonState]);

  useEffect(() => {
    if (tboCSVState) {
      importCSV();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tboCSVState]);

  const inputRef = useRef<any>();

  const importCSV = () => {
    const { csvfile } = tboCSVState;
    papaParse.parse(csvfile, {
      complete: updateData,
      header: true,
    });
  };

  const handleChange = (event) => {
    setTboCSVState({
      csvfile: event.target.files[0],
    });
  };

  const updateData = (result) => {
    var data = result.data;
    setExcelToJsonState(data);
  };

  return (
    <div className="container-fluid">
      <InputGroup className="mb-3">
        <input
          type="file"
          className="form-control"
          ref={(input) => {
            inputRef.current = input;
          }}
          name="file"
          placeholder="Choose a csv file"
          onChange={handleChange}
        />
        {/* <button className="btn btn-primary" onClick={importCSV}>
          Import
        </button> */}
      </InputGroup>

      <h3>Compare TBO Excel</h3>

      {tboCSVState && (
        <>
          {excelToJsonState?.length !== paxBookings?.length && (
            <p className="text-danger">PNR missing</p>
          )}
          {excelToJsonState && paxBookings ? (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Supplier</th>
                    <th>Date</th>
                    <th>BOOKING MODE</th>
                    <th>FARE</th>
                    <th>TAXES & FEES</th>
                    <th>Special Ser Chrgs</th>
                    <th>PAX NAME</th>
                    <th>GROSS</th>
                    <th>Commission Earned</th>
                    <th>PLBAmount</th>
                    <th>IncentiveAmount</th>
                    <th>Service Charges</th>
                    <th>TDS</th>
                    <th>SGST Amt</th>
                    <th>CGST Amt</th>
                    <th>IGST Amt</th>
                    <th>Total GST</th>
                    <th>NET</th>
                    <th>TRA Fee</th>
                    <th>TicketSaleAmount</th>
                    <th>PNR</th>
                    <th>IsAmendment</th>
                  </tr>
                </thead>
                <tbody>
                  {excelToJsonState.map((row, index) => {
                    const savedBooking = paxBookings.find(
                      (booking) =>
                        booking.pnr === row.PNR &&
                        row["PAX NAME"].replace(/\s+/g, " ").trim() ===
                          `${booking.title} ${booking.firstName} ${booking.lastName}`
                    );

                    const savedPaxName = `${savedBooking?.title} ${savedBooking?.firstName} ${savedBooking?.lastName}`;
                    const totalTds =
                      parseFloat(
                        savedBooking?.fare?.tdsOnCommission.toString() || "0"
                      ) +
                      parseFloat(
                        savedBooking?.fare?.tdsOnIncentive.toString() || "0"
                      ) +
                      parseFloat(
                        savedBooking?.fare?.tdsOnPlb.toString() || "0"
                      );

                    const savedNet = (
                      (savedBooking?.fare?.offeredFare || 0) + totalTds
                    ).toFixed(2);

                    const savedTaxesAndFee = (
                      (savedBooking?.fare?.tax || 0) +
                      (savedBooking?.fare?.totalBaggageCharges || 0) +
                      (savedBooking?.fare?.totalMealCharges || 0) +
                      (savedBooking?.fare?.totalSeatCharges || 0) +
                      (savedBooking?.fare?.totalSpecialServiceCharges || 0)
                    ).toFixed(2);

                    // let isBaseFareSame = false;

                    // if(row.FARE === )

                    return (
                      <>
                        <tr
                          key={index}
                          className={!savedBooking ? "table-danger" : ""}
                        >
                          <td>TBO</td>
                          <td>{row["DATE"]}</td>
                          <td>{row["BOOKING MODE"]}</td>
                          <td>{row.FARE}</td>
                          <td>{row.TAX}</td>
                          <td>{row["Special Ser Chrgs"]}</td>
                          <td>{row["PAX NAME"]}</td>
                          <td>{row.GROSS}</td>
                          <td>{row["H.CHRG(commission  - Rev H.Charge)"]}</td>
                          <td>{row.PLBAmount}</td>
                          <td>{row.IncentiveAmount}</td>
                          <td>{row["Service Charges"]}</td>
                          <td>{row.TDS}</td>
                          <td>{row["SGST Amt"]}</td>
                          <td>{row["CGST Amt"]}</td>
                          <td>{row["IGST Amt"]}</td>
                          <td>{row[" Total GST"]}</td>
                          <td>{parseFloat(row["NET"]).toFixed(2)}</td>
                          <td>{row["TRA Fee"]}</td>
                          <td>
                            {parseFloat(row["TicketSaleAmount"]).toFixed(2)}
                          </td>
                          <td>{row.PNR}</td>
                          <td>{row.IsAmendment}</td>
                        </tr>
                        <tr
                          key={index}
                          style={{
                            opacity: 0.75,
                            borderBottom: "10px solid #c5c5c5",
                          }}
                          className={!savedBooking ? "table-danger" : ""}
                        >
                          <td>Ours</td>
                          <td>
                            {moment(savedBooking?.createdAt).format("ll")}
                          </td>
                          <td></td>
                          <td
                            className={
                              savedBooking?.fare?.baseFare.toFixed(2) !==
                              parseFloat(row["FARE"]).toFixed(2)
                                ? "table-danger"
                                : "table-success"
                            }
                          >
                            {savedBooking?.fare?.baseFare}
                          </td>
                          <td
                            className={
                              savedTaxesAndFee.toString() !==
                              parseFloat(row["TAX"]).toFixed(2)
                                ? "table-danger"
                                : "table-success"
                            }
                          >
                            {savedTaxesAndFee}
                          </td>
                          <td>
                            {savedBooking?.fare?.totalSpecialServiceCharges}
                          </td>
                          <td>{savedPaxName}</td>
                          <td
                            className={
                              (savedBooking?.fare?.publishedFare || 0).toFixed(
                                2
                              ) !== parseFloat(row["GROSS"]).toFixed(2)
                                ? "table-danger"
                                : "table-success"
                            }
                          >
                            {savedBooking?.fare?.publishedFare}
                          </td>
                          <td
                            className={
                              savedBooking?.fare?.commissionEarned.toFixed(
                                2
                              ) !==
                              parseFloat(
                                row["H.CHRG(commission  - Rev H.Charge)"]
                              ).toFixed(2)
                                ? "table-danger"
                                : "table-success"
                            }
                          >
                            {savedBooking?.fare?.commissionEarned}
                          </td>
                          <td>{savedBooking?.fare?.plbEarned}</td>
                          <td>{savedBooking?.fare?.incentiveEarned}</td>
                          <td
                            className={
                              savedBooking?.fare?.otherCharges.toFixed(2) !==
                              parseFloat(row["Service Charges"]).toFixed(2)
                                ? "table-danger"
                                : "table-success"
                            }
                          >
                            {savedBooking?.fare?.serviceFee}
                          </td>
                          <td
                            className={
                              totalTds.toFixed(2) !==
                              parseFloat(row["TDS"]).toFixed(2)
                                ? "table-danger"
                                : "table-success"
                            }
                          >
                            {totalTds}
                          </td>
                          <td>{}</td>
                          <td>{}</td>
                          <td>{}</td>
                          <td>{}</td>
                          <td
                            className={
                              savedNet.toString() !==
                              parseFloat(row["NET"]).toFixed(2)
                                ? "table-danger"
                                : "table-success"
                            }
                          >
                            {savedNet}
                          </td>
                          <td>{savedBooking?.fare?.additionalTxnFeeOfrd}</td>
                          <td
                            className={
                              savedNet.toString() !==
                              parseFloat(row["TicketSaleAmount"]).toFixed(2)
                                ? "table-danger"
                                : "table-success"
                            }
                          >
                            {savedNet}
                          </td>
                          <td>{savedBooking?.pnr}</td>
                          <td></td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <Spinner animation="border" />
          )}
        </>
      )}
    </div>
  );
};
