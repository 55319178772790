import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List, ListItem, makeStyles, Button, Typography } from '@material-ui/core'
import { HtmlNationalityDialog } from 'components/admin/visa/addHtmlWithNationality'
import { SET_NATIONALITY, SET_SELECTED_VISA_CHECKLIST } from 'redux/actions/visaActions'

const useStyles = makeStyles((theme) => ({
    style: {
            marginTop: "10px",
            display: 'flex',
            alignItems: 'center', 
            padding: theme.spacing(2),
            justifyContent: 'center',
    },

    buttonStyle : {
        display:'flex',
        direction: 'column',
        alignItems: 'center',
        marginBottom: '10px',
        justifyContent: 'center',
        alignSelf: 'center',
        
    },
    flexStyle : {
        justifyContent: 'space-between',
    }

}))

export default function CheckList() {
    const dispatch = useDispatch()
    const selectedVisaChecklistRedux = useSelector((state) => state.visaChecklist.visaChecklists)
    const selectedIndexFromVisaCategoryRedux = useSelector((state) => state.visaChecklist.selectedVisaCategory)
    const classes = useStyles()
    const [ dailogOpen, setDailogOpen ] = useState(false)

    const toggleDailog = () => {
        setDailogOpen(!dailogOpen)
    }

    return (
        <> 
                { selectedIndexFromVisaCategoryRedux._id ?
                    <div>
                        
                            <Typography variant='h5' noWrap={true}> 
                               Selected Visa: {selectedIndexFromVisaCategoryRedux.visaCategory}
                            </Typography>
                            <br/>
                        <div className={classes.buttonStyle}>
                            <Button variant='contained' 
                                color='primary' 
                                onClick={() => {toggleDailog();
                                dispatch(SET_NATIONALITY([]));
                                dispatch(SET_SELECTED_VISA_CHECKLIST([]));
                            }}
                            >
                                + Add New
                            </Button>
                        </div>
                    <div className={classes.style}> 
                            <List >
                                    <ListItem divider={true}><b>
                                        {selectedVisaChecklistRedux.length ?
                                            `Nationality CheckList` : `no nationality added yet`
                                        }</b>
                                    </ListItem>
                                {selectedVisaChecklistRedux?.map((t,i) =>
                                <React.Fragment key={i}>
                                    <ListItem divider={true} key={i} className={classes.flexStyle}>
                                        <Typography>
                                            {t.nationality_countryId.countryName}
                                        </Typography>                                     
                                        <Button color="primary" variant="contained" key={i}
                                                style={{marginLeft:" 10px"}}
                                                onClick={() => {
                                                    dispatch(SET_SELECTED_VISA_CHECKLIST(t));
                                                    dispatch(SET_NATIONALITY(t.nationality_countryId))
                                                    toggleDailog();
                                                }}
                                        >
                                                Edit
                                        </Button>
                                    </ListItem>
                                </React.Fragment>
                                )}
                            </List>
                </div>
                </div>
                : 'No Visa Category Selected Yet'}
                            
                <HtmlNationalityDialog 
                    open={dailogOpen} 
                    close={toggleDailog}
                />
            
        
            
        </>
    )
}