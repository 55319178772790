import {takeEvery,put} from 'redux-saga/effects'
import {commonTypes} from 'redux/actionTypes/common.types'

function* setAlertMessage(action){
    yield put({
        type: commonTypes.SHOW_ALERT_MESSAGE,
        payload: {message: action.payload, show: true}
    })
}

export function* watchCommonActions () {
    yield takeEvery(commonTypes.SET_ALERT_MESSAGE,setAlertMessage)
}