import {
  getAllPaymentGatewaysChargesApiCall,
  saveSelectedPaymentGatewayApiCall,
} from "apiCalls/siteSettingsDb";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
// import Button from "@material-ui/core/Button";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { selectUpdatedCount } from "redux/slices/paymentGatewaySlice";
import { useAppSelector } from "hooks/reduxHooks";

export enum PaymentModeEnum {
  CreditCard = "CreditCard",
  DebitCard = "DebitCard",
  Upi = "Upi",
  NetBanking = "NetBanking",
  Wallet = "Wallet",
  EMI = "EMI",
  Payin3Emis = "Payin3Emis",
  PayLater = "PayLater",
  Paypal = "Paypal",
}

export const SelectPaymentGatewayTable: React.FC<{
  setLoading: (loading: boolean) => void;
}> = (props) => {
  const [PGTableState, setPGTableState] = useState<{
    [x: string]: Array<{
      paymentGateway: string;
      paymentMode: PaymentModeEnum;
      fixed: string;
      percentage: string;
      is_selected: boolean;
    }>;
  }>();
  const updatedPaymentCharges = useAppSelector(selectUpdatedCount);
  //   const [PGTableState, setPGTableState] = useState<
  //     Array<{
  //       paymentGateway: string;
  //       paymentMode: string;
  //       fixed: string;
  //       percentage: string;
  //       is_selected: boolean;
  //     }>
  //   >();

  useEffect(() => {
    getSelectedPaymentGateways();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPaymentCharges.updated]);

  const getSelectedPaymentGateways = async () => {
    props.setLoading(true);
    const paymentGatewayTable = await getAllPaymentGatewaysChargesApiCall();

    if (paymentGatewayTable.success) {
      const groupedPG: any = _.mapValues(
        _.groupBy(paymentGatewayTable.result, "paymentGateway"),
        (pgList) => pgList.map((pg) => _.omit(pg, "paymentGateway"))
      );
      setPGTableState(groupedPG);
      //   setPGTableState(paymentGatewayTable.result);
    }
    props.setLoading(false);
  };

  const handleSelectChange = (e, paymentGateway, paymentMode) => {
    const newPGTable = { ...PGTableState };
    if (newPGTable) {
      Object.keys(newPGTable).forEach((pgKey) => {
        newPGTable[pgKey] = newPGTable[pgKey].map((pg) => {
          if (pg.paymentMode === paymentMode) {
            if (pgKey !== paymentGateway) {
              pg.is_selected = false;
            } else {
              pg.is_selected = e.target.checked;
            }
          }
          return pg;
        });
      });

      setPGTableState(newPGTable);
    }
  };

  const handleSave = async () => {
    props.setLoading(true);
    const pgTable = { ...PGTableState };

    const pgTableForm: Array<any> = [];
    Object.values(pgTable).forEach((paymentGatewayCharges) => {
      pgTableForm.push(...paymentGatewayCharges);
    });
    const saveResponse = await saveSelectedPaymentGatewayApiCall({
      selectedPaymentGateways: pgTableForm,
    });
    if (saveResponse.success) {
      toast.success("Payment gateway selection successfully set.");
    } else {
      toast.error("Invalid selection.");
    }

    props.setLoading(false);
  };

  return (
    <>
      <div className="default-payment-gateway-pss mb-5">
        <h3>Select Default Payment Gateway</h3>
        {/* <pre>{JSON.stringify(PGTableState, null, 2)}</pre> */}
        <table className="table table-responsive table-bordered table-striped">
          <tbody>
            <tr>
              <td></td>
              <td>CreditCard</td>
              <td>DebitCard</td>
              <td>NetBanking</td>
              <td>Upi</td>
              <td>Wallet</td>
              <td>Pay in EMI</td>
              <td>Pay in 3 EMIs</td>
              <td>Pay later</td>
              <td>Paypal</td>
            </tr>
            {PGTableState &&
              Object.keys(PGTableState)?.map((pg, index) => {
                const getCharges = (paymentMode) => {
                  return PGTableState[pg].find(
                    (pgCharge) => pgCharge.paymentMode === paymentMode
                  );
                };

                const ccCharges = getCharges("CreditCard");
                const dcCharges = getCharges("DebitCard");
                const upiCharges = getCharges("Upi");
                const netBankingCharges = getCharges("NetBanking");
                const walletCharges = getCharges("Wallet");
                const emiCharges = getCharges(PaymentModeEnum.EMI);
                const payInThreeEmiCharges = getCharges(
                  PaymentModeEnum.Payin3Emis
                );
                const paylaterCharges = getCharges(PaymentModeEnum.PayLater);
                const paypalCharges = getCharges(PaymentModeEnum.Paypal);
                if (pg === "HDFC_CCAVENUE" || pg === "ICICI") {
                  return null;
                }
                return (
                  <tr key={index}>
                    <th scope="row">{pg}</th>
                    <td>
                      Rs.{ccCharges?.fixed || 0} + {ccCharges?.percentage || 0}%
                      <Checkbox
                        size="small"
                        checked={ccCharges?.is_selected}
                        onChange={(e) =>
                          handleSelectChange(e, pg, "CreditCard")
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </td>
                    <td>
                      Rs.{dcCharges?.fixed || 0} + {dcCharges?.percentage || 0}%
                      <Checkbox
                        size="small"
                        checked={dcCharges?.is_selected}
                        onChange={(e) => handleSelectChange(e, pg, "DebitCard")}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </td>
                    <td>
                      Rs.{netBankingCharges?.fixed || 0} +{" "}
                      {netBankingCharges?.percentage || 0}%
                      <Checkbox
                        size="small"
                        checked={netBankingCharges?.is_selected}
                        onChange={(e) =>
                          handleSelectChange(e, pg, "NetBanking")
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </td>
                    <td>
                      Rs.{upiCharges?.fixed || 0} +{" "}
                      {upiCharges?.percentage || 0}%
                      <Checkbox
                        size="small"
                        checked={upiCharges?.is_selected}
                        onChange={(e) => handleSelectChange(e, pg, "Upi")}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </td>
                    <td>
                      Rs.{upiCharges?.fixed || 0} +{" "}
                      {upiCharges?.percentage || 0}%
                      <Checkbox
                        size="small"
                        checked={walletCharges?.is_selected}
                        onChange={(e) => handleSelectChange(e, pg, "Wallet")}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </td>
                    {pg === "Cashfree" && (
                      <>
                        {" "}
                        <td>
                          Rs.{upiCharges?.fixed || 0} +{" "}
                          {upiCharges?.percentage || 0}%
                          <Checkbox
                            size="small"
                            checked={emiCharges?.is_selected}
                            onChange={(e) =>
                              handleSelectChange(e, pg, PaymentModeEnum.EMI)
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </td>
                        <td>
                          Rs.{upiCharges?.fixed || 0} +{" "}
                          {upiCharges?.percentage || 0}%
                          <Checkbox
                            size="small"
                            checked={payInThreeEmiCharges?.is_selected}
                            onChange={(e) =>
                              handleSelectChange(
                                e,
                                pg,
                                PaymentModeEnum.Payin3Emis
                              )
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </td>
                        <td>
                          Rs.{upiCharges?.fixed || 0} +{" "}
                          {upiCharges?.percentage || 0}%
                          <Checkbox
                            size="small"
                            checked={paylaterCharges?.is_selected}
                            onChange={(e) =>
                              handleSelectChange(
                                e,
                                pg,
                                PaymentModeEnum.PayLater
                              )
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </td>
                        <td>
                          Rs.{upiCharges?.fixed || 0} +{" "}
                          {upiCharges?.percentage || 0}%
                          <Checkbox
                            size="small"
                            checked={paypalCharges?.is_selected}
                            onChange={(e) =>
                              handleSelectChange(e, pg, PaymentModeEnum.Paypal)
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </td>{" "}
                      </>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="text-center">
          <button className="btn btn-success" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};
