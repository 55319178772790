import React from 'react'
import {List,Icon,Button,Loader} from 'semantic-ui-react'

const printListItem = (data,i,deleteUser,selectUser) => {
    return (
    <List.Item key={i}>
        <Icon name='user circle' size="large" />
        <List.Content>
            <List.Header as='a' onClick={()=>selectUser(data,i)}>
                {data.userName? data.userName + " (new)" : data.username + " (old)"}
            </List.Header>
            <List.Description>
               <Button icon="trash" size="mini"
               color="red" onClick={()=>{deleteUser(data._id)}} disabled/>
            </List.Description>
        </List.Content>
    </List.Item>)
}
export default function ListAdmins(props){

   

    return(
     
        <div className="card">
            <List >
            <Loader active={props.loading} inline />

                {
                props.users.map((data,i) => {
                    return (printListItem(data,i,props.deleteUser,props.selectUser))
                
                })
            }
            </List>
        </div>
      
    )
}