import {visaActionTypes} from 'redux/actionTypes/visaActionTypes'

const initialState = {
        countries: [],
        selectedCountry: {},
        visaCategories: [],
        selectedVisaCategory: [],
        visaChecklists: [],
        selectedNationality: [],
        selectedVisaChecklist: []
}

export default  function VisaReducer(state = initialState, action) {
switch(action.type) {
        case(visaActionTypes.GET_ALL_COUNTRIES):
        return {...state, countries: action.payload};

        case (visaActionTypes.SET_COUNTRY):
        return {...state, selectedCountry: action.payload};

        case (visaActionTypes.SET_VISACATEGORIES):
        return {...state, visaCategories: action.payload};

        case (visaActionTypes.SET_VISACHECKLISTS):
        return {...state, visaChecklists: action.payload};


        case (visaActionTypes.SET_NATIONALITY):
        return {...state, selectedNationality: action.payload};


        case(visaActionTypes.SET_SELECTED_VISACATEGORY):
        return {...state, selectedVisaCategory: action.payload};


        case(visaActionTypes.SET_SELECTED_VISA_CHECKLIST):
        return {...state, selectedVisaChecklist: action.payload};

        default: 
        return state;
}
}

