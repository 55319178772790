import {accountsTypes} from 'redux/actionTypes/accounts.types'

const initialState = {
    whitelabelsWithSippiCustomerData: []
}

export const accountsReducer = (state = initialState, {type,payload}) => {
    switch(type){
        
        case(accountsTypes.SET_WHITELABELS_WITH_SIPPI_CUSTOMER_DATA):
            return {
                ...state,
                whitelabelsWithSippiCustomerData: payload
            };
        
        
            
        default:
             return state;
    }
}