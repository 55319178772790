import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { currencyType } from "types/wallet.types";

interface ICurrencyWisePrices {
  currencyWisePrices:
    | {
        id: number;
        Travel_Website_Purchase: number;
        airIQ: number;
        callerDesk: number;
        cheapFixDeparture: number;
        clearTrip: number;
        tfNexux: number;
        galileo: number;
        goFlySmart: number;
        panCardVerification: number;
        tbo: number;
        tripJack: number;
        whatsApp: number;
        currency: currencyType;
        comboPlan1: number;
        comboPlan2: number;
        comboPlan3: number;
        comboPlan4: number;
      }
    | undefined;
}

const initialState: ICurrencyWisePrices | undefined = {
  currencyWisePrices: undefined,
};

export const currencyWisePricesSlice = createSlice({
  name: "currencyWisePrices",
  initialState,
  reducers: {
    setCurrencyWisePrices: (state, action) => {
      state.currencyWisePrices = action.payload;
    },
  },
});

export const { setCurrencyWisePrices } = currencyWisePricesSlice.actions;

//selector
export const selectCurrencyWisePrices = (state: RootState) =>
  state.currencyWisePrices;

export const currencyWisePricesReducer = currencyWisePricesSlice.reducer;
