import React, { useEffect, useState } from "react";
import Loading from "components/admin/common/loading";
import { GET_SITE_DATA } from "redux/actions/siteSettings.actions";

import { postRequest } from "tools/ajax";
import { toast } from "react-toastify";

import { SippiCustRow } from "./SippiCustRow";
import { ISippiCustomerWithPurchase } from "types/sippiCustomer.types";
import { BillingDetailModal } from "./BillingDetailModal";
import { FormControl } from "react-bootstrap";
import { useAppDispatch } from "hooks/reduxHooks";
import { sippiCustomerSlice } from "redux/slices/sippiCustomer.slice";

export default function SippiCustomers() {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedSippiCustomer, setSelectedCustomer] = useState<
    ISippiCustomerWithPurchase | undefined
  >(undefined);
  const [pagination, setPagination] = useState<{
    perPage: number;
    recordsToSkip: number;
    page: number;
    totalRecords: number;
    totalPages: number;
  }>({
    perPage: 10,
    recordsToSkip: 0,
    page: 1,
    totalRecords: 0,
    totalPages: 1,
  });

  useEffect(() => {
    getSippiCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSippiCustomers = async (pageNumber = 1) => {
    setLoading(true);
    const r = await postRequest("admin/whitelabel/getAllSippiCustomers", {
      page: pageNumber,
      searchKeyword,
    });
    if (!r.success) {
      return toast.error(r?.errorMessage);
    }

    setCustomers(r.result.customers);
    setPagination(r.result.pagination);
    // console.log(r.result);
    setLoading(false);
  };

  const jumpLogin = async (c) => {
    // console.log({ customer: c });
    const r = await postRequest("user/googleLogin", {
      profileObj: {
        googleId: c.googlePersonObj.googleId,
      },
    });

    // console.log(r, "response from server");
    localStorage.setItem("customer-token", r.token);
    localStorage.setItem(
      "googlePersonObj",
      JSON.stringify(r.customer.googlePersonObj)
    );

    dispatch(sippiCustomerSlice.actions.setSippiCustomer(r.customer));
    dispatch(GET_SITE_DATA());

    /**
     * adding set timeout to wait for redux update
     * because sometimes it was redirect before even setting data to redux
     * I am assuming that setting data in redux won't take more than 1 second
     * and this feature will only be used by sippi staff thats why i tought its ok to add timeout
     */
    setTimeout(() => {
      const win: any = window;
      win.open("/", "_blank").focus();
    }, 1000);
  };

  const searchCustomers = () => {
    setPagination({ ...pagination, page: 1 });
    getSippiCustomers(1);
  };

  return (
    <div className="container">
      <Loading loading={loading} />

      <div className="m-2 row justify-content-center ">
        <div className="col-sm-6 col-xs-8 py-1">
          <FormControl
            placeholder="Search by name or email"
            onChange={(e) => setSearchKeyword(e.target.value)}
            value={searchKeyword}
          />
        </div>
        <div className="col-xs-4 col-sm-2 py-1">
          <button className="btn btn-success" onClick={searchCustomers}>
            Search
          </button>
        </div>
      </div>

      <div className="table-responsive shadow">
        <table className="table  table-bordered table-responsive table-striped">
          <thead>
            <tr>
              <td>Action</td>
              <td>Name (Email, Phone)</td>
              <td>Plan</td>
              <td>Payment Gateway</td>
              <td>Promo Code</td>
            </tr>
          </thead>
          <tbody>
            {customers.map((c, i) => (
              <SippiCustRow
                customer={c}
                key={i}
                jumpLogin={jumpLogin}
                setSelectedCustomer={setSelectedCustomer}
                getSippiCustomers={() => getSippiCustomers(pagination.page)}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="p-1 d-flex justify-content-around my-2">
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            if (pagination.page > 1) {
              getSippiCustomers(pagination.page - 1);
            }
          }}
        >
          Previous
        </button>
        <div>{`${pagination.page} of ${pagination.totalPages} Pages - ${pagination.totalRecords} Records`}</div>
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            if (pagination.page === pagination.totalPages) {
              return;
            }
            getSippiCustomers(pagination.page + 1);
          }}
        >
          Next
        </button>
      </div>
      <div style={{ height: 70 }}></div>
      {selectedSippiCustomer && (
        <BillingDetailModal
          selectedSippiCustomer={selectedSippiCustomer}
          show={Boolean(selectedSippiCustomer)}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
    </div>
  );
}
