import React from 'react'
import Container from '@material-ui/core/Container';
export default function CommonContainer(props) {
    const {children} = props
    return (
        <Container {...props}>
            {children}
        </Container>
    )
}
