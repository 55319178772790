export const siteSettingsTypes = {
    "GET_SMTP_DETAILS": 'GET_SMTP_DETAILS',
    "SET_SMTP_DETAILS": "SET_SMTP_DETAILS",
    "DELETE_SMTP_DETAIL": "DELETE_SMTP_DETAIL",
    "GET_SITE_DATA": "GET_SITE_DATA",
    "SET_SITE_DATA": "SET_SITE_DATA",
    'SEND_TEST_EMAIL': "SEND_TEST_EMAIL",
    'SEND_TEST_EMAIL_RESPONSE': "SEND_TEST_EMAIL_RESPONSE",
    'GET_BANK_DETAILS': "GET_BANK_DETAILS",
    'SET_BANK_DETAILS': "SET_BANK_DETAILS",
    'BANK_DETAILS': "BANK_DETAILS"
}