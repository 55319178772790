import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getRequest } from "tools/ajax";
import _ from "lodash";
import { currencySigns, currencyType } from "types/wallet.types";
import { AddonsData } from "./AddonData";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCurrencyWisePrices } from "redux/slices/currencyWisePrices.slice";
import { useAppSelector } from "hooks/reduxHooks";

interface IAddon {
  addon: string;
  amount: number;
  id: number;
  sippiCustomerId: string;
}

export const Addons: React.FC<{}> = () => {
  const [productPrices, setProductPrices] = useState<
    | {
        product: string;
        price: number;
        currency: currencyType;
        monthlyMinimum: number;
        offeredBalance: number;
      }[]
    | undefined
  >(undefined);
  const [addons, setAddons] = useState<IAddon[]>();
  const dispatch = useDispatch();
  useEffect(() => {
    getPurchasedAddons();
    getCurrency();
  }, []);
  const currencyWisePrices = useAppSelector(
    (state) => state.currencyWisePrices.currencyWisePrices
  );
  useEffect(() => {
    if (currencyWisePrices?.currency) getProductPriceWithCurrency();
  }, [currencyWisePrices?.currency]);

  const getCurrency = async () => {
    try {
      const res = await getRequest("wallet/getSippiCustomerCurrency", {});

      if (res?.result?.currency) {
        dispatch(
          setCurrencyWisePrices({
            ...currencyWisePrices,
            currency: res.result.currency,
          })
        );
      } else {
        dispatch(
          setCurrencyWisePrices({
            ...currencyWisePrices,
            currency: "INR",
          })
        );
      }
      getProductPriceWithCurrency();
    } catch (error) {
      toast.error(error);
    }
  };

  const getProductPriceWithCurrency = async () => {
    try {
      if (!currencyWisePrices?.currency) return;
      const pricesResponce = await getRequest(
        "wallet/getProductPriceWithCurrency",
        {
          currency: currencyWisePrices?.currency,
        }
      );
      const productWisePricesObj: any = {};
      if (pricesResponce.result) {
        for (let item of pricesResponce.result) {
          productWisePricesObj[item.product] = item.price;
        }
      }

      dispatch(
        setCurrencyWisePrices({
          ...currencyWisePrices,
          ...productWisePricesObj,
        })
      );
      setProductPrices(pricesResponce.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getPurchasedAddons = async () => {
    try {
      const res = await getRequest("wallet/getPurchasedAddons", {});
      if (res?.success) {
        setAddons(res.result);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div className="sippi-customer-addons container " id="add-ons-page">
      <div className="row py-5 ">
        {/* addon-item */}

        {AddonsData.map((addon, index) => {
          const addonPriceObj = _.find(productPrices, {
            product: addon.addonEnum,
          });
          const currencySign = currencyWisePrices?.currency
            ? currencySigns[currencyWisePrices?.currency]
            : "";
          return (
            <div className="col-lg-3" key={index}>
              <div className="add-on-item rounded border p-3 mb-4">
                <div className="cover-div">
                  <img
                    className="img-fluid card-cover-img"
                    src={addon.data.bgImage}
                    alt=""
                  />
                </div>
                <div className="add-on-item-content">
                  <div className="icon-div">
                    <img
                      className="img-fluid icon"
                      src={addon.data.logoImage}
                      alt=""
                    />
                  </div>

                  <div className="d-flex justify-content-between">
                    <h6 className="fw-bold">{addon.addonName}</h6>
                    <div>
                      <b>
                        {currencySign}
                        {addonPriceObj?.price
                          ? addonPriceObj?.price
                          : "loading.."}
                      </b>
                    </div>
                  </div>
                  <p className="small short-description">
                    {addon.data.shortDescription}
                  </p>
                  {_.find(addons, { addon: addon.addonEnum }) ? (
                    <>
                      <span className="badge bg-success small">
                        <i className="fas fa-check-circle me-1"></i>Purchased
                      </span>
                      <div className="mt-3">
                        <Link
                          to={`/user/addons/${addon.addonEnum}`}
                          className="text-decoration-underline "
                        >
                          Show more details
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="purchase-btn ">
                      <Link to={`/user/addons/${addon.addonEnum}`}>
                        <button className="btn btn-primary btn-sm">
                          Purchase Now
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
