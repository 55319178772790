import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import GoogleLogin from "react-google-login";
import Loading from "components/user/common/loading";
import axios from "axios";
import { useHistory } from "react-router-dom";
//import context

import queryString from "stringquery";

import { GET_SITE_DATA } from "redux/actions/siteSettings.actions";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { sippiCustomerSlice } from "redux/slices/sippiCustomer.slice";
import { SippiCustomerContactDetailsModal } from "components/admin/sippiCustomers/SippiCustomerContactDetailsModal";
import { toast } from "react-toastify";
import { phoneNumberStringSchema } from "commonFnBcode/zodSchemas/sippiCustomerSchema";

export default function Login(props) {
  //logOut()

  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);
  const [isSignedIn, updateSignedIn] = React.useState(false);
  const [showEditDetailsModal, setShowEditDetailsModal] = useState(false);
  const history = useHistory();
  const sippiCustomer = useAppSelector(
    (state) => state.sippiCustomer?.customer
  );
  const phoneNumber = sippiCustomer?.purchases?.form.phoneNumber;
  const countryCode = sippiCustomer?.purchases?.form.phoneCountryCode;

  const googleSuccess = (res) => {
    setLoading(true);
    axios({
      url: "/api/user/googleLogin",
      method: "POST",
      data: res,
    }).then((r) => {
      // console.log(r.data,'response from server')
      localStorage.setItem("customer-token", r.data.token);
      localStorage.setItem(
        "googlePersonObj",
        JSON.stringify(r.data.customer.googlePersonObj)
      );

      dispatch(sippiCustomerSlice.actions.setSippiCustomer(r.data.customer));
      setLoading(false);
      updateSignedIn(true);
      dispatch(GET_SITE_DATA());
    });
  };

  const redirectPostLogin = () => {
    const queryStr = queryString(props.location.search);
    if (!queryStr.next) history.push("/user/dashboard");
    else history.push(queryStr.next);
  };

  const googleFailure = (res) => {
    console.log("google login failure", "\n\n api response", res);
  };

  const checkPhoneValidity = () => {
    if (phoneNumber) {
      if (!countryCode) {
        toast.warning("Phone Country code not found");
        setShowEditDetailsModal(true);
        return;
      }

      if (countryCode === "91" && phoneNumber.length !== 10) {
        setShowEditDetailsModal(true);
        return;
      }
      const validPhoneNumber = phoneNumberStringSchema.safeParse(phoneNumber);
      if (!validPhoneNumber.success) {
        toast.warning("Invalid Phone Number");
        setShowEditDetailsModal(true);
        return;
      }
    }
    redirectPostLogin();
  };

  useEffect(() => {
    if (sippiCustomer) {
      checkPhoneValidity();
    }
  }, [sippiCustomer]);
  return (
    <div className="login-page-pss">
      <div className="page-content">
        <Loading loading={loading} />
        <Card className="login-card" variant="outlined">
          <CardContent className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <img
                src="https://images.unsplash.com/photo-1604629142630-11d209431dd7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=465&q=80"
                alt=""
              ></img>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="content">
                <Typography className="">
                  {" "}
                  <h3>Login</h3>
                </Typography>
                {/* {isSignedIn === true && 'isSignedIn'}
                    <button onClick={() => {updateSignedIn(false)}}>
                        log out
                    </button>
                    <br /> */}
                <div className="button-div">
                  <GoogleLogin
                    className="google-button"
                    clientId="129678767412-cu4rsr6cfk436qi7ectg1dp3an3she04.apps.googleusercontent.com"
                    buttonText="Login with Google Account"
                    onSuccess={googleSuccess}
                    onFailure={googleFailure}
                    cookiePolicy={"single_host_origin"}
                    isSignedIn={isSignedIn}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      {showEditDetailsModal && sippiCustomer && (
        <SippiCustomerContactDetailsModal
          userType="user"
          show={showEditDetailsModal}
          handleClose={() => {
            setShowEditDetailsModal(false);
            redirectPostLogin();
          }}
          customerDetails={sippiCustomer}
        />
      )}
    </div>
  );
}
