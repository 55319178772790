import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAirline, IAirlinesData } from "redux/reducers/flightApi.reducer";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { UploadImage as ReactEasyCrop } from "@sippisoftwaresolutions/upload-image-pkg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { postRequest } from "tools/ajax";
import { flightBaseUrl } from "tools/routeConstants";
import { getAllAirlines } from "redux/actions/flightApi.actions";
import { RootState } from "redux/store";
import { toast } from "react-toastify";
import { UploadAirlineLogoPopup } from "./UploadAirlineLogoPopup";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
    green: {
      color: "#fff",
      backgroundColor: "grey",
    },
  })
);

export const AirlineSettings = () => {
  const allAirlines = useSelector<RootState, Array<IAirlinesData>>(
    (state) => state.flightApi.allAirlines
  );
  const classes = useStyles();

  // console.log("----", allAirlines);

  const [openState, setOpenState] = useState<boolean>(false);
  const [editAirDetails, setEditAirDetails] = useState<boolean>(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedAirlineId, setSelectedAirlineId] = useState<number>();
  const [airlineLogoState, setAirlineLogoState] = useState<Blob>();
  const [noOfAirlinesLoaded, setNoOfAirlinesLoaded] = useState<number>(50);
  const [filteredAirLines, setFilteredAirLines] = useState<
    IAirlinesData[] | undefined
  >([]);
  const [airlineDetails, setAirlineDetails] = useState<{
    airlineName: string;
    airlineCode: string;
    isLCC?: string | null;
  }>({
    isLCC: null,
    airlineName: "",
    airlineCode: "",
  });
  const dispatch = useDispatch();

  const hideEditDialog = () => {
    setSelectedAirlineId(undefined);
    setOpenState(false);
  };
  const showEditLogoDialog = (id: number) => {
    setSelectedAirlineId(id);
    setOpenState(true);
  };

  const setImageData = (data: Blob) => {
    setAirlineLogoState(data);
    // console.log(airlineLogoState);
  };

  useEffect(() => {
    setFilteredAirLines(allAirlines);
    filterAirlinesByDate();
  }, [allAirlines]);

  const saveAirlineImage = async () => {
    let formData: FormData = new FormData();

    if (selectedAirlineId && airlineLogoState) {
      formData.append("id", selectedAirlineId.toString());

      formData.append("image", airlineLogoState[0], airlineLogoState[0].name);

      try {
        const response = await postRequest(
          `${flightBaseUrl}/uploadAirlineLogo`,
          formData
        );
        if (response?.success) {
          // console.log("response---", response);
          dispatch(getAllAirlines());
          setOpenState(false);
          toast.success("Airline logo uploaded successfully!");
        }
      } catch (err) {
        toast.error("There is an error uploading image, try again later.");
        // window.location.reload();
      }
    } else {
      toast.error("Please select a logo first.");
    }
  };

  const saveAirLineDetails = async () => {
    const customForm = new FormData();
    const airlineDetailsObj = {
      id: selectedAirlineId,
      ...airlineDetails,
    };
    customForm.append(
      "airlineDetails",
      JSON.stringify({ ...airlineDetailsObj })
    );
    try {
      const response = await postRequest(
        `${flightBaseUrl}/uploadAirlineDetails`,
        customForm
      );
      if (response?.success) {
        setAirlineDetails({ airlineCode: "", airlineName: "", isLCC: "" });
        dispatch(getAllAirlines());
        setEditAirDetails(false);
        toast.success("Airline details updated!");
      }
    } catch (error) {
      toast.error("Request failed");
    }
  };
  const handleAirlineDetails = (e) => {
    const { name, value } = e.target;
    setAirlineDetails((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const filterResults = (e) => {
    if (e.target.value.trim().length) {
      setFilteredAirLines(
        allAirlines.filter((airLine) => {
          if (
            airLine.airlineName
              .toLocaleLowerCase()
              .includes(e.target.value.toLocaleLowerCase())
          )
            return true;
          else return false;
        })
      );
    } else {
      setFilteredAirLines(allAirlines);
      filterAirlinesByDate();
    }
  };

  const addNewAirline = async () => {
    // if (!airlineLogoState) {
    //   return;
    // }
    // console.log({ ...airlineDetails, airlineLogo: airlineLogoState[0] });
    const customForm = new FormData();
    // console.log(airlineDetails);

    customForm.append("newAirLineData", JSON.stringify({ ...airlineDetails }));

    if (
      !airlineLogoState ||
      !airlineDetails.airlineCode.trim() ||
      !airlineDetails.airlineName.trim() ||
      !airlineDetails.isLCC
    ) {
      toast.error("Insufficient Airline Data!");
      return;
    }

    customForm.append("image", airlineLogoState[0], airlineLogoState[0].name);
    try {
      const response = await postRequest(
        `${flightBaseUrl}/addNewAirline`,
        customForm
      );

      if (response?.success) {
        dispatch(getAllAirlines());
        setAirlineDetails({ airlineCode: "", airlineName: "", isLCC: "" });
        setAirlineLogoState(undefined);
        toast.success("New Airline Added!");
      }
    } catch (error) {
      toast.error("Request Failed");
    }
  };

  const filterAirlinesByDate = () => {
    setFilteredAirLines((old: any) => {
      return _.orderBy(old, (o) => new Date(o.updatedAt), "desc");
    });
  };

  const showAirlinesWithoutLogoFirst = (e) => {
    if (e.target.checked) {
      setFilteredAirLines((old) =>
        old?.filter((airline) => {
          if (airline.logoUrl === null) return true;
        })
      );
    } else {
      setFilteredAirLines(allAirlines);
      filterAirlinesByDate();
    }
  };

  return (
    <div className="container">
      <UploadAirlineLogoPopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        setImageData={setImageData}
      />
      <div className="container mb-5">
        <h2 className="w-100 text-center  my-2 fs-2">Airline Settings</h2>
        <div className="d-flex justify-content-center align-items-end w-100">
          <div className="mx-3">
            <label htmlFor="">Search Airline</label>
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              onChange={filterResults}
            />
          </div>
          <div className="form-check form-switch mx-3 ">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              onChange={showAirlinesWithoutLogoFirst}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Without Logo
            </label>
          </div>
        </div>
        <h3>Add Airline</h3>
        <div className="w-100 row d-flex align-items-end justify-content-center">
          <div className="col-2 h-100 d-flex align-items-end">
            <button
              className="btn btn-primary w-100"
              onClick={() => setModalShow(true)}
            >
              <b>Add Logo</b>
              {airlineLogoState !== undefined
                ? `(${airlineLogoState[0].name.substr(0, 4)}...)`
                : ""}
            </button>
          </div>
          <div className="col-3">
            <label htmlFor="airlineName" className="form-label bold">
              Airline Name
            </label>
            <input
              id="airlineName"
              onChange={handleAirlineDetails}
              type="text"
              placeholder="Airline Name"
              className="form-control "
              name="airlineName"
            />
          </div>
          <div className="col-2">
            <label htmlFor="airlineCode" className="form-label bold">
              Airline Code
            </label>
            <input
              id="airlineCode"
              onChange={handleAirlineDetails}
              type="text"
              placeholder="Airline Code"
              className="form-control "
              name="airlineCode"
            />
          </div>

          <div className="col-2 h-100 d-flex justify-content-evenly align-items-end pb-2 ">
            <div>
              <input
                onChange={handleAirlineDetails}
                className="me-1"
                type="radio"
                id="true"
                name="isLCC"
                value="true"
              />
              <label htmlFor="true">LCC</label>
            </div>
            <div>
              <input
                onChange={handleAirlineDetails}
                className="me-1"
                type="radio"
                id="false"
                name="isLCC"
                value="false"
              />
              <label htmlFor="false">Non LCC</label>
            </div>
          </div>

          <div className="col-2 d-flex align-items-end h-100">
            <button
              className="btn btn-success w-100"
              type="button"
              onClick={addNewAirline}
            >
              Save
            </button>
          </div>
        </div>
        <Dialog
          open={openState}
          onClose={hideEditDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">Add Airline Logo</DialogTitle>
          <DialogContent
            style={{
              width: 500,
            }}
          >
            <ReactEasyCrop aspectRatio={1} setImageData={setImageData} />
          </DialogContent>
          <DialogActions>
            <button className="btn btn-secondary" onClick={saveAirlineImage}>
              Save Logo
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editAirDetails}
          onClose={() => {
            setSelectedAirlineId(undefined);
            setEditAirDetails(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">Add Airline Details</DialogTitle>
          <DialogContent
            style={{
              width: 500,
            }}
          >
            <div className="mb-3">
              <label htmlFor="airline-name" className="form-label">
                Airline Name
              </label>
              <input
                value={airlineDetails.airlineName || ""}
                type="text"
                className="form-control"
                id="airline-name"
                onChange={handleAirlineDetails}
                name="airlineName"
              />
              <label htmlFor="airline-code" className="form-label">
                Airline Code
              </label>
              <input
                value={airlineDetails.airlineCode || ""}
                name="airlineCode"
                onChange={handleAirlineDetails}
                type="text"
                className="form-control"
                id="airline-code"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <button
              className="btn btn-primary"
              onClick={() => {
                saveAirLineDetails();
              }}
            >
              Save Details
            </button>
          </DialogActions>
        </Dialog>
      </div>
      <h3>All Airlines({filteredAirLines?.length}):</h3>
      {filteredAirLines?.length &&
        filteredAirLines
          ?.filter((f, indx) => indx < noOfAirlinesLoaded)
          .map((air, index) => (
            <div key={index}>
              <div className="row my-2 align-items-center ">
                <div className="col-3">
                  {air.logoUrl ? (
                    <Avatar
                      style={{
                        width: 50,
                        height: 50,
                        margin: "0 auto",
                      }}
                      src={air?.logoUrl}
                    />
                  ) : (
                    <Avatar
                      className={`${classes.green} mx-auto`}
                      style={{
                        width: 50,
                        height: 50,
                        margin: "0 auto",
                      }}
                    >
                      <i className="fas fa-plane"></i>
                    </Avatar>
                  )}
                </div>
                <div className="col-3">
                  {`${index + 1}. ${air.airlineName} (${air.airlineCode})`}
                </div>
                <div className="col-2">
                  isLCC: {air.isLCC ? "True" : "False"}
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-md btn-success"
                    onClick={() => showEditLogoDialog(air.id)}
                  >
                    Edit Logo
                  </button>
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="btn btn-md btn-primary "
                    onClick={() => {
                      setAirlineDetails({
                        airlineCode: air.airlineCode,
                        airlineName: air.airlineName,
                        // isLCC: air.isLCC,
                      });
                      setSelectedAirlineId(air.id);
                      setEditAirDetails(true);
                    }}
                  >
                    Edit Details
                  </button>
                </div>
              </div>
            </div>
          ))}
      <div className="w-100 d-flex justify-content-center my-4 ">
        <button
          className="btn btn-warning text-white "
          onClick={() => {
            setNoOfAirlinesLoaded((old) => (old += 50));
          }}
        >
          Load more...
        </button>
      </div>
    </div>
  );
};
