import axios from "axios";
import { BASE_URLS } from "tools/constants";


// This axios is created here only for Airline Discount Markup Folder,
// so we can copy this folder and move to semantic
// Donot use it anywhere else
export const flightAxios = axios.create({
    baseURL: BASE_URLS.PARTNER_SIPPI + '/admin/flightApi/',
    headers: {
        'auth-token': localStorage.getItem('auth-token'),
        'customer-token': localStorage.getItem('customer-token')
    },
    method: "POST",
})
