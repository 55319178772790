import { PhoneInput } from "components/user/plans/travelEsLite/PhoneInput";

import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CountryData } from "react-intl-tel-input";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { sippiCustomerSlice } from "redux/slices/sippiCustomer.slice";
import { ModalHeader } from "semantic-ui-react";
import { postRequest } from "tools/ajax";
import {
  IsippiCustomer,
  ISippiCustomerWithPurchase,
} from "types/sippiCustomer.types";

interface IApiResponse<T> {
  result: T;
  success: boolean;
  errorMessage: any;
}

export const SippiCustomerContactDetailsModal = (props: {
  userType: "admin" | "user";
  show: boolean;
  handleClose: () => void;
  customerDetails: ISippiCustomerWithPurchase | IsippiCustomer;
  getSippiCustomers?: () => void;
}) => {
  const dispatch = useDispatch();
  const [contactDetails, setContactDetails] = useState({
    phoneNumber: props.customerDetails.purchases?.form.phoneNumber || "",
    phoneCode: props.customerDetails.purchases?.form.phoneCountryCode || "",
  });
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  const handlePhoneInpChange = (
    isValid: boolean,
    value: string,
    selectedCountryData: CountryData,
    fullNumber: string
  ) => {
    setIsPhoneNumberValid(isValid);
    if (value.includes("+") || value.includes("-")) {
      setIsPhoneNumberValid(false);
    }
    if (selectedCountryData?.dialCode === "91" && value.length > 10) {
      setIsPhoneNumberValid(false);
    }
    setContactDetails((old) => {
      return {
        ...old,
        phoneCode: selectedCountryData.dialCode || "",
        phoneNumber: value,
      };
    });
  };

  const updateSippiCustomerDetailsByAdmin = async () => {
    try {
      let apiRes:
        | IApiResponse<{
            updatedSippiCustomer: IsippiCustomer | null;
          }>
        | undefined;
      if (props.userType === "admin") {
        apiRes = await postRequest(
          "admin/whitelabel/updateSippiCustomerDetailsByAdmin",
          {
            contactDetails,
            sippiCustomerId: props?.customerDetails?._id,
          }
        );
      } else if (props.userType === "user") {
        apiRes = await postRequest(
          "user/updateSippiCustomerPhoneNumberByUser",
          contactDetails
        );
      }
      if (apiRes?.success) {
        toast.success("Saved");
        if (props.getSippiCustomers) {
          props.getSippiCustomers();
        }
        props.handleClose();
      }
      if (apiRes?.result?.updatedSippiCustomer) {
        dispatch(
          sippiCustomerSlice.actions.setSippiCustomer(
            apiRes?.result.updatedSippiCustomer
          )
        );
      }
      if (!apiRes?.success) {
        throw new Error(apiRes?.errorMessage);
      }
    } catch (err: any) {
      toast.error(err.message);
      console.log(err);
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <ModalHeader>
        <div className="p-3 pb-0">
          <h3 className="m-0 ">
            {props.userType === "user"
              ? "Please Provide a valid phone number"
              : "Update Phone Number"}
          </h3>
          <div className="small">
            {props.customerDetails.googlePersonObj.email}
          </div>
        </div>
      </ModalHeader>
      <Modal.Body>
        <label htmlFor="">Phone Number</label>
        <PhoneInput
          phoneNumberIsValid={isPhoneNumberValid}
          value={contactDetails.phoneNumber}
          label=""
          defaultValue={contactDetails.phoneNumber}
          handleChange={handlePhoneInpChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={updateSippiCustomerDetailsByAdmin}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
