import React, { useEffect, useState } from "react";
import Container from "commonUi/Container";
import { Button, Grid, Typography } from "@material-ui/core";
import {
  getlook2BookApiCall,
  getlook2BookReportApiCall,
} from "apiCalls/siteSettingsDb";

import moment from "moment";
import Loading from "../common/loading";
import exportFromJSON from "export-from-json";
import { UserDatePicker } from "components/DateControl";
import { useAppDispatch } from "hooks/reduxHooks";
import { SET_LOADER } from "redux/actions/common.actions";

export const LookToBookRatio = () => {
  const [lookCount, setLookCount] = useState<number>();

  const [loadingState, setLoadingState] = useState(false);
  const [bookCount, setBookCount] = useState<number>();

  const [filterState, setFilterState] = React.useState<{
    fromDate?: Date;
    toDate?: Date;
  }>({
    fromDate: moment().startOf("day").toDate(),
  });

  const dispatch = useAppDispatch();

  const handleDateChange = (name, value) => {
    if (["fromDate", "toDate"].includes(name)) {
      setFilterState((fs) => ({
        ...fs,
        [name]:
          name === "toDate"
            ? moment(value).endOf("day")
            : moment(value).startOf("day"),
      }));
    }
  };

  const getLookToBookRatio = async () => {
    dispatch(SET_LOADER(true));
    const l2bResponse = await getlook2BookApiCall();
    if (l2bResponse.success && l2bResponse.result) {
      setLookCount(l2bResponse.result.lookToBook.totalSearches);
      setBookCount(l2bResponse.result.lookToBook.totalTickets);
    }
    dispatch(SET_LOADER(false));
  };

  const downloadLookToBookReport = async () => {
    setLoadingState(true);
    const l2bResponse = await getlook2BookReportApiCall({
      fromDate: filterState.fromDate,
      toDate: filterState.toDate,
    });

    if (l2bResponse.success && l2bResponse.result) {
      // setSearchesArray(l2bResponse.result.totalSearches);
      exportFromJSON({
        data: l2bResponse.result.totalSearches.map((item) => {
          return {
            Origin: item?.searchResults?.Origin,
            Destination: item?.searchResults?.Destination,
            "Search Date": moment(item?.createdAt).format("lll"),
            // Return: item?.hasReturn,

            userId: `${item?.userId}`,
            email: item?.user?.email,
            phone: item?.user?.phone,
            searchId: item?._id,
          };
        }),
        fileName: "SearchData",
        exportType: "xls",
      });
    }
    setLoadingState(false);
  };

  useEffect(() => {
    getLookToBookRatio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loading loading={loadingState} />
      <Container className="mt-4">
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" className="text-center mb-3">
              Look to Book Report
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {lookCount !== undefined && bookCount !== undefined && (
              <>
                {/* <Typography variant="h5" className="text-center mt-3">
                  Total Searches: {lookCount}
                </Typography>
                <Typography variant="h5" className="text-center mt-1">
                  Total Issued Tickets: {bookCount}
                </Typography>
                <Typography variant="h5" className="text-center mt-1 mb-4">
                  Look to Book Percentage:{" "}
                  {((bookCount / lookCount) * 100).toFixed(2)}%
                </Typography>

                <div className="text-center">
                  <ReactSpeedometer
                    maxValue={100}
                    value={parseFloat(
                      ((bookCount / lookCount) * 100).toFixed(2)
                    )}
                    needleColor="black"
                    startColor="red"
                    segments={100}
                    maxSegmentLabels={10}
                    segmentColors={_.times(100, (indx) => {
                      return indx;
                    }).map((seg) => {
                      if (seg < 3) return "red";
                      if (seg < 10) return "orange";
                      return "green";
                    })}
                    endColor="green"
                  />
                </div> */}

                <div className="container">
                  <div className="row">
                    <div className="col">
                      <UserDatePicker
                        maxDate={new Date()}
                        label="From Date"
                        value={filterState.fromDate}
                        handleChange={handleDateChange}
                        name="fromDate"
                        disabled={false}
                        isRequired={false}
                        errors={undefined}
                        minDate={undefined}
                      />
                    </div>
                    <div className="col">
                      <UserDatePicker
                        minDate={filterState.fromDate}
                        maxDate={new Date()}
                        label="To Date"
                        value={filterState.toDate}
                        handleChange={handleDateChange}
                        name="toDate"
                        disabled={false}
                        isRequired={false}
                        errors={undefined}
                      />
                    </div>
                  </div>

                  <div className="row">
                    {/* <div className="col-6">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="fromDate"
                          label="From Date"
                          value={fromDate}
                          onChange={(e) => handleDateChange(e)}
                          KeyboardButtonProps={{
                            "aria-label": "From Date",
                          }}
                          style={{ float: "right" }}
                          minDate={moment(new Date("01-12-2021")).toDate()}
                          // id="date"
                          // label="From Date"
                          // type="date"
                          // minDate={today}
                          // defaultValue={moment(new Date())
                          //   .subtract(1, "month")
                          //   .format("YYYY-MM-DD")}
                          // InputLabelProps={{

                          //   shrink: true,
                          // }}
                          // style={{ float: "right" }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-6">
                      <TextField
                        id="date"
                        label="To Date"
                        type="date"
                        defaultValue={new Date().toISOString().substring(0, 10)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div> */}

                    <div className="col-12 text-center mt-3">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={downloadLookToBookReport}
                      >
                        Download Excel
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
