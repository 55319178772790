import React, { useEffect, useState } from "react";
import Container from "commonUi/Container";
import GridContainer from "commonUi/grid/container";
import GridItem from "commonUi/grid/item";
import Typography from "commonUi/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "commonUi/button/btnPrimary";
import TxtInp from "commonUi/input/text";
import {
  getWhitelabelByDomain,
  getWhitelabelToTboEnv,
  postWhitelabelToTboEnv,
} from "apiCalls/whitelabelDb";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import LiveSitesList from "./LiveSitesList";

import { toast } from "react-toastify";

const useStyles = makeStyles({
  gridContainer: {
    marginTop: "1rem",
  },
  searchInp: {
    margin: "10px auto",
    width: "100%",
  },
  imgHeight: {
    float: "left",
    display: "block",
    maxHeight: "100px",
    maxWidth: "100%",
    width: "auto",
    height: "auto",
    margin: "1px auto",
    objectFit: "cover",
  },
});

export default function Index() {
  const classes = useStyles();
  const [searchDomain, setSearchDomain] = useState("");
  const [liveApi, setLiveApi] = useState(false);
  const [whitelabel, setWhitelabel] = useState({});

  useEffect(() => {
    if (
      whitelabel &&
      Object.keys(whitelabel).length !== 0 &&
      whitelabel.constructor === Object
    ) {
      getWhitelabelFromTboEnvDb();
    }
  }, [whitelabel]); // eslint-disable-line react-hooks/exhaustive-deps

  const getWhitelabelFromTboEnvDb = async () => {
    const r = await getWhitelabelToTboEnv({ siteId: whitelabel._id });
    if (r?.flightApiConfig?.tbo?.liveApi)
      return setLiveApi(r.flightApiConfig.tbo.liveApi);
    else setLiveApi(false);
  };

  const handleSearchChange = (e) => {
    let domain = e.target.value;
    setSearchDomain(domain);
  };

  const handleSwitchChange = async (siteId) => {
    const r = await postWhitelabelToTboEnv({
      siteId,
      liveApi: !liveApi,
    });
    if (r) return getWhitelabelFromTboEnvDb();
  };

  const searchDomainFromDb = async () => {
    if (!searchDomain) return toast.error("Please Enter Domain");
    const whitelabelSearchResult = await getWhitelabelByDomain({
      domain: searchDomain,
    });
    if (whitelabelSearchResult.err)
      return toast.error(whitelabelSearchResult.err);
    setWhitelabel(whitelabelSearchResult);
  };

  return (
    <div>
      <Container>
        <Typography variant="h4" style={{ textAlign: "center" }}>
          TBO Api Settings
        </Typography>
        <Divider />
        <GridContainer
          className={classes.gridContainer}
          justify="center"
          spacing={2}
        >
          <GridItem xs={12} sm={6}>
            <Typography variant="h5">Manage Live/Test Sites</Typography>
            <TxtInp
              className={classes.searchInp}
              label="Search Domain"
              onChange={handleSearchChange}
            />

            <Button onClick={() => searchDomainFromDb()} label="search" />
          </GridItem>
          <Grid item xs={12}></Grid>
          {whitelabel &&
            Object.keys(whitelabel).length !== 0 &&
            whitelabel.constructor === Object && (
              <GridItem xs={12} sm={6}>
                <img
                  src={whitelabel.logoUrl}
                  alt={whitelabel.companyName}
                  className={classes.imgHeight}
                />
                <Typography variant="h6" color="initial">
                  {whitelabel.companyName}
                </Typography>
                <Typography color="initial">
                  Domain: {whitelabel.domain}
                </Typography>
                <Typography color="initial">
                  Site: {whitelabel.siteName}
                </Typography>
                <Divider />
                <Typography variant="h6" color="initial">
                  {`Flight Api \xa0`}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={liveApi}
                        color="secondary"
                        inputprops={{ "aria-label": "primary checkbox" }}
                        onChange={() => handleSwitchChange(whitelabel._id)}
                      />
                    }
                  />
                  {liveApi ? (
                    <div style={{ color: "green" }}>Live</div>
                  ) : (
                    "Production"
                  )}
                </Typography>
              </GridItem>
            )}
        </GridContainer>
        <LiveSitesList />
      </Container>
    </div>
  );
}
