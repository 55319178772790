import React from "react";
import { ListAllWhitelabels } from "./ListAllWhitelabels";

export default function ViewWhitelabel() {
  return (
    <div className="p-2">
      <ListAllWhitelabels />;
    </div>
  );
}
