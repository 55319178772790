import React from "react";
import * as yup from "yup";
import axios from "axios";
import { Grid, Button, TextField } from "@material-ui/core";
import Loading from "components/admin/common/loading";
import { toast } from "react-toastify";
let schema = yup.object().shape({
  userName: yup.string().required(),
  password: yup.string().required(),
});
export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      otp: "",
      isSubmitting: false,
      otpSent: false,
      retriesPending: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    if (localStorage.getItem("auth-token")) {
      this.props.history.push("/admin/whiteLabel");
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    //console.log(this.state)
    this.setState({ isSubmitting: true });
    let s = this.state;
    let obj = {
      userName: s.userName,
      password: s.password,
    };
    schema.isValid(obj).then((valid) => {
      if (!valid) {
        toast.error("Please fill correct username & password!");
        this.setState({ isSubmitting: false });
        return;
      }

      axios({
        url: "/api/admin/user/login",
        method: "POST",
        data: obj,
      }).then((r) => {
        //console.log('server',r.data)
        this.setState({
          isSubmitting: false,
          otpSent: true,
          retriesPending: 3,
        });
        if (r.data.err) return toast.error(r.data.err);
        toast.success("Otp sent to your email.");
      });
    });
  }

  verifyOtp = () => {
    //console.log(this.state)
    this.setState({
      isSubmitting: true,
    });
    // this.setState({ isSubmitting: true });
    let s = this.state;
    let obj = {
      userName: s.userName,
      password: s.password,
      otp: s.otp,
    };

    axios({
      url: "/api/admin/user/verify-email-otp",
      method: "POST",
      data: obj,
    })
      .then((r) => {
        if (r.data.success) {
          if (r.data.err) return toast.error(r.data.err);
          localStorage.setItem("auth-token", r.data.token);
          this.props.history.push("/admin/whiteLabel");
        } else {
          throw new Error(r.data.msg);
        }
      })
      .catch((err) => {
        this.setState((old) => ({
          retriesPending: old.retriesPending - 1,
        }));
        if (err.message) {
          toast.error(err.message || "Something went wrong!");
        }
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };
  render() {
    return (
      <div className="admin-login-page-pss">
        <Grid container justify="center">
          <Grid item align="center">
            <div className="admin-form-box">
              <img
                src="https://ik.imagekit.io/m2f79jq3u/sippilogo_5upKEqYuZ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672744260344"
                alt=""
              ></img>
              <h3>Admin Login</h3>
              <Loading loading={this.state.isSubmitting} />
              <form onSubmit={this.handleSubmit}>
                <TextField
                  name="userName"
                  label="Username"
                  variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.userName}
                />

                <br />
                <br />
                <TextField
                  name="password"
                  variant="outlined"
                  type="password"
                  label="Password"
                  onChange={this.handleChange}
                  value={this.state.password}
                />
                <br />
                <br />

                {this.state.otpSent && this.state.retriesPending > 0 ? (
                  <>
                    <TextField
                      name="otp"
                      variant="outlined"
                      type="number"
                      label="OTP"
                      onChange={this.handleChange}
                      value={this.state.otp}
                    />
                    <br />
                    <br />
                    {this.state.retriesPending > 0 &&
                      this.state.retriesPending < 3 && (
                        <>
                          <p className="text-danger">
                            Retries Pending: {this.state.retriesPending}
                          </p>
                        </>
                      )}
                    <Button
                      color="primary"
                      type="button"
                      variant="contained"
                      onClick={this.verifyOtp}
                    >
                      Login
                    </Button>
                  </>
                ) : (
                  <Button color="primary" variant="contained" type="submit">
                    Generate OTP
                  </Button>
                )}
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
