import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { DELETE_SMTP_DETAIL } from "redux/actions/siteSettings.actions";
import TestMailDialog from "./TestMailDialog/Index";

export default function ({ smtpDetails }) {
  const dispatch = useDispatch();
  const [testDialogIsOpen, setTestDialogIsOpen] = useState(false);
  const [selectedSmtp, setSelectedSmtp] = useState({});

  const deleteSmtpDetail = (id) => {
    dispatch(DELETE_SMTP_DETAIL(id));
  };

  const openTestMailDialog = (smtp) => {
    setTestDialogIsOpen(true);
    setSelectedSmtp(smtp);
  };

  return (
    <>
      <ul className="list-group">
        {smtpDetails.length ? (
          smtpDetails?.map((smtp, i) => (
            <li key={i} className="list-group-item">
              Host: {smtp.host}, Port: {smtp.port}, User: {smtp.user}
              {smtp.secure && ", Secure"}
              <div style={{ textAlign: "right" }}>
                <button
                  className="btn btn-primary btn-sm m-1"
                  onClick={() => openTestMailDialog(smtp)}
                >
                  Test Email
                </button>
                <button
                  className="btn btn-danger btn-sm m-1"
                  onClick={() => deleteSmtpDetail(smtp._id)}
                >
                  Delete
                </button>
              </div>
            </li>
          ))
        ) : (
          <p>No SMTP Details found!</p>
        )}
      </ul>
      <TestMailDialog
        isOpen={testDialogIsOpen}
        smtp={selectedSmtp}
        handleClose={() => {
          setTestDialogIsOpen(false);
        }}
      />
    </>
  );
}
