import {commonTypes} from 'redux/actionTypes/common.types'

const initialState = {
    alert: {
        showErrorMessage: false,
        errorMessage: ""
    },
    showLoader: false
}

export const commonReducer = (state = initialState, action) => {
    switch(action.type){
        
        case commonTypes.SHOW_ALERT_MESSAGE:
            console.log('sss',action.payload.message)
            return {
                ...state,
                alert:{
                    showErrorMessage: action.payload.show,
                    errorMessage: action.payload.message?.message
                }
            }

        case commonTypes.HIDE_ALERT_MESSAGE:
            return {
                ...state,
                alert:{
                    ...state.alert,
                    showErrorMessage: false,
                }
            }
        
        case commonTypes.SET_LOADER:
            return {
                ...state,
                showLoader: action.payload
            }

        default:
            return state;
    }
}