import { useHistory } from "react-router-dom";
import { UserWalletCard } from "./UserWalletCard";
import { useState, useEffect, useRef } from "react";
import Collapse from "react-bootstrap/Collapse";
import { number } from "joi";
import { getRequest, postRequest } from "tools/ajax";
import { toast } from "react-toastify";
import moment from "moment";
import { WalletReachargePlanItem } from "./WalletReachargePlanItem";
import ReactDatePicker from "react-datepicker";
import { currencySigns, currencyType } from "types/wallet.types";
import { useAppSelector } from "hooks/reduxHooks";
import { useDispatch } from "react-redux";
import { setCurrencyWisePrices } from "redux/slices/currencyWisePrices.slice";
import { currencyConfig } from "types/sippiCurrencyTypes";
import { productTypeEnum } from "commonFnBcode/types/whitelabel.types";
import { TransactionUsagePopup } from "./TransactionUsagePopup";

const TransactionsSkelton = () => {
  return (
    <div className="row text-center bg-white mx-0 border-bottom py-4">
      <p className="placeholder-glow">
        <span className="placeholder col-12"></span>
      </p>
    </div>
  );
};
export enum EnumTransactionFilter {
  PAN_CARD_VERIFICATION = "panCardVerification",
  DAILY_BILL = "daily_bill",
  FLIGHT_BOOKING = "flight_booking",
  WALLET_RECHARGE = "Wallet_Recharge",
  ONLY_DEBIT = "debit",
  ONLY_CREDIT = "credit",
  OTHERS = "others",
}
export const UserWallet: React.FC<{}> = () => {
  const history = useHistory();
  const scrollRef = useRef(null);
  const [currentBalance, setCurrentBalance] = useState<
    | {
        balance: number;
        currency: currencyType;
      }
    | undefined
  >(undefined);

  const [transactions, setTransactions] = useState<
    {
      amount: Number;
      last_balance: Number;
      createdAt: string;
      email: string;
      id: Number;
      remark: string | null;
      sippiCustomerId: string;
      transactionType: string;
      updatedAt: string;
      name: string;
    }[]
  >();
  const [pagination, setPagination] = useState<{
    perPage: number;
    recordsToSkip: number;
    page: number;
    totalRecords: number;
    totalPages: number;
  }>({
    perPage: 10,
    recordsToSkip: 0,
    page: 1,
    totalRecords: 0,
    totalPages: 1,
  });
  const [loading, setLoading] = useState(false);
  const [showTransactionUsageModal, setShowTransactionUsageModal] =
    useState(false);
  const defaultFilterState = {
    fromDate: moment().subtract(2, "months").toDate(),
    toDate: moment().toDate(),
  };

  const [transactionsFilter, setTransactionsFilter] = useState<
    string | undefined
  >(undefined);

  const [dateFilter, setDateFilter] = useState<{
    fromDate: Date;
    toDate: Date;
  }>(defaultFilterState);
  // const dispatch = useDispatch();
  // const currencyWisePrices = useAppSelector(
  //   (state) => state.currencyWisePrices.currencyWisePrices
  // );
  useEffect(() => {
    getCurrentUserBalance();
    getWalletTransactions();
  }, [dateFilter]);

  // const getWalletInfo = async () => {
  //   try {
  //     const res = await getRequest("user/getWalletInfo", {});
  //     setCurrentBalance(res.result.Balance.balance);
  //     setmonthlyExaustedAmount(res.result.CurrentMonthExhaustedAmount);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getCurrentUserBalance = async () => {
    setLoading(true);

    const res = await getRequest("wallet/getCurrentUserBalance", {});
    if (res?.success) {
      setCurrentBalance(res.result);
    }
    setLoading(false);
  };

  const getWalletTransactions = async (pageNumber = 1) => {
    setLoading(true);
    const transactionsRes = await getRequest("wallet/getWalletTxnsByCustId", {
      dateFilter,
      page: pageNumber,
      transactionsFilter,
    });
    if (transactionsRes?.success) {
      setPagination({
        ...transactionsRes.result.pagination,
        page: parseInt(transactionsRes.result.pagination.page),
      });
      setTransactions(transactionsRes.result.allTransactions);
    }
    setLoading(false);
  };
  //        ..................................
  //source: | <~~~~:::STACK OVERFLOW:::~~~~> |function below returns comma seprated amount
  //        ``````````````````````````````````
  function formatNumber(num) {
    let n1, n2;
    num = num + "" || "";
    n1 = num.split(".");
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    return num;
  }

  useEffect(() => {
    getWalletTransactions();
  }, [transactionsFilter]);
  return (
    <div className="user-wallet  pt-4">
      <div className="container">
        <div className="user-wallet-heading text-muted w-100 mb-3 text-start fs-4">
          Subscription Wallet <i className="fas fa-wallet"></i>
        </div>
        <div></div>
        <div className="topup-items row mt-4">
          <UserWalletCard
            heading={`${
              currentBalance?.currency
                ? currencySigns[currentBalance?.currency]
                : currencyConfig.baseCurrencySymbol
            } ${currentBalance ? formatNumber(currentBalance.balance) : "0"}`}
            subHeading="Current Balance"
            description="Add Amount To Your Wallet or Pay Pending Bills using a recharge plan."
            onClick={() => {}}
            loading={loading}
            isBalanceNegative={
              currentBalance && currentBalance?.balance < 0 ? true : false
            }
          />
          <UserWalletCard
            heading="+Add Amount"
            subHeading="Recharge Your Wallet"
            btnLable="Show Recharge Plans"
            description="We offer different recharge plans with varying bonus amounts. Please select the plan that best suits your needs."
            className="success"
            onClick={() => {
              history.push("wallet_recharge");
            }}
          />
        </div>
      </div>
      <div className="container  py-5">
        <div className="">
          <div className="bg-light py-4 px-3 border " id="usage">
            <div ref={scrollRef} id="transactions">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                  <div className="d-flex d-md-block align-items-center justify-content-between ">
                    <h4 className="wallet-subheading m-0 pt-2 ps-0">
                      Transactions:
                    </h4>
                    <div
                      className=" link-primary small pt-md-2 "
                      role="button"
                      onClick={() => {
                        setShowTransactionUsageModal(true);
                      }}
                    >
                      Check Total Usage <i className="fas fa-link ms-2"></i>
                    </div>
                  </div>
                  <br />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                  <div className="row">
                    {/*  */}
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                      <label htmlFor="" className="small mb-1">
                        Select :
                      </label>
                      <select
                        className="form-select"
                        role="button"
                        value={transactionsFilter}
                        onChange={(e) => {
                          setTransactionsFilter(e.target.value);
                        }}
                      >
                        <option value={""}>All Transaction</option>
                        <option value={EnumTransactionFilter.WALLET_RECHARGE}>
                          Wallet Recharge{" "}
                        </option>
                        <option value={EnumTransactionFilter.DAILY_BILL}>
                          Daily Bills{" "}
                        </option>
                        <option
                          value={EnumTransactionFilter.PAN_CARD_VERIFICATION}
                        >
                          Pan Card Verifications{" "}
                        </option>
                        <option value={EnumTransactionFilter.FLIGHT_BOOKING}>
                          Flight Booking{" "}
                        </option>
                        <option value={EnumTransactionFilter.ONLY_DEBIT}>
                          Only Debit{" "}
                        </option>
                        <option value={EnumTransactionFilter.ONLY_CREDIT}>
                          Only Credit{" "}
                        </option>
                        <option value={EnumTransactionFilter.OTHERS}>
                          Others{" "}
                        </option>
                      </select>
                    </div>

                    {/*  */}
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                      <div className="">
                        <label htmlFor="" className="smal mb-1">
                          From :
                        </label>
                        <ReactDatePicker
                          wrapperClassName="w-100"
                          className="form-control"
                          maxDate={dateFilter.toDate}
                          onChange={(date) => {
                            setDateFilter({
                              ...dateFilter,
                              fromDate: moment(date).toDate(),
                            });
                          }}
                          selected={dateFilter.fromDate}
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                      <div className="  ">
                        <label htmlFor="" className="small mb-1">
                          To :
                        </label>

                        <ReactDatePicker
                          wrapperClassName="w-100"
                          className=" form-control"
                          onChange={(date) => {
                            setDateFilter({
                              ...dateFilter,
                              toDate: moment(date).toDate(),
                            });
                          }}
                          maxDate={moment().toDate()}
                          minDate={dateFilter.fromDate}
                          selected={dateFilter.toDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div>
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option>Select Duration</option>
                <option defaultValue="1">Daily</option>
                <option value="2">Monthly</option>
                <option value="3">Yearly</option>
              </select>
            </div> */}
          </div>
        </div>
        <div className="row text-center border-bottom bg-white mx-0 align-items-center">
          <div className="col-2 col-md-1">
            <div className="py-3 bg-white">
              <b>Sr.No.</b>
            </div>
          </div>
          <div className="col-3">
            <div className="py-3 bg-white">
              <b>Date:</b>
            </div>
          </div>
          <div className="col-4 col-md-3">
            <div className="py-3 bg-white">
              {/* <b>No of Bookings / Amount</b>
              <br />
              <span className="text-secondary small">₹## per booking</span> */}
              <b>Debit/Credit:</b>
            </div>
          </div>
          <div className="col-3 col-md-2">
            <div className="py-3 bg-white">
              {/* <b>Other Costs</b> <br /> */}
              {/* <span className="text-secondary small">₹## per search</span> */}
              <b>Balance:</b>
            </div>
          </div>
          <div className="col-12 col-md-2 text-start remark-label">
            {/* <div className="py-3 bg-white">
              <b>Total Cost</b>
            </div> */}
            <b>Remarks:</b>
          </div>
        </div>
        {!loading && transactions?.length ? (
          transactions?.map((transaction, index) => {
            return (
              <div
                className="row text-center bg-white mx-0 border-bottom"
                key={index}
              >
                <div className="col-2 col-md-1 ">
                  <div className="py-4">
                    {index + 1 + (pagination.page - 1) * 10}.
                  </div>
                </div>
                <div className="col-3 ">
                  <div className="py-4">
                    {" "}
                    {moment(transaction.updatedAt).format("LLL")}
                  </div>
                </div>

                <div
                  className={`col-4 col-md-3 ps-3 py-4 ${
                    transaction.transactionType === "debit"
                      ? "text-danger"
                      : "text-success"
                  }`}
                >
                  <b>
                    {transaction.transactionType === "debit" ? "-" : "+"}
                    {formatNumber(transaction.amount)}
                  </b>
                  <span className="text-muted ps-2">
                    ({transaction.transactionType})
                  </span>
                </div>

                <div className="col-3 col-md-2 ">
                  <div className="py-4">
                    {" "}
                    <b>{formatNumber(transaction.last_balance)}</b>{" "}
                  </div>
                </div>
                <div className="col-12 col-md-3 small">
                  <div className=" py-md-4 text-start remark-sm">
                    <b className="remark-sm-label">Remark:</b>{" "}
                    {transaction.remark}
                  </div>
                </div>

                {/* <div className="col-3 ">
                <div className="py-4">50 / ₹98</div>
              </div>
              <div className="col-3 ">
                <div className="">
                  <div
                    className="text-primary other-costs my-4 "
                    onClick={() => {
                      setSelectedOption(index);
                      setOpen(selectedOption === index ? !open : true);
                    }}
                    aria-controls="other-costs-collapse"
                    aria-expanded={selectedOption === index && open}
                  >
                    {`${selectedOption === index && open ? "Hide " : "View "}`}
                    <i className={`fas fa-angle-${open ? "up" : "down"}`}></i>
                  </div>
                  <Collapse in={selectedOption === index && open}>
                    <div id="other-costs-collapse">
                      <table className="w-100 table  table-striped ">
                        <tbody>
                          <tr className="">
                            <th className=" ">key</th>
                            <td>value</td>
                          </tr>
                          <tr>
                            <th className=" ">key</th>
                            <td>value</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Collapse>
                </div>
              </div>
              <div className="col-3 ">
                <div className="py-4 text-danger bold">₹78.98</div>
              </div> */}
              </div>
            );
          })
        ) : !loading ? (
          <div className="text-center text-danger py-2">
            <p>No Transactions Found!</p>
          </div>
        ) : null}
        {loading && (
          <>
            <TransactionsSkelton />
            <TransactionsSkelton />
            <TransactionsSkelton />
            <TransactionsSkelton />
            <TransactionsSkelton />
            <TransactionsSkelton />
            <TransactionsSkelton />
            <TransactionsSkelton />
            <TransactionsSkelton />
          </>
        )}
        <div className="p-1 d-flex justify-content-center my-2">
          {pagination.page !== 1 && (
            <div
              className="btn btn-outline-dark"
              onClick={() => {
                if (pagination.page > 1) {
                  getWalletTransactions(pagination.page - 1);
                }
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </div>
          )}
          <div className="mx-4 py-2">{`${pagination.page} of ${pagination.totalPages} Pages - ${pagination.totalRecords} Records`}</div>
          {pagination.totalPages > 1 &&
            pagination.page !== pagination.totalPages && (
              <div
                className="btn btn-outline-dark"
                onClick={() => {
                  if (pagination.page === pagination.totalPages) {
                    return;
                  }
                  getWalletTransactions(pagination.page + 1);
                }}
              >
                <i className="fas fa-chevron-right"></i>
              </div>
            )}
        </div>
      </div>
      <TransactionUsagePopup
        onHide={() => {
          setShowTransactionUsageModal(false);
        }}
        show={showTransactionUsageModal}
      />
    </div>
  );
};
