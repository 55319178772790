import React from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    color: "#fff",
  },
}));

export default function Loader() {
  const classes = useStyles();
  const showLoader = useSelector((state) => state.common.showLoader);
  return (
    <Backdrop open={showLoader} className={classes.backdrop}>
      <CircularProgress />
    </Backdrop>
  );
}
