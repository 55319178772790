import { useAppSelector } from "hooks/reduxHooks";
import _ from "lodash";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCurrencyWisePrices } from "redux/slices/currencyWisePrices.slice";
import { getRequest } from "tools/ajax";
import {
  IComboPlans,
  ICurrencyWisePrices,
  currencySigns,
  currencyType,
} from "types/wallet.types";
import { ConfirmCurrencyChangeModal } from "../travelEsLite/ConfirmCurrencyChangeModal";

export const ComboPlansScreen: React.FC<{}> = () => {
  const currencyWisePrices: ICurrencyWisePrices | undefined = useAppSelector(
    (state) => state.currencyWisePrices.currencyWisePrices
  );
  const [selectedCurrency, setSelectedCurrency] = useState<
    currencyType | undefined
  >(currencyWisePrices?.currency || currencyType.INR);

  const [showCurrencyChangeModal, setShowCurrencyChangeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comboPlan, setComboPlan] = useState<IComboPlans | undefined>(
    undefined
  );
  const [productPrices, setProductPrices] = useState<
    | {
        product: string;
        price: number;
        currency: currencyType;
        monthlyMinimum: number;
        offeredBalance: number;
      }[]
    | undefined
  >(undefined);
  const dispatch = useDispatch();

  const history = useHistory();
  useEffect(() => {
    getProductPriceWithCurrency();
  }, [selectedCurrency]);

  useEffect(() => {
    getComboPlans();
  }, []);

  let currencySign = selectedCurrency && currencySigns[selectedCurrency];

  const getComboPlans = async () => {
    try {
      const comboPlansRes = await getRequest("wallet/getComboPlans", {});
      if (comboPlansRes?.success) {
        setComboPlan(comboPlansRes.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(comboPlans);
  const getProductPriceWithCurrency = async () => {
    try {
      const pricesResponce = await getRequest(
        "wallet/getProductPriceWithCurrency",
        {
          currency: selectedCurrency,
        }
      );
      setProductPrices(pricesResponce.result);

      // console.log(pricesResponce);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCurrencyChange = async (currency) => {
    const savedCurrency = await getRequest("wallet/getSippiCustomerCurrency", {
      checkingBeforeWebsitePurchase: true,
    });
    if (savedCurrency?.result?.currency) {
      setShowCurrencyChangeModal(true);
    } else {
      setSelectedCurrency(currency);
    }
  };
  let planPrice = _.find(productPrices, {
    product: comboPlan?.planEnum,
  });
  return (
    <div className="">
      <section className="" id="price-plan">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-12">
              <h2 className="text-white fw-bold">Travel Website</h2>
            </div>
            <div className="col-md-6 col-12 text-md-end">
              {/* <h1 className="fs-3">{comboPlan?.planName}</h1> */}
              {/* <div className="d-flex justify-content-md-end mt-5 mt-md-0">
                <select
                  value={selectedCurrency}
                  className="form-select py-1 w-25 mb-3 "
                  aria-label="Default select example"
                  name="selectedCurrency"
                  onChange={(e) => {
                    handleCurrencyChange(e.target.value);
                    // setSelectedCurrency(e.target.value);
                  }}
                >
                  <option value={currencyType.INR}>{currencyType.INR}</option>
                  <option value={currencyType.USD}>{currencyType.USD}</option>
                </select>
              </div> */}
              <h2 className="text-white my-2">
                {currencySign}{" "}
                {loading
                  ? "Loading..."
                  : planPrice?.price
                  ? planPrice.price
                  : "Not Found"}
              </h2>
              {/* <span className="text-muted">
                        {currencySign}
                        {planPrice?.monthlyMinimum} / month
                      </span> */}

              <div>
                <button
                  className="btn btn-primary px-5 rounded-0 py-2"
                  onClick={() => {
                    dispatch(
                      setCurrencyWisePrices({
                        ...currencyWisePrices,
                        currency: selectedCurrency,
                      })
                    );
                    history.push(`/buyTravelWebsite`);
                  }}
                >
                  Purchase Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmCurrencyChangeModal
        setCurrentCurrency={setSelectedCurrency}
        show={showCurrencyChangeModal}
        temorarerlySelectedCurrency={selectedCurrency}
        onHide={() => setShowCurrencyChangeModal(false)}
      />
      <div className="container">
        <h3 className="text-center">Purchase Includes</h3>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <ul className="plan-points h-100">
              <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  Travel Website <br />
                  <span className="text-muted small">
                    ( Live Flights Booking )
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  {currencySign}
                  {planPrice?.offeredBalance} Subscription Wallet
                  <br />
                  <span className="text-muted small">( For Site Usage )</span>
                </div>
              </li>
              <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  MAYA Travels Flight API included <br />
                  <span className="text-muted small">
                    (MAYA Travels Flight API will be included by default)
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  CRM <br />
                  <span className="text-muted small">
                    (Customer Relationship Management)
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <ul className="plan-points h-100">
              <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  Multiple-Staffs <br />
                  <span className="text-muted small">
                    (Create multiple staff members other than admin.)
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  Multiple Markup Groups <br />
                  <span className="text-muted small">
                    (Create multiple markup groups)
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  SMTP <br />
                  <span className="text-muted small">
                    (Get SMTP integration.)
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  SEO Friendly <br />
                  <span className="text-muted small">
                    (Get a SEO friendly website)
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <ul className="plan-points h-100">
              <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  Google Tag Manager <br />
                  <span className="text-muted small">
                    (Manage all your website tags without editing code.)
                  </span>
                </div>
              </li>
              {/* <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  RBAC <br />
                  <span className="text-muted small">
                    (Get Role-based Access Control (RBAC))
                  </span>
                </div>
              </li> */}
              <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                  Multiple Payment Gateways <br />
                  <span className="text-muted small">
                    (Make Payments Easier With Multiple Payment Gateways.)
                  </span>
                </div>
              </li>
              {/* <li>
                <div>
                  <i
                    className={`fas fa-check  ${
                      comboPlan?.planEnum === "comboPlan2"
                        ? "text-white"
                        : "text-success"
                    }`}
                  ></i>
                </div>
                <div>
                Own Inventory + Supplier
                </div>
              </li> */}
            </ul>
          </div>
        </div>
        {/* {comboPlan?.description.map((item, index) => {
          return (
            <li key={index}>
              <div>
                <i
                  className={`fas fa-check  ${
                    comboPlan?.planEnum === "comboPlan2"
                      ? "text-white"
                      : "text-success"
                  }`}
                ></i>
              </div>

              <div className="description">{item.heading}</div>
            </li>
          );
        })}
        {comboPlan?.excludedItems.map((item, index) => {
          return (
            <div className="disable-feature" key={index}>
              <li>
                <div>
                  <i className="fas fa-times text-danger"></i>{" "}
                </div>
                <div>{item.heading}</div>
              </li>
            </div>
          );
        })} */}
      </div>
    </div>
  );
};
