import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

export const AdminMasterMenu = () => {
  return (
    <div className="container">
      <ListGroup horizontal>
        <Link to="/admin/master/mongo_country_cities">
          <ListGroup.Item>Mongo Cities</ListGroup.Item>
        </Link>
        <Link to="/admin/master/mysql_country_cities">
          <ListGroup.Item>Mysql Cities & countries</ListGroup.Item>
        </Link>
      </ListGroup>
    </div>
  );
};
