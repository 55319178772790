import React, { useRef } from "react";
import Select, { components } from "react-select";
export interface IOption {
  label: string;
  value: string;
  [key: string]: any;
  email?: string;
  name?: string;
  givenName?: string;
  familyName?: string;
  imageUrl?: string;
}

export const SelectWrapper: React.FC<{
  options: Array<IOption>;
  onChange: (value: any) => void;
  styles?: any;
  onInputChange?: (input: string) => void;
  value?: string | undefined;
  className?: string;
  controlClassName?: string;
  inputClassName?: string;
  isLoading?: boolean;
  placeholder?: string;
  components?: any;
}> = (Props) => {
  const timer = useRef<NodeJS.Timer>();

  const customStyles = {
    control: (base) => ({
      ...base,
      paddingTop: "4px",
      paddingBottom: "4px",
      borderRadius: "5px",
    }),
  };

  return (
    <Select
      placeholder={Props.placeholder}
      onChange={(selected) => {
        Props.onChange(selected);
      }}
      onInputChange={(input) => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
          Props.onInputChange && Props.onInputChange(input);
        }, 500);
      }}
      className={`${Props.className} text-dark`}
      defaultValue={Props.options?.filter((option) => {
        return option.value === Props.value;
      })}
      options={Props.options}
      isClearable
      isLoading={Props.isLoading}
      styles={Props.styles ? Props.styles : customStyles}
      components={{
        Option: (props) => {
          return (
            <div className="option">
              <components.Option className="border-bottom" {...props}>
                {props.data.email &&
                props.data.name &&
                props.data.givenName &&
                props.data.familyName ? (
                  <>
                    Email:
                    <b>
                      <span>{props.data.email}</span>
                    </b>
                    <br />
                    Name:
                    <b>
                      <span>{props.data.name}</span>
                    </b>
                    <br />
                    Given Name:
                    <b>
                      <span>{props.data.givenName}</span>
                    </b>
                    <br />
                    Family Name:
                    <b>
                      <span>{props.data.familyName} </span>
                    </b>
                  </>
                ) : (
                  <>{props.label}</>
                )}
              </components.Option>
            </div>
          );
        },
      }}
    />
  );
};
