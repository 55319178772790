import {packageTypes} from 'redux/actionTypes/package.types'

export const GET_ALL_PACKAGES = () => {
    return {
        type: packageTypes.GET_ALL_PACKAGES
    }
}

export const SET_ALL_PACKAGES = (data) => {
    return {
        type: packageTypes.SET_ALL_PACKAGES,
        payload: data
    }
}