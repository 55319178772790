//import { Button } from '@material-ui/core'
import React from "react";
import { List } from "semantic-ui-react";
export default function ListCountry(props) {
  return (
    <List.Item>
      <List.Content>
        {props.country.countryName}
        {/* <Button
                 color='primary'
                 size='small'
                 variant='contained'
                 style={{float:'right'}}
                 onClick={()=>toast.error("Clicked!")}
                >
                    Edit
                </Button> */}
      </List.Content>
    </List.Item>
  );
}
