import { FullScreenLoader } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import moment from "moment";
import { useEffect, useState } from "react";
import { getRequest } from "tools/ajax";

interface ISiteWiseBillingUsage {
  id: number;
  serviceEnum: string;
  remark: string;
  serviceUnits: number;
  siteId: string;
  sippiCustomerId: string;
  createdAt: string;
  updatedAt: string;
  isUsed: true;
}

export const SiteWiseBillingUsage: React.FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [siteWiseBillingUsage, setSiteWiseBillingUsage] = useState<
    ISiteWiseBillingUsage[] | undefined
  >(undefined);
  const [domain, setDomain] = useState<string | undefined>(undefined);
  useEffect(() => {
    getSiteWiseBillingSummary();
  }, []);

  const [pagination, setPagination] = useState<{
    perPage: number;
    recordsToSkip: number;
    page: number;
    totalRecords: number;
    totalPages: number;
  }>({
    perPage: 10,
    recordsToSkip: 0,
    page: 1,
    totalRecords: 0,
    totalPages: 1,
  });

  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);

  // Accessing individual parameters
  const siteId = queryParams.get("siteId");
  const fromDate = queryParams.get("fromDate");
  const toDate = queryParams.get("toDate");

  const getSiteWiseBillingSummary = async (pageNumber = 1) => {
    // setLoading(true);
    // try {
    //   if (!fromDate || !toDate || !siteId) {
    //     return;
    //   }
    //   const siteWiseBillingSummaryRes = await getRequest(
    //     "billing/getSiteWiseBillingSummaryRes",
    //     {
    //       siteId,
    //       fromDate,
    //       toDate,
    //       pageNumber,
    //     }
    //   );
    //   if (siteWiseBillingSummaryRes?.result?.sippiServiceUsage) {
    //     setSiteWiseBillingUsage(
    //       siteWiseBillingSummaryRes?.result?.sippiServiceUsage
    //     );
    //   }
    //   if (siteWiseBillingSummaryRes?.result?.customerDomain) {
    //     setDomain(siteWiseBillingSummaryRes?.result?.customerDomain);
    //   }
    //   if (siteWiseBillingSummaryRes?.result?.pagination) {
    //     setPagination({
    //       ...siteWiseBillingSummaryRes.result.pagination,
    //       page: parseInt(siteWiseBillingSummaryRes.result.pagination.page),
    //     });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    // setLoading(false);
  };

  return (
    <div className="container ">
      <FullScreenLoader show={loading} />
      <div className="alert alert-warning" role="alert">
        This page is under-maintenance after monthly billing is shifted to daily
        billing
      </div>
      <h3>Billing usage</h3>
      <b>Domain :</b> {domain} <br />
      <b>Date: </b> {moment(fromDate).format("DD MMM YYYY")} <br />
      <b>Time Slot :</b> {moment(fromDate).format("hh:mm A")} ~{" "}
      {moment(toDate).format("hh:mm A")}
      <div className="row my-2 bg-light p-2 ">
        <div className="col-2">
          <b>index</b>{" "}
        </div>
        <div className="col-2">
          <b>Service Enum</b>{" "}
        </div>
        <div className="col-2">
          <b>serviceUnits</b>{" "}
        </div>
        <div className="col-2">
          <b>isUsed</b>{" "}
        </div>
        <div className="col-2">
          <b>CreatedAt</b>{" "}
        </div>
        <div className="col-2">
          <b>UpdatedAt</b>{" "}
        </div>
      </div>
      {siteWiseBillingUsage &&
        siteWiseBillingUsage.map((item, key) => {
          return (
            <div className="row border-bottom p-3 mb-2 " key={key}>
              <div className="col-2">
                {" "}
                {key + 1 + (pagination.page - 1) * 10}.
              </div>
              <div className="col-2">{item?.serviceEnum}</div>
              <div className="col-2">{item?.serviceUnits}</div>
              <div className="col-2">
                {item?.isUsed ? (
                  <b className="text-success">True</b>
                ) : (
                  <b className="text-danger">False</b>
                )}
              </div>
              <div className="col-2">
                {moment(item?.createdAt).format("lll")}
              </div>
              <div className="col-2">
                {moment(item?.updatedAt).format("lll")}
              </div>
            </div>
          );
        })}
      {!siteWiseBillingUsage?.length && (
        <p className="mt-4 text-center">No Usage Found</p>
      )}
      <div className="p-1 d-flex justify-content-center my-2 ">
        {pagination.page !== 1 && (
          <div
            className="btn btn-outline-dark"
            onClick={() => {
              if (pagination.page > 1) {
                getSiteWiseBillingSummary(pagination.page - 1);
              }
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </div>
        )}
        <div className="mx-4 py-2">{`${pagination.page} of ${pagination.totalPages} Pages - ${pagination.totalRecords} Records`}</div>
        {pagination.totalPages > 1 &&
          pagination.page !== pagination.totalPages && (
            <div
              className="btn btn-outline-dark"
              onClick={() => {
                if (pagination.page === pagination.totalPages) {
                  return;
                }
                getSiteWiseBillingSummary(pagination.page + 1);
              }}
            >
              <i className="fas fa-chevron-right"></i>
            </div>
          )}
      </div>
    </div>
  );
};
