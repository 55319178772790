import React from "react";
import { Modal } from "react-bootstrap";
import { ISippiCustomerWithPurchase } from "types/sippiCustomer.types";

export const BillingDetailModal: React.FC<{
  show: boolean;
  selectedSippiCustomer: ISippiCustomerWithPurchase;
  setSelectedCustomer: (undefined) => void;
}> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.setSelectedCustomer(undefined)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Billing Details</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {props.selectedSippiCustomer.purchases &&
        props.selectedSippiCustomer.purchases.form ? (
          <div className="table-responsive">
            <table className="table-bordered table ">
              <tbody>
                {Object.keys(props.selectedSippiCustomer?.purchases?.form).map(
                  (x, i) => (
                    <tr key={i}>
                      <td>
                        <b>{x}</b>
                      </td>
                      <td>{props.selectedSippiCustomer?.purchases?.form[x]}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-danger">No Purchase made by this user yet!</p>
        )}
      </Modal.Body>
    </Modal>
  );
};
