import React from 'react'
import Dialog from 'commonUi/dialog/Index'

export default function LinkSippiCustDialog(props) {
    const {selectedWl: w} = props
    return (
        <Dialog isOpen={props.isOpen} 
        handleClose={props.handleClose}
        title="link Website to Sippi"
        >
            <>
            <b>Domain:</b> {w.domain}
            <pre>
            {JSON.stringify(w)}
            </pre>
            
            </>
        </Dialog>
    )
}