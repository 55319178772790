import { getRequest, postRequest } from "tools/ajax";

/**
 * OLD API CALLS START-----------------------------
 * Below given are the old api calls which were being used earlier
 * and these are being stored in mongodb
 * these are stored manually as well
 */
export const getCountries = async () => {
  const r = await getRequest("admin/master/getCountries", {});
  return r;
};

export const getCities = async () => {
  return await getRequest("admin/master/getCities", {});
};

/**
 * OLD API CALLS END-----------------------------
 */

export class MasterApi {
  static getCountries = async () => {
    const response = await getRequest("master/getCountries", {});
    return response;
  };

  static getCitiesByCountryCode = async (countryCode: string) => {
    const response = await getRequest("master/getCitiesByCountryCode", {
      countryCode,
    });
    return response;
  };

  static saveCity = async (props: {
    city: string;
    lat: number;
    lon: number;
    country: string;
    country_iso2: string;
  }) => {
    const response = await postRequest("master/saveCity", props);
    return response;
  };

  //delete city
  static deleteCity = async (city: any) => {
    const response = await postRequest("master/deleteCity", { city });
    return response;
  };

  static getCityLogs = async (pageNo: number) => {
    const response = await postRequest("master/getCityLogs", { pageNo });
    return response;
  };
}
