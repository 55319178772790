import { postRequest } from "tools/ajax";

// export const sendTestMail = async () => {
//     return await postRequest('user/testEmail')
// }

export const saveSiteSmtpDetails = async (data) => {
  return await postRequest("siteSettings/saveSiteSmtpDetails", data);
};

export const getSiteSmtpDetails = async (data) => {
  return await postRequest("siteSettings/getSiteSmtpDetails", data);
};

export const deleteSmtpDetail = async (data) => {
  return await postRequest("siteSettings/deleteSmtpDetailById", data);
};

export const sendTestEmail = async (form, smtp) => {
  console.log(form, smtp);
  let mailObj = {
    emailConfig: {
      host: smtp.host,
      port: smtp.port,
      user: smtp.user,
      pass: smtp.pass,
      secure: smtp.secure,
    },
    senderName: smtp.senderName,
    senderEmail: smtp.user,
    to: form.recipient,
    subject: form.subject,
    text: form.body,
    // html
  };
  // return mailObj
  return await postRequest("notifications/sendMail", mailObj);
};

export const addTboCredentialsApiCall = async (data) => {
  return await postRequest("siteSettings/addTboCredentials", data);
};

export const getTboCredentialsApiCall = async () => {
  return await postRequest("siteSettings/getTboCredentialsToStaff");
};

export const addBankDetailsApiCall = async (data) => {
  return await postRequest("siteSettings/addBankDetails", data);
};

export const getBankDetailsApiCall = async (data) => {
  return await postRequest("siteSettings/getBankDetails", data);
};

export const addRazorpayDetailsApiCall = async (data) => {
  return await postRequest("siteSettings/addRazorpayDetails", data);
};
export const getRazorpayDetailsApiCall = async () => {
  return await postRequest("siteSettings/getRazorpayDetailsToStaff");
};
export const getActivePaymentGatewayApiCall = async (data) => {
  return await postRequest("siteSettings/getActivePaymentGateway", data);
};
export const addICICIDetailsApiCall = async (data) => {
  return await postRequest("siteSettings/addIciciDetails", data);
};
export const getICICIDetailsApiCall = async () => {
  return await postRequest("siteSettings/getIciciDetailsToStaff");
};
export const getHDFCCCAvenueDetailsApiCall = async () => {
  return await postRequest("siteSettings/getHDFCCCAvenueDetailsToStaff");
};
export const addHDFCCCavenueDetailsApiCall = async (data) => {
  return await postRequest("siteSettings/addHDFCCCavenueDetails", data);
};
export const getCashfreeDetailsApiCall = async () => {
  return await postRequest("siteSettings/getCashfreeDetailsToStaff");
};
export const addCashfreeDetailsApiCall = async (data) => {
  return await postRequest("siteSettings/addCashfreeDetails", data);
};

export const getPhonepeDetailsApiCall = async () => {
  return await postRequest("siteSettings/getPhonepeDetailsToStaff");
};
export const addPhonepeDetailsApiCall = async (data) => {
  return await postRequest("siteSettings/addPhonepeDetails", data);
};

export const getAccountsReportForWlApiCall = async () => {
  return await postRequest("accounts/getAccountsReportForWl");
};
export const getlook2BookApiCall = async () => {
  return await postRequest("accounts/getBookToLookRatio");
};
export const getlook2BookReportApiCall = async ({ fromDate, toDate }) => {
  return await postRequest("accounts/getBookToLookReport", {
    fromDate,
    toDate,
  });
};
export const verifyBillingPaymentApiCall = async ({ orderId }) => {
  return await postRequest("accounts/verifyPayment", {
    orderId,
  });
};
export const paySippiMonthlyFee = async ({ billingId }) => {
  return await postRequest("accounts/getBookToLookReport", {
    billingId,
  });
};
export const addPGChargesApiCall = async (data) => {
  return await postRequest("siteSettings/addPaymentGatewayCharges", data);
};
export const getPGChargesApiCall = async (data) => {
  return await postRequest("siteSettings/getPaymentGatewayCharges", data);
};
export const getAllPaymentGatewaysChargesApiCall = async (data) => {
  return await postRequest("siteSettings/getAllPaymentGatewaysCharges", data);
};
export const saveSelectedPaymentGatewayApiCall = async (data) => {
  return await postRequest("siteSettings/saveSelectedPaymentGateway", data);
};

export const addGtmTagApiCall = async (data) => {
  return await postRequest("siteSettings/addGtmTag", data);
};
export const addHomePageBuilderApiCall = async (data) => {
  return await postRequest("siteSettings/addHomePageBuilder", data);
};
export const getHomePageBuilderCodeApiCall = async (data) => {
  return await postRequest("siteSettings/getHomePageBuilderCode", data);
};
