import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { toast } from "react-toastify";

import moment from "moment";
import { date } from "tools/constants";
import {
  getAllWalletEntries,
  postWhitelabelWallet,
} from "apiCalls/whitelabelDb";
import Pagination from "@material-ui/lab/Pagination";
import { FullScreenLoader } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader";
import { useHistory, useParams } from "react-router-dom";
import { SearchCustomer } from "../SearchCustomer";
import { AdminRouteConstants } from "constants/routes";
import Button from "commonUi/button/btnPrimary";
import GridItem from "commonUi/grid/item";
import TxtInp from "commonUi/input/text";
import { printAmount } from "tools/commonFuncions";
import { IPagination } from "components/admin/flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/types";
import exportFromJSON from "export-from-json";
import axios from "axios";

export interface IWalletPagination extends IPagination {
  data: {
    records: IWalletTransaction[];
    lastBalance: number;
  };
}

export interface IWalletTransaction {
  id: number;
  siteId: string;
  companyName: string;
  siteName: string;
  meta: string;
  type: string;
  amount: number;
  lastBalance: number;
  paidForService?: any;
  createdAt: Date;
  updatedAt: Date;
  paymentMode: string;
}

const useStyles = makeStyles({
  gridContainer: {
    marginTop: "1rem",
  },
  searchInp: {
    margin: "10px auto",
    width: "100%",
  },
  imgHeight: {
    maxHeight: "100px",
    maxWidth: "100%",
    width: "auto",
    height: "auto",
    margin: "1px auto",
    objectFit: "cover",
  },
});

const exportType = "xls";

export const AllEntries = (props) => {
  const classes = useStyles();

  const [walletPaginationState, setWalletPaginationState] =
    useState<IWalletPagination>();
  // const [allEntries, setAllEntries] = useState([])
  const [pageState, setPageState] = React.useState(1);
  const [loadingState, setLoadingState] = React.useState(false);
  const [whitelabelState, setWhitelabelState] = React.useState<any>();
  const [amount, setAmount] = useState(0);
  const [remark, setRemark] = useState("");
  const [debitCredit, setDebitCredit] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [tboBalanceState, setTboBalanceState] = useState(0);
  const [allWhitelabelsState, setAllWhitelabelsState] = useState<Array<any>>(
    []
  );
  const [transactingWhitelabelsState, setTransactingWhitelabelsState] =
    useState<Array<any>>([]);

  let routeParams: any = useParams();
  let history: any = useHistory();

  useEffect(() => {
    axios({
      url: "/api/admin/whitelabel/all",
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
      },
    })
      .then((res) => {
        setAllWhitelabelsState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (walletPaginationState) {
      setPageState(walletPaginationState?.currentPage);
    }
  }, [walletPaginationState]);

  useEffect(() => {
    if (walletPaginationState && allWhitelabelsState) {
      setTransactingWhitelabelsState(
        allWhitelabelsState.filter((wl) => {
          return walletPaginationState.data.records.find(
            (w) => w.siteId === wl._id
          );
        })
      );
    }
  }, [walletPaginationState, allWhitelabelsState]);

  const callGetAllWalletEntries = useCallback(async () => {
    setLoadingState(true);

    const res = await getAllWalletEntries({
      page: pageState,
      siteId: routeParams.siteId,
    });
    setLoadingState(false);

    // const allEntriess = res.reverse()
    // console.log(allEntriess)
    if (res.success) {
      setWalletPaginationState(res.result);
      setTboBalanceState(res.result.tboAgencyBalance);
    } else {
      setWalletPaginationState(undefined);
    }
  }, [pageState, routeParams.siteId]);

  useEffect(() => {
    if (!routeParams.siteId) {
      setWhitelabelState(undefined);
    }
    callGetAllWalletEntries();
  }, [pageState, routeParams.siteId, callGetAllWalletEntries]);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const handleDebitCreditChange = (e) => {
    setDebitCredit(e.target.value);
    console.log(e.target.value);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (value !== pageState) {
      setPageState(value);
    }
  };

  const TablePagination = () => (
    <Pagination
      style={{
        float: "right",
        marginTop: 15,
      }}
      color="primary"
      count={walletPaginationState?.totalPages || 0}
      page={pageState}
      onChange={handlePageChange}
    />
  );

  const setWl = (whitelabel) => {
    if (whitelabel) {
      setWhitelabelState(undefined);
      setWhitelabelState(whitelabel);
      history.push(`${AdminRouteConstants.ADMIN_WALLET}/${whitelabel._id}`);
    } else {
      setWhitelabelState(undefined);
      history.push(`${AdminRouteConstants.ADMIN_WALLET}/`);
    }
  };

  const submitAmount = async (e) => {
    e.preventDefault();
    if (amount > 500000 || amount < 1)
      return toast.error("Amount cannot exceed 5 lakhs or can be less than 0");
    if (debitCredit === "")
      return toast.error("Please Select Debit/Credit Amount");
    setDisabled(true);

    const data = {
      siteId: whitelabelState._id,
      amount: amount,
      meta: remark,
      type: debitCredit,
    };
    setLoadingState(true);
    const result = await postWhitelabelWallet(data);
    if (!result || !result.success) {
      toast.error("Transaction Failed!");
      setDisabled(false);
    } else {
      callGetAllWalletEntries();
    }
    setAmount(0);
    setRemark("");
    setDebitCredit("");
    setDisabled(false);
  };

  const exportTransactionsToExcel = (siteId: string, companyName: string) => {
    if (walletPaginationState?.data.records) {
      // let filesExported = 0;
      // allWhitelabelsState.forEach((wlabel) => {
      let wltxns = walletPaginationState?.data.records
        .filter((txn) => txn.siteId === siteId)
        .map((txn) => {
          return {
            id: txn.id,
            companyName: txn.companyName,
            meta: txn.meta,
            type: txn.type,
            amount: txn.amount,
            balance: txn.lastBalance,
            createdAt: txn.createdAt,
            updatedAt: txn.updatedAt,
            paymentMode: txn.paymentMode,
            PNR: txn.paidForService?.pnr || "",
          };
        });
      if (wltxns.length > 0) {
        // filesExported++;
        exportFromJSON({
          data: wltxns,
          fileName: companyName,
          exportType,
        });
      }
      // });
      // toast.error(filesExported + " Site Transactions downloaded");
    }
  };

  return (
    <>
      <FullScreenLoader show={loadingState} />

      <Container>
        <SearchCustomer setWhitelabel={setWl} />

        <Typography variant="h5" className="mb-3">
          {tboBalanceState !== undefined
            ? // `TBO Balance: ${Intl.NumberFormat("en-IN").format(
              //     parseFloat(tboBalanceState)
              //   )}`
              `TBO Balance:  ₹ ${Intl.NumberFormat("en-IN").format(
                parseFloat(tboBalanceState.toString())
              )}`
            : `Unable to get TBO Balance`}
        </Typography>

        <Typography variant="h5">All Whitelabel Wallet Entries</Typography>

        {walletPaginationState?.data.records.length && (
          <>
            {transactingWhitelabelsState.map((wl) => {
              return (
                <button
                  className="btn btn-secondary d-block my-2"
                  onClick={() =>
                    exportTransactionsToExcel(wl._id, wl.companyName)
                  }
                >
                  Download {wl.companyName}
                </button>
              );
            })}
            {walletPaginationState?.totalRecords} Records Found
            {/* <button
              className="btn btn-primary"
              onClick={() => {
                exportTransactionsToExcel();
              }}
            >
              Download
            </button> */}
          </>
        )}

        {whitelabelState &&
          !loadingState &&
          Object.keys(whitelabelState).length !== 0 &&
          whitelabelState.constructor === Object && (
            <GridItem xs={12} sm={6}>
              <Grid container justify="center">
                <Grid item xs={12} md={6} className="text-center">
                  <img
                    src={whitelabelState.logoUrl}
                    alt={whitelabelState.companyName}
                    className={classes.imgHeight}
                  />
                </Grid>
                <Grid item xs={12} md={6} className="text-center">
                  <Typography variant="h6" color="initial">
                    {whitelabelState.companyName}
                  </Typography>
                  <Typography color="initial">
                    Domain: {whitelabelState.domain}
                  </Typography>
                  <Typography color="initial">
                    Site: {whitelabelState.siteName}
                  </Typography>
                  <Typography variant="h3" color="initial">
                    ₹{" "}
                    {printAmount(
                      whitelabelState &&
                        walletPaginationState?.data.records.length
                        ? walletPaginationState?.data.lastBalance
                        : 0
                    )}
                  </Typography>
                </Grid>

                <form onSubmit={submitAmount} autoComplete="off">
                  <TxtInp
                    type="number"
                    name="amount"
                    placeholder="Enter Amount"
                    value={amount}
                    className={classes.searchInp}
                    label="Amount"
                    onWheel={(event) => event.target.blur()}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    onChange={handleAmountChange}
                  />
                  <RadioGroup
                    aria-label="Action"
                    name="gender1"
                    value={debitCredit}
                    onChange={handleDebitCreditChange}
                  >
                    <FormControlLabel
                      value="debit"
                      control={<Radio />}
                      label="Debit Amount"
                    />
                    <FormControlLabel
                      value="credit"
                      control={<Radio />}
                      label="Credit Amount"
                    />
                  </RadioGroup>
                  <TxtInp
                    name="remark"
                    value={remark}
                    className={classes.searchInp}
                    label="Remarks"
                    required
                    multiline
                    rows={5}
                    onChange={handleRemarkChange}
                  />
                  {!disabled && (
                    <Button onClick={undefined} label="Submit" type="submit" />
                  )}
                </form>
              </Grid>
            </GridItem>
          )}

        <TablePagination />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>TxnId</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Domain</TableCell>
                <TableCell>Credit</TableCell>
                <TableCell>Debit</TableCell>
                <TableCell>Ticket PNR</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Remark</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletPaginationState?.data.records.map((entry, index) => (
                <TableRow key={entry.id}>
                  <TableCell>{entry.id}</TableCell>
                  <TableCell>
                    {moment(entry.createdAt).format(date.dateTimeFormat)}
                  </TableCell>
                  <TableCell>
                    {entry.siteName}
                    <br />
                    {entry.companyName}
                  </TableCell>
                  <TableCell>
                    {entry.type === "credit" && entry.amount}
                  </TableCell>
                  <TableCell>
                    {entry.type === "debit" && entry.amount}
                  </TableCell>
                  <TableCell>{entry.paidForService?.pnr}</TableCell>
                  <TableCell>{entry.lastBalance}</TableCell>

                  <TableCell>{entry.meta}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination />
      </Container>
    </>
  );
};
