import React from "react";

import { Link } from "react-router-dom";
import { L2BCalculator } from "./L2BCalculator";

export const PlatformAndUsageFee = () => {
  return (
    <div className="container pt-5">
      <h1 className="mb-2">Platform and Usage Fee</h1>
      <p className="text-danger fw-bold">
        18% GST will be applicable on the charges mentioned on this page.
      </p>
      <div className="alert alert-warning" role="alert">
        <b>Important!</b> Users must maintain a sufficient balance in their
        wallet to ensure uninterrupted website services. In the event of an
        insufficient balance, users will be given 24 hours to rectify the
        negative balance. Failure to settle the dues within this timeframe will
        result in automatic suspension of associated website services until the
        outstanding balance is cleared.
      </div>

      {/* <h2 className="h4">Setup Fee</h2> */}
      {/* <div className=" mt-3 ">
        <b className="fs-1 text-warning">₹5,100 one time</b>
      </div>
      <p>
        This is the fee paid at the time of setup of the new account/website.
      </p>

      <h2 className="h4">Monthly Maintenance and Usage Fee</h2>
      <b className="fs-4 text-warning">starts from ₹500/month </b>
      <p>
        This fee is charged on a monthly basis as per the usage of
        services/website features. This fee also includes basic maintenance
        costs example server fee etc. This fee excludes the cost of development
        of products/software/website/tools.  ₹500/month is the minimum amount
        that will be charged every month. This amount will be calculated based
        on the below chart.
      </p>

      <table className="table shadow">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Service</th>
            <th scope="col">Charges</th>
            <th scope="col">Remark</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Air Ticket</td>
            <td>₹10/segment/passenger</td>
            <td></td>
          </tr>
          <tr>
            <td>Fixed Departure (For Tickets below ₹10,000)</td>
            <td>₹10/ticket</td>
            <td>Ticket amount ₹1 - ₹10,000</td>
          </tr>
          <tr>
            <td>Fixed Departure (For Tickets below ₹20,000)</td>
            <td>₹20/ticket</td>
            <td>Ticket amount ₹10,001 - ₹20,000</td>
          </tr>
          <tr>
            <td>Fixed Departure (For Tickets below ₹30,000)</td>
            <td>₹30/ticket</td>
            <td>Ticket amount ₹20,001 - ₹30,000</td>
          </tr>
          <tr>
            <td>Fixed Departure (For Tickets below ₹40,000)</td>
            <td>₹40/ticket</td>
            <td>Ticket amount ₹30,001 - ₹40,000</td>
          </tr>
          <tr>
            <td>Fixed Departure (For Tickets below ₹50,000)</td>
            <td>₹50/ticket</td>
            <td>Ticket amount ₹40,001 - ₹50,000</td>
          </tr>
          <tr>
            <td>Fixed Departure (For Tickets below ₹1,00,000)</td>
            <td>₹100/ticket</td>
            <td>Ticket amount ₹50,001 - ₹100,000</td>
          </tr>
        </tbody> */}
      {/* </table> */}
      {/* <div className="lookToBook shadow p-2 my-4">
        <h2 className="h4">Look to Book Ratio (30:1)</h2>
        <b className="fs-4 text-warning">₹0.25/extra search </b>
        <p className="">
          This fee is charged when the number of searches done by the website
          exceeds the look to book ratio of 30:1.
          <br />
          Example 1: If the website has 30 searches and 1 booking, then no extra
          fee will be charged. As the ratio is 30:1.
          <br />
          Example 2: If the website has 31 searches and 1 booking, then an extra
          fee of ₹0.25/extra search will be charged. As the ratio is 31:1. So
          there is an extra search.
          <br />
          Example 3: If the website has 100 searches and 2 bookings, then an
          extra fee of ₹10 will be charged. As there are 40 extra searches.
        </p>
        <L2BCalculator />
      </div> */}
      {/* android app charges */}
      {/* <div className="shadow p-2 my-4">
        <h2 className="h4">Mobile App (Andriod WebView)</h2>
        <b className="fs-4 text-warning"> ₹5,000 one time </b>
        <p>
          This is the fee paid at the time of setup of the mobile app. If you
          want to upload the app to the play store from your own developer
          account then the registration fee for the play store account is not
          included in our service charges. You will pay directly to the
          google/play store for any extra charges.
        </p>
        <p>
          The app will be a simple web view (an exact copy of the website) and
          will not contain any app-specific features.
        </p>
        <p>
          An additional charge of ₹500 will be applicable for any software
          update or version update or any branding/information changes from your
          end.
        </p>
      </div> */}
      <div className="service-charges bg-white border rounded p-3 p-sm-5 pt-4 shadow">
        <div className="row border-bottom bg-light mb-4 labelbar">
          <div className="col-3 py-3">Product</div>
          <div className="col-3 py-3">One Time Cost</div>
          <div className="col-2 py-3">Daily Charges</div>
          <div className="col-4 py-3">
            Usage Charges (for flight booking)
            <br />
          </div>
        </div>
        <div className="row border-bottom pb-3">
          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <h1 className="m-0 text-primary">Website</h1>
            <p className="small text-secondary">
              <ul>
                <li>
                  This is the fee paid at the time of setup of the new
                  account/website.
                </li>
                <li>
                  This amount will be transfered to your wallet after website
                  purchase.
                </li>
              </ul>
            </p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <span className="mobilelabel">One Time Cost</span>
            <h1 className="p-0 m-0">₹20,000</h1>
            <p className="small text-success alert alert-success mt-2 p-2">
              Bonus : <b className="">₹5000</b> Subscription Wallet
            </p>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-12 mt-3 mt-md-0">
            <span className="mobilelabel">Daily Charges</span>
            <h1 className="m-0">₹ 20</h1>
          </div>
          <div className="col-lg-4 col-md-2 col-sm-12 col-12 mt-3 mt-md-0">
            <span className="mobilelabel">
              Usage Charges (for flight booking)
            </span>
            <h1 className="m-0">
              ₹ 10{" "}
              <span className="small fs-6 fw-normal">
                / Segment per Passenger
              </span>
            </h1>
          </div>
        </div>
        <div className="row py-3 mb-4">
          <div className="col-lg-3 col-md-4 col-sm-12 col-12">
            <h1 className="m-0 text-primary">Android App</h1>
            <p className="small text-secondary">Android WebView</p>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 col-12 mt-3 mt-md-0">
            <span className="mobilelabel">One Time Cost</span>
            <h1 className="m-0">₹ 10,000</h1>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 col-12 mt-3 mt-md-0">
            <span className="mobilelabel">Daily Charges</span>
            <h1 className="m-0">₹ 10</h1>
          </div>
          <div className="col-12 col-md-12 col-sm-12 col-12">
            <p className="p-0 mt-3 text-secondary small">
              This is the fee paid at the time of setup of the mobile app. If
              you want to upload the app to the play store from your own
              developer account then the registration fee for the play store
              account is not included in our service charges. You will pay
              directly to the google/play store for any extra charges. The app
              will be a simple web view (an exact copy of the website) and will
              not contain any app-specific features. An additional charge of
              ₹500 will be applicable for any software update or version update
              or any branding/information changes from your end.
            </p>
          </div>
        </div>
        <div className="row border bg-light">
          <div className="col-3 py-3">Product</div>
          <div className="col-3 py-3">One Time Cost</div>
          <div className="col-3 py-3">Daily Charges</div>
          <div className="col-3 py-3 border-start">Usage Charges</div>
        </div>
        <div className="row border">
          <div className="col-3 py-3">
            <h5 className="m-0">TBO API</h5>
          </div>
          <div className="col-3 py-3">₹20000</div>
          <div className="col-3 py-3">₹20</div>
          <div className="col-3 bg-light border-start py-3">
            ₹10
            <span className="small"> / Segment / Passenger</span>
          </div>
        </div>
        <div className="row border">
          <div className="col-3 py-3">
            <h5 className="m-0">TripJack API</h5>
          </div>
          <div className="col-3 py-3">₹20000</div>
          <div className="col-3 py-3">₹20</div>
          <div className="col-3 bg-light border-start py-3">
            ₹10 <span className="small"> / Segment / Passenger</span>
          </div>
        </div>
        <div className="row border">
          <div className="col-3 py-3">
            <h5 className="m-0">FD King</h5>
          </div>
          <div className="col-3 py-3">₹10000</div>
          <div className="col-3 py-3">₹10</div>
          <div className="col-3 bg-light border-start py-3">
            ₹10 <span className="small"> / Segment / Passenger</span>
          </div>
        </div>

        <div className="row border">
          <div className="col-3 py-3">
            <h5 className="m-0">AIRIQ API</h5>
          </div>
          <div className="col-3 py-3">₹10000</div>
          <div className="col-3 py-3">₹10</div>
          <div className="col-3 bg-light border-start py-3">
            ₹10 <span className="small"> / Segment / Passenger</span>
          </div>
        </div>
        <div className="row border">
          <div className="col-3 py-3">
            <h5 className="m-0">Fare Boutique</h5>
          </div>
          <div className="col-3 py-3">₹10000</div>
          <div className="col-3 py-3">₹10</div>
          <div className="col-3 bg-light border-start py-3">
            ₹10 <span className="small"> / Segment / Passenger</span>
          </div>
        </div>
        <div className="row border">
          <div className="col-3 py-3">
            <h5 className="m-0">TF Nexus API</h5>
          </div>
          <div className="col-3 py-3">₹10000</div>
          <div className="col-3 py-3">₹10</div>
          <div className="col-3 bg-light border-start py-3">
            ₹10 <span className="small"> / Segment / Passenger</span>
          </div>
        </div>
        <div className="row border">
          <div className="col-3 py-3">
            <h5 className="m-0">Amadeus</h5>
          </div>
          <div className="col-3 py-3">₹500000</div>
          <div className="col-3 py-3">₹20</div>
          <div className="col-3 bg-light border-start py-3">
            ₹10 <span className="small"> / Segment / Passenger</span>
          </div>
        </div>
        <div className="row border">
          <div className="col-3 py-3">
            <h5 className="m-0">Caller Desk API</h5>
          </div>
          <div className="col-3 py-3">₹10000</div>
          <div className="col-3 py-3"></div>
        </div>
        <div className="row border">
          <div className="col-3 py-3">
            <h5 className="m-0">WhatsApp API</h5>
          </div>
          <div className="col-3 py-3">₹10000</div>
          <div className="col-3 py-3"></div>
        </div>

        <div className=" mt-3">
          Note :
          <br />
          <ol>
            <li>
              The fee for pan-card-verifcation will be <b>₹5</b> / verification.
            </li>
            <br />
            <li>
              The fee for changing the domain will be <b>₹2500</b>.
            </li>
            <br />
            <li>
              Website purchase fee also includes basic maintenance costs example
              server fee etc. This fee excludes the cost of development of
              products/software/website/tools.
            </li>
            <br />
            <li>
              Please also refer to{" "}
              <Link to="/terms-and-conditions">Terms and Conditions </Link> and{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>. Usage of our
              website/products and services is subject to the terms and
              conditions mentioned in the referred documents.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
