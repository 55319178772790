import { Container, List, ListItem, Typography } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination/Pagination'
import { getAllErrorLogs } from 'apiCalls/errorLogsDb'
import React, { useCallback, useEffect, useState } from 'react'
import { FullScreenLoader } from '../flightModuleSettings/AirlineSettings/AirlineDiscountMarkup/components/FullScreenLoader'
import { AdvanceSettings } from './AdvanceSettings'
import { IErrorLogsPagination } from './types/ErrorLogs'

export const ErrorLogs = () => {

    const [errorLogsPaginationState, setErrorLogsPaginationState] = useState<IErrorLogsPagination>()

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (value !== pageState) {
            setPageState(value);
        }
    };

    const [pageState, setPageState] = React.useState(1);
    const [loadingState, setLoadingState] = React.useState(false);

    useEffect(() => {
        if (errorLogsPaginationState) {
            setPageState(errorLogsPaginationState?.currentPage);
        }
    }, [errorLogsPaginationState])

    const callGetAllErrorLogsEntries = useCallback(async () => {
        setLoadingState(true)

        const res = await getAllErrorLogs({
            page: pageState
        })
        setLoadingState(false)

        // const allEntriess = res.reverse()
        // console.log(allEntriess)
        if (res.success) {
            setErrorLogsPaginationState(res.result);
        }
    }, [pageState])

    useEffect(() => {
        callGetAllErrorLogsEntries()
    }, [pageState, callGetAllErrorLogsEntries])

    const ErrorPagination = () => {
        return <Pagination
            style={{
                float: 'right',
                marginTop: 15
            }}
            color="primary"
            count={errorLogsPaginationState?.totalPages || 0}
            page={pageState}
            onChange={handlePageChange}
        />
    }

    return (
        <>
            <FullScreenLoader show={loadingState} />

            <AdvanceSettings />
            <Container>
                <Typography variant="h5">Error Logs</Typography>
                <Typography>Total Errors: {errorLogsPaginationState?.totalRecords}</Typography>


                <ErrorPagination />

                <List style={{
                    marginTop: 50
                }}>
                    {
                        errorLogsPaginationState?.data.map(error => {
                            return (
                                <ListItem key={error._id}>
                                    <pre>
                                        {JSON.stringify(error, null, 2)}
                                    </pre>
                                </ListItem>
                            )
                        })
                    }
                </List>

                <ErrorPagination />


            </Container>
        </>
    )
}
