import bag3 from "../../../assets/images/rechargePlans/3bag.png";
export const WalletReachargePlanItemSkelton: React.FC<{}> = () => {
  return (
    <div className="recharge-item border-start py-4 border border-bottom">
      <div className="row align-items-center">
        <div className="col-lg-3">
          <span>
            <p className="placeholder-glow">
              <span className="placeholder col-12"></span>
            </p>
          </span>
        </div>
        <div className="col-lg-3">
          <p className="placeholder-glow">
            <span className="placeholder col-12"></span>
          </p>
        </div>
        <div className="col-lg-3 ">
          <p className="placeholder-glow">
            <span className="placeholder col-12"></span>
          </p>
        </div>

        <div className="col-lg-3">
          <p className="placeholder-glow">
            <span className="placeholder col-12"></span>
          </p>
        </div>
      </div>
    </div>
  );
};
