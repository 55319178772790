import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { postRequest } from "tools/ajax";
import _ from "lodash";

export const WlPgList = () => {
  const [pgList, setPgList] = useState<any[]>([]);
  const [uqPgs, setUqPgs] = useState<string[]>([]);
  const [filteredPgs, setFilteredPgs] = useState<any[]>([]);

  useEffect(() => {
    getPgWlList();
  }, []);

  useEffect(() => {
    const uniquePgs = _.uniqBy(pgList, "pgName").map((pg) => pg.pgName);
    setUqPgs(uniquePgs);
  }, [pgList]);

  const getPgWlList = async () => {
    const response = await postRequest(
      "admin/accounts/getAllSitesByPaymentGateway",
      {}
    );

    if (!response.success || response.errorMessage) {
      return toast.error(
        response.errorMessage || "Could not get payment gateway list..."
      );
    }
    setPgList(response.result);
    setFilteredPgs(response.result);
  };

  const filterPg = (e) => {
    console.log(e.target.value);
    if (e.target.value === "all") return setFilteredPgs(pgList);
    const filteredResults = pgList.filter((pg) => pg.pgName === e.target.value);
    setFilteredPgs(filteredResults);
  };
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-sm-6">
          <label>
            <b>Filter by Payment Gateway</b>
          </label>
          <Form.Select onChange={filterPg}>
            <option value={"all"}>All Payment Gateways</option>
            {uqPgs.map((pg) => (
              <option value={pg} key={pg}>
                {pg}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
      <table className="table table-striped table-bordered m-2">
        <thead>
          <tr>
            <th>Payment Gateway </th>
            <th>Domain</th>
          </tr>
        </thead>
        <tbody>
          {filteredPgs.map((pg) => (
            <tr key={pg._id}>
              <td>
                {pg.pgName} {pg.isActive && "(Active)"}
              </td>
              <td>{pg.siteId.domain}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
