import {
  currencySigns,
  currencyType,
  paymentTypeEnum,
} from "types/wallet.types";
import bag3 from "../../../assets/images/rechargePlans/3bag.png";
import { postRequest } from "tools/ajax";
import { toast } from "react-toastify";
import { productTypeEnum } from "commonFnBcode/types/whitelabel.types";

interface rechargePlan {
  planName: string;
  price: number;
  description: string;
  offered_amount: number;
  history: any;
}

export const WalletReachargePlanItem: React.FC<{
  plan_name: string;
  currency: currencyType | undefined;
  price: number;
  offered_amount: number;
  description: string;
  history?: any;
  index: number;
  loading: boolean;
  setLoading: (boolean) => void;
  currencySign: currencySigns | string;
}> = (props) => {
  const converToFloat = (amount) => {
    if (typeof amount === "string") return parseFloat(amount);
    return amount;
  };

  const price = converToFloat(props.price);
  const offered_amount = converToFloat(props.offered_amount);

  const createOrderForRecharge = async () => {
    if (props.loading) return;
    props.setLoading(true);
    if (!props.currency) {
      toast.error("Currency not found");
      return;
    }
    const createRechargeOrderRes = await postRequest(
      "wallet/createSippiOrder",
      {
        amount: price,
        remark: paymentTypeEnum.WALLET_RECHARGE,
        productType: productTypeEnum.WALLET_RECHARGE,
        currency: props.currency,
      }
    );
    if (createRechargeOrderRes?.result) {
      props.history.push(
        `/user/payment_screen?orderId=${createRechargeOrderRes.result}&redirectRoute=/user/user_wallet`
      );
    }
    props.setLoading(false);
  };
  function formatNumber(num) {
    let input = num;
    let n1, n2;
    num = num + "" || "";
    // works for integer and floating as well
    n1 = num.split(".");
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    // console.log("Input:", input);
    // console.log("Output:", num);
    return num;
  }
  const extraPercentage = ((offered_amount - price) * 100) / price;
  return (
    <div
      className="recharge-item border-start py-4 border border-bottom"
      key={props.index}
    >
      <div className="row align-items-center">
        <div className="col-3">
          <span>
            <b className="fs-4 text-primary">
              {props.currencySign}
              {formatNumber(price)}
            </b>
          </span>
          <br />
          <span className="small">Amount</span>
        </div>
        <div className="col-3">
          {extraPercentage ? (
            <div className="">
              <span className="">Get {extraPercentage.toFixed(0)}% extra</span>
            </div>
          ) : (
            "No Bonus Available"
          )}
        </div>
        <div className="col-3 ">
          <span>
            <b className="fs-4 text-success">
              {props.currencySign}
              {formatNumber(offered_amount)}
            </b>
          </span>
          <br />
          <span className="small">Recharge Amount</span>
        </div>

        <div className="col-3">
          <div className={`purchase-button ${props.loading ? "disabled" : ""}`}>
            <button
              onClick={createOrderForRecharge}
              className="btn btn-sm btn-outline-primary w-100  p-1 p-md-2 "
            >
              Purchase
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
