import {fork} from 'redux-saga/effects'
import {watchSiteSettings} from './siteSettings.saga'
import {watchCommonActions} from './common.saga'
import {watchPackages} from './package.saga'
import {watchAccountsActions} from './accounts.saga'
import { watchFlightActions } from './flight.saga'

export default function* rootSaga(){
    yield fork(watchSiteSettings)
    yield fork(watchCommonActions)
    yield fork(watchPackages)
    yield fork(watchAccountsActions)
    yield fork(watchFlightActions)
}