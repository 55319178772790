import { addGtmTagApiCall } from "apiCalls/siteSettingsDb";
import { getWhitelabel } from "apiCalls/whitelabelDb";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

export const GTagManager = () => {
  // const {
  //     siteData
  // } = useSelector((state: any) => state.siteSettings)

  const [gtmTag, setGtmTag] = useState<string>("");

  const [advanceSaveBtnDisabled, setAdvanceSaveBtnDisabled] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setAdvanceSaveBtnDisabled(false);
    setGtmTag(value);
  };

  const getWhitelabelData = async () => {
    const whitelabel = await getWhitelabel();

    if (whitelabel[0]) {
      setGtmTag(whitelabel[0]?.gtm_tag || "");
    }
  };

  useEffect(() => {
    getWhitelabelData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdvanceSubmit = async (event) => {
    // event.preventDefault()
    setAdvanceSaveBtnDisabled(true);
    const advanceSettings = await addGtmTagApiCall({ gtm_tag: gtmTag });
    if (advanceSettings) {
      setAdvanceSaveBtnDisabled(false);
      toast.success("GTM Tag saved.");
    }
  };

  const printInp = (name, label, value, placeholder) => (
    <Form.Group>
      <Form.Label className="mb-0 ">{label}</Form.Label>
      <Form.Control
        name={name}
        type="text"
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        required
      />
    </Form.Group>
  );
  return (
    <div>
      <div className="row shadow p-2 ">
        <div className="col-md-6 d-flex justify-content-center align-items-center flex-column">
          {printInp(
            "gtm_tag",
            "Google Tag Manager",
            gtmTag,
            "Google Tag Manager"
          )}

          <button
            onClick={handleAdvanceSubmit}
            disabled={advanceSaveBtnDisabled}
            className="btn btn-primary mt-2"
          >
            Save
          </button>
        </div>
        <div className="col-md-6">
          <p>
            Google Tag Manager is a tag management system that includes the same
            functionality as global site tags, and lets you configure and
            instantly deploy tags on your website from an easy to use web-based
            user interface.
          </p>
          <iframe
            width="100%"
            height="250"
            src="https://www.youtube.com/embed/9A-i7EWXzjs"
            title="Google Tag Manager Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};
