import React from "react";

export const ErrorHelperList: React.FC<{
  errors: Array<string>;
}> = (props) => {
  return (
    <>
      {props.errors.length > 0 ? (
        <ul className="list-unstyled ml-3">
          {props.errors.map((err, index) => (
            <li key={index} className="text-danger">
              {err}
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};
