import React, { useEffect } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@material-ui/core";
// import { IAirline } from 'redux/reducers/flightApi.reducer'
// import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DiscountMarkupTextField } from "./components/DiscountMarkupTextField";
import { useState } from "react";
import { IDefaultDiscount, IDefaultDiscountForm } from "./types";

import { Radio } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { flightAxios } from "./commonFlightAjax";
import { FullScreenLoader } from "./components/FullScreenLoader";

import { ERROR_MESSAGES, isInvalidField } from "./tools/utils";
import { toast } from "react-toastify";
import { ErrorHelperList } from "./components/ErrorHelperText";
import { FormControl } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        // width: 200,
      },
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

interface IDefaultDiscountFormState {
  isDomestic: boolean;
  discountInPercent: number;
  isLCC: boolean;
}

export const DefaultDiscountSettings: React.FC = (props) => {
  const classes = useStyles();

  const initialFormState: IDefaultDiscountForm = {
    isDomestic: { value: true, error: [], validation: ["required"] },
    discountInPercent: {
      value: 0,
      error: [],
      validation: ["required"],
    },
    isLCC: { value: true, error: [], validation: ["required"] },
  };

  const [formState, setFormState] =
    useState<IDefaultDiscountForm>(initialFormState);

  const [loadingState, setLoadingState] = useState(false);
  const [defaultDiscountState, setDefaultDiscountState] = useState<
    Array<IDefaultDiscount>
  >([]);

  useEffect(() => {
    getDefaultAirlineDiscounts();
    return () => {
      // cleanup
    };
  }, []);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: {
        ...formState[e.target.name],
        value: e.target.value,
        error: [],
      },
    });
  };

  const getDefaultAirlineDiscounts = async () => {
    let defaultDiscountResponse: AxiosResponse;
    setLoadingState(true);
    try {
      defaultDiscountResponse = await flightAxios({
        url: `defaultAirlineDiscount`,
        method: "GET",
      });
    } catch (error) {
      toast.error("Error connecting to server. ");
      return;
    }

    if (defaultDiscountResponse.data?.success) {
      setDefaultDiscountState(defaultDiscountResponse.data.result);
    }
    setLoadingState(false);
  };

  const handleDomesticChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    let isDomestic: boolean = value === "true" || value === "1";
    setFormState({
      ...formState,
      isDomestic: {
        ...formState.isDomestic,
        value: isDomestic,
        error: [],
      },
    });
  };

  const handleLCCChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    let isLCC: boolean = value === "true" || value === "1";
    setFormState({
      ...formState,
      isLCC: {
        ...formState.isDomestic,
        value: isLCC,
        error: [],
      },
    });
  };

  const handleDefaultDiscountEditBtn = (discountRowIndx) => {
    let formData = { ...formState };

    Object.keys(formData).forEach((key) => {
      formData[key] = {
        ...formData[key],
        value: defaultDiscountState[discountRowIndx][key],
      };
    });

    setFormState(formData);
  };

  const handleDefaultDiscountSave = async () => {
    setLoadingState(true);

    let formData: IDefaultDiscountFormState = {
      discountInPercent: 0,
      isDomestic: true,
      isLCC: true,
    };

    let newFormDataState = { ...formState };

    let errorExists = false;

    Object.keys(newFormDataState).forEach((fieldKey) => {
      formData[fieldKey] = newFormDataState[fieldKey].value;
      console.log(newFormDataState[fieldKey].value);
      if (isInvalidField(newFormDataState[fieldKey])) {
        newFormDataState[fieldKey].error = ["This Field is invalid"];
        errorExists = true;
      }
    });

    setFormState(newFormDataState);

    if (!errorExists) {
      try {
        let saveResponse = await flightAxios({
          url: "/defaultAirlineDiscount",
          data: formData,
        });

        await getDefaultAirlineDiscounts();

        let backendErrors = saveResponse.data.errors;
        if (backendErrors) {
          let newFormDataState = { ...formState };
          Object.keys(newFormDataState).forEach((fieldKey) => {
            if (backendErrors[fieldKey]) {
              newFormDataState[fieldKey].error = backendErrors[fieldKey];
            }
          });
          setFormState(newFormDataState);
        } else {
          setFormState(initialFormState);
        }
      } catch (error) {
        toast.error(ERROR_MESSAGES.SERVER_TRY_LATER);
      }
    }
    setLoadingState(false);
  };

  return (
    <>
      <FullScreenLoader show={loadingState} />
      <div>
        <form className={classes.root} noValidate autoComplete="off">
          <div>
            <div className="my-4">
              <Typography variant="h5">Default Discount Settings</Typography>
              <Typography>
                If Flight specific discount is not present, then default
                discount will be applicable.
              </Typography>
            </div>

            <Grid container>
              {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={formState.isDefault.value} onChange={handleDefaultChange} name="isDefault" />}
                                    label="Is Default ?"
                                />
                            </Grid> */}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="isDomestic"
                    name="isDomestic"
                    value={formState.isDomestic.value}
                    onChange={handleDomesticChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Domestic"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="International"
                    />
                  </RadioGroup>
                  <ErrorHelperList errors={formState.isDomestic.error} />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="isLCC"
                    name="isLCC"
                    value={formState.isLCC.value}
                    onChange={handleLCCChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="LCC"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Non LCC"
                    />
                  </RadioGroup>
                  <ErrorHelperList errors={formState.isDomestic.error} />
                </FormControl>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <DiscountMarkupTextField
                    name="discountInPercent"
                    label="Discount Percentage"
                    value={formState.discountInPercent}
                    onChange={handleInputChange}
                    textFieldProps={{
                      type: "number",
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  className="mt-2 mb-5"
                  color="primary"
                  variant="contained"
                  onClick={handleDefaultDiscountSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>

            {defaultDiscountState.map((air, index) => {
              return (
                <div key={index}>
                  <Grid
                    justify="center"
                    alignItems="center"
                    container
                    spacing={2}
                  >
                    <Grid item xs={3} className="text-center">
                      <p className="mb-0">
                        <small>Discount</small>
                      </p>
                      <p>{air.discountInPercent}%</p>
                    </Grid>
                    <Grid item xs={3} className="text-center">
                      {air.isDomestic ? "Domestic" : "International"}
                    </Grid>
                    <Grid item xs={3} className="text-center">
                      {air.isLCC ? "LCC" : "Non LCC"}
                    </Grid>
                    <Grid item xs={3} className="text-center">
                      <Button
                        onClick={() => handleDefaultDiscountEditBtn(index)}
                        variant="contained"
                        color="primary"
                        size="small"
                        className="mt-2"
                      >
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                  <hr />
                </div>
              );
            })}
          </div>
        </form>
      </div>
    </>
  );
};
