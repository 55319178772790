import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";

export default function Component({
  label,
  value,
  name,
  handleChange,
  minDate,
  maxDate,
  errors,
  disabled,
  isRequired,
}) {
  const onChange = (date) => {
    handleChange(name, date);
  };

  const error = errors ? errors[name] : null;

  // const detePickerRef = useRef();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        // ref={detePickerRef}
        label={label}
        format={"dd/MM/yyyy"}
        minDate={minDate}
        value={value ? value : null}
        error={error ? true : false}
        helperText={error}
        fullWidth
        autoOk
        required={isRequired}
        inputVariant="outlined"
        size="small"
        // inputProps={{

        // }}
        onChange={onChange}
        disabled={disabled}
        maxDate={maxDate}
        // KeyboardButtonProps={ {
        //     "aria-label": "change date",
        //   }}
      />
    </MuiPickersUtilsProvider>
  );
}
