import { z } from "zod";

export interface CountryType {
  _id: string;
  countryName: string;
  countryCode: string;
  currencyCode: string;
  continentName: ContinentNameEnum;
}

export enum ContinentNameEnum {
  Africa = "Africa",
  Antarctica = "Antarctica",
  Asia = "Asia",
  Europe = "Europe",
  NorthAmerica = "North America",
  Oceania = "Oceania",
  SouthAmerica = "South America",
}

export interface IJSONResponse<T = any> {
  success: boolean;
  message: string;
  result: T;
  errorMessage: string;
}

export const prePaymentFormSchema = z.object({
  companyName: z.string().refine((data) => data !== "", {
    message: "Value must not be an empty string",
  }),
  billingName: z.string().refine((data) => data !== "", {
    message: "Value must not be an empty string",
  }),
  email: z.string().refine((data) => data !== "", {
    message: "Value must not be an empty string",
  }),
  phoneCountryCode: z.string().refine((data) => data !== "", {
    message: "Value must not be an empty string",
  }),
  phoneNumber: z.string().refine((data) => data !== "", {
    message: "Value must not be an empty string",
  }),
  country: z.string().refine((data) => data !== "", {
    message: "Value must not be an empty string",
  }),
  gst: z.string().optional(),
  state: z.string().refine((data) => data !== "", {
    message: "Value must not be an empty string",
  }),
  address1: z.string().refine((data) => data !== "", {
    message: "Value must not be an empty string",
  }),
  address2: z.string().optional(),
  pin: z.string().refine((data) => data !== "", {
    message: "Value must not be an empty string",
  }),
  promoCode: z.string().optional(),
  panCardNo: z.string().optional(),
});
