import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export interface PaymentGatewayState {
  updated: number;
}

const initialState: PaymentGatewayState = {
  updated: 1,
};

export const counterSlice = createSlice({
  name: "paymentGateway",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updatePGCharge: (state) => {
      state.updated += 1;
    },
  },
});

export const { updatePGCharge } = counterSlice.actions;

// Selector
export const selectUpdatedCount = (state: RootState) => state.paymentGateway;

export const paymentGatewayReducer = counterSlice.reducer;
