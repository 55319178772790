import {siteSettingsTypes} from 'redux/actionTypes/siteSettings.types'

const initialState = {
    smtpDetails: [],
    siteData: {},
    testEmailResponse: null,
    bankDetails: {}
}

export function siteSettingsReducer (state = initialState,action) {
    switch(action.type){

        case siteSettingsTypes.SET_SMTP_DETAILS: 
            return {...state, smtpDetails: action.payload}

        case siteSettingsTypes.SET_SITE_DATA:
            return {...state,siteData: action.payload}

        case siteSettingsTypes.SEND_TEST_EMAIL_RESPONSE:
            return {...state,testEmailResponse: action.payload}

        case siteSettingsTypes.SET_BANK_DETAILS:
            return {...state,bankDetails: action.payload}

        default: 
        return state;
    }
}