import { getSiteSmtpDetails } from "apiCalls/siteSettingsDb";
import useWhitelabel from "hooks/useWhitelabel";

const useSmtp = () => {
  const { getWhitelabel } = useWhitelabel();

  const getSmtpDetailsBySiteId = async () => {
    const wl = await getWhitelabel();
    if (wl.err) return wl.err;
    console.log("from useSmtp.js", wl);
    return await getSiteSmtpDetails({ siteId: wl._id });
  };

  return {
    getSmtpDetailsBySiteId,
  };
};

export default useSmtp;
