import React, { useState } from "react";
import TopDrawer from "./topDrawer";
import { Container, Navbar } from "react-bootstrap";

export default function TopMenu() {
  const [open, setOpen] = useState(false);

  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>Super Login</Navbar.Brand>
        <p
          className="text-light h5"
          onClick={() => {
            setOpen(true);
          }}
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-bars "></i>
          &nbsp; MENU
        </p>
        <TopDrawer
          open={open}
          close={() => {
            setOpen(false);
          }}
        />
      </Container>
    </Navbar>
  );
}
